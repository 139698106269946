import React, { useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import HelperFns from "../../Helpers/HelperFns";
import PaymentFactors from "../../Constants/PaymentFactors";

import Table from "@mui/material/Table";
import { ModalHeader, Spinner } from "reactstrap";
import Loader from "../../Components/Loader";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import MainModal from "../../Components/MainModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import swal from "sweetalert";
import { showErrorToast, showPenaltyModal } from "../../Store/Actions";
import { useDispatch } from "react-redux";
import {
  EditIconButton,
  InfoIconButton,
} from "../../Components/IconButtonWithTooltip";
import { GET_PENALTY_DETAILS } from "../../Graphql/query";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import AntSwitch from "../../Components/AntSwitch";
import { applyPenaltiesMutation } from "../../Graphql/mutation";
import { showToast } from "../../Helpers/HelperFns";

const overtimeCols = [
  {
    title: "day",
    fieldName: "req_day",
    renderCell: (value) => moment(value).format("DD-MM-YYYY"),
  },
  {
    title: "interval",
    fieldName: "interval_in_time",
    // renderCell: (value) => moment(value, "HH:mm").format("HH:mm"),
    renderCell: (value) => value,
  },
  {
    title: "payable interval",
    fieldName: "payable_interval_in_time",
    // renderCell: (value) => moment(value, "HH:mm").format("HH:mm"),
    renderCell: (value) => value,
  },
  {
    title: "amount",
    fieldName: "amount",
    renderCell: (value) => (!isNaN(value) ? Number(value)?.toFixed(2) : value),
  },
  {
    title: "accepted by",
    fieldName: "accepted_by",
    renderCell: (value) => (
      <p className="text-ellipsis w-185">{value?.toLowerCase()}</p>
    ),
  },
  {
    title: "payable at",
    fieldName: "payable_at",
    // renderCell: (value) => moment(value).format("DD-MM-YYYY"),
    renderCell: (value) => value,
  },
  {
    title: "payroll",
    fieldName: "month_year_payroll",
    // renderCell: (value) => moment(value, "MM-YYYY").format("MMMM YYYY"),
    renderCell: (value) => value,
  },
];
const bonusCols = [
  {
    title: "name",
    fieldName: "name",
    renderCell: (value) => value,
  },
  {
    title: "value",
    fieldName: "value",
    renderCell: (value) => value,
  },
  {
    title: "unit",
    fieldName: "unit",
    renderCell: (value) => value,
  },
  {
    title: "amount",
    fieldName: "amount",
    renderCell: (value) => (!isNaN(value) ? Number(value)?.toFixed(2) : value),
  },
  {
    title: "reason",
    fieldName: "description",
    renderCell: (value) => value,
  },
  {
    title: "created by",
    fieldName: "createdBy",
    renderCell: (value) => <p className="text-ellipsis w-185">{value}</p>,
  },
  {
    title: "payable at",
    fieldName: "payroll_at",
    renderCell: (value) => moment(value).format("DD-MM-YYYY"),
  },
];
const allowancesCols = [
  {
    title: "name",
    fieldName: "name",
    renderCell: (value) => value,
  },
  {
    title: "amount",
    fieldName: "amount",
    renderCell: (value) => (!isNaN(value) ? Number(value)?.toFixed(2) : value),
  },
  // {
  //   title: "target amount",
  //   fieldName: "target_amount",
  //   renderCell: (value) => (!isNaN(value) ? Number(value)?.toFixed(2) : value),
  // },
];

const otherDeductCols = [
  {
    deductions: [
      {
        title: "name",
        fieldName: "name",
        renderCell: (value) => value,
      },
      // {
      //   title: "value",
      //   fieldName: "value",
      //   renderCell: (value) => value,
      // },
      // {
      //   title: "unit",
      //   fieldName: "payment_factor_id",
      //   renderCell: (value) =>
      //     value == PaymentFactors.FIXED_AMOUNT
      //       ? HelperFns.localizeFns("Fixed Amount")
      //       : HelperFns.localizeFns("Days"),
      // },
      {
        title: "amount",
        fieldName: "amount",
        renderCell: (value) =>
          !isNaN(value) ? Number(value)?.toFixed(2) : value,
      },
      // {
      //   title: "reason",
      //   fieldName: "description",
      //   renderCell: (value) => value,
      // },
      // {
      //   title: "created by",
      //   fieldName: "createdBy",
      //   renderCell: (value) => <p className="text-ellipsis">{value?.name}</p>,
      // },
      // {
      //   title: "payable at",
      //   fieldName: "payroll_at",
      //   renderCell: (value) => moment(value).format("DD-MM-YYYY"),
      // },
    ],
  },
  {
    deductibles: [
      {
        title: "name",
        fieldName: "deductible",
        renderCell: (value, row) =>
          row.deductible?.id == "4"
            ? HelperFns.localizeFns(value?.name)
            : value?.name,
      },
      {
        title: "current amount",
        fieldName: "amount",
        renderCell: (value) =>
          !isNaN(value) ? Number(value)?.toFixed(2) : value,
      },
      {
        title: "target amount",
        fieldName: "target_amount",
        renderCell: (value, row) =>
          row.deductible?.id !== "4"
            ? !isNaN(value)
              ? Number(value)?.toFixed(2)
              : value
            : !isNaN(row?.amount)
            ? Number(row?.amount)?.toFixed(2)
            : row?.amount,
      },
    ],
  },
  {
    installments: [
      {
        title: "loan",
        fieldName: "name",
        renderCell: (value) => value,
      },
      {
        title: "loan amount",
        fieldName: "amount",
        renderCell: (value) =>
          !isNaN(value) ? Number(value)?.toFixed(2) : value,
      },
      {
        title: "installment amount",
        fieldName: "installment_amount",
        renderCell: (value) =>
          !isNaN(value) ? Number(value)?.toFixed(2) : value,
      },
    ],
  },
  {
    taxSettlements: [
      {
        title: "start Month",
        fieldName: "start",
        renderCell: (value) => value,
      },
      {
        title: "end Month",
        fieldName: "end",
        renderCell: (value) => value,
      },
      {
        title: "year",
        fieldName: "year",
        renderCell: (value) => value,
      },
      {
        title: "amount",
        fieldName: "value",
        renderCell: (value) => (isNaN(value) ? value : (+value)?.toFixed(2)),
      },
    ],
  },
];
const otherAddedCols = [
  {
    claims: [
      {
        title: "amount",
        fieldName: "amount",
        renderCell: (value) =>
          !isNaN(value) ? Number(value)?.toFixed(2) : value,
      },
      {
        title: "reason",
        fieldName: "reason",
        renderCell: (value) => value,
      },
      {
        title: "incurred at",
        fieldName: "incurred_at",
        renderCell: (value) => moment(value).format("DD-MM-YYYY"),
      },
      {
        title: "action by",
        fieldName: "actionBy",
        renderCell: (value) => <p className="text-ellipsis">{value?.name}</p>,
      },
    ],
  },
  {
    loans: [
      {
        title: "name",
        fieldName: "name",
        renderCell: (value) => value,
      },
      {
        title: "loan amount",
        fieldName: "amount",
        renderCell: (value) =>
          !isNaN(value) ? Number(value)?.toFixed(2) : value,
      },
      {
        title: "created by",
        fieldName: "createdBy",
        renderCell: (value) => <p className="text-ellipsis">{value}</p>,
      },
    ],
  },
  {
    taxSettlements: [
      {
        title: "start Month",
        fieldName: "start",
        renderCell: (value) => moment(value, "M").format("MMMM"),
      },
      {
        title: "end Month",
        fieldName: "end",
        renderCell: (value) => moment(value, "M").format("MMMM"),
      },
      {
        title: "year",
        fieldName: "year",
        renderCell: (value) => value,
      },
      {
        title: "amount",
        fieldName: "value",
        renderCell: (value) => (isNaN(value) ? value : (+value)?.toFixed(2)),
      },
    ],
  },
];

// const exportOvertimeDetailaQuery = gql`
//   query exportOvertimeDetailaQuery($payroll_id: ID) {
//     export_overtime_details(payroll_id: $payroll_id) {
//       file
//       status
//       message
//     }
//   }
// `;

const ViewWeeklyPayrollDetails = ({
  refetchPayrollAndPenalties,
  isManager = true,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { dismissModal, isModalVissible, title } = props;

  const [penaltyLoadingId, setPenaltyLoadingId] = useState(null);

  const [fetchPenalty, { loading: fetchPenaltyLoading }] =
    useLazyQuery(GET_PENALTY_DETAILS);

  const [applyPenalty, { loading: isApplyPenaltyLoading }] = useMutation(
    applyPenaltiesMutation,
    {
      onCompleted: () => {
        showToast("success");
        refetchPayrollAndPenalties();
        setPenaltyLoadingId(null);
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ?? err?.message
        );
        setPenaltyLoadingId(null);
      },
    }
  );

  const penaltyCols = [
    {
      title: "date",
      fieldName: "date",
      renderCell: (value) => moment(value).format("DD-MM-YYYY"),
    },
    {
      title: "quantity",
      fieldName: "quantity",
      renderCell: (value) =>
        !isNaN(value) ? Number(value)?.toFixed(2) : value,
    },
    {
      title: "unit",
      fieldName: "quantity_unit",
      renderCell: (value) => HelperFns.localizeFns(value),
    },
    {
      title: "amount",
      fieldName: "PenaltyAmount",
      renderCell: (value, row) => {
        if (!value) return "---";

        return !isNaN(value) ? Number(value)?.toFixed(2) : value;
      },
    },
    {
      title: "cause",
      fieldName: "full_cause",
      renderCell: (value) => HelperFns.localizeFns(value),
    },
    ...(isManager
      ? [
          {
            title: "",
            fieldName: "",
            renderCell: (_, penalty) => {
              return (
                <div
                  style={{
                    height: "1rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: 80,
                  }}
                >
                  <div
                    style={{
                      marginRight: 5,
                      minWidth: 60,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {penalty?.canApply ? (
                      isApplyPenaltyLoading &&
                      penaltyLoadingId === penalty?.id ? (
                        <Spinner
                          style={{
                            width: "1rem",
                            height: "1rem",
                            color: "#2764ac",
                          }}
                        />
                      ) : (
                        <HasPrivileges
                          reqireMain={[Privilages.APPLY_REVERT_PENALTIES]}
                          allowBP
                          altChildren={
                            <AntSwitch
                              inputProps={{ "aria-label": "Switch demo" }}
                              checked={penalty?.apply_flag}
                              disabled
                              color="primary"
                            />
                          }
                          avalibleOnExpire={false}
                          altExpireChildren={
                            <AntSwitch
                              inputProps={{ "aria-label": "Switch demo" }}
                              checked={penalty?.apply_flag}
                              disabled
                              color="primary"
                            />
                          }
                        >
                          <AntSwitch
                            inputProps={{ "aria-label": "Switch demo" }}
                            checked={penalty?.apply_flag}
                            onChange={() => {
                              setPenaltyLoadingId(penalty?.id);
                              applyPenalty({ variables: { id: penalty.id } });
                            }}
                            color="primary"
                          />
                        </HasPrivileges>
                      )
                    ) : null}
                  </div>
                  <div style={{ minWidth: 40 }}>
                    {penalty?.canEdit ? (
                      <div className="my-2">
                        <div className="data-table-action">
                          {fetchPenaltyLoading &&
                          penaltyLoadingId === penalty?.id ? (
                            <Spinner
                              style={{
                                width: "1rem",
                                height: "1rem",
                                color: "#27b40c",
                              }}
                            />
                          ) : (
                            <EditIconButton
                              onClick={() => {
                                setPenaltyLoadingId(penalty?.id);
                                fetchPenalty({
                                  variables: {
                                    id: penalty?.id,
                                  },
                                  onCompleted: (data) => {
                                    dispatch(
                                      showPenaltyModal({
                                        formName: "editPenaltyModal",
                                        ref: "editPenaltyModalActions",
                                        data: {
                                          date: penalty?.date,
                                          penalties: [data?.penalty],
                                          employeeName: props?.data?.name,
                                          sign_in_time:
                                            data?.penalty?.sign_in_out
                                              ?.sign_in_time,
                                          sign_out_time:
                                            data?.penalty?.sign_in_out
                                              ?.sign_out_time,
                                        },
                                      })
                                    );
                                    setPenaltyLoadingId(null);
                                  },
                                });
                              }}
                            />
                          )}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              );
            },
          },
        ]
      : []),
  ];

  const columns =
    props?.data?.title === "overtime"
      ? overtimeCols
      : props?.data?.title === "bonus"
      ? bonusCols
      : props?.data?.title === "penalty"
      ? penaltyCols
      : props?.data?.title === "other deductibles"
      ? otherDeductCols
      : props?.data?.title === "other added"
      ? otherAddedCols
      : props?.data?.title === "allowances"
      ? allowancesCols
      : null;

  // const [exportOvertimeDetails, { data, loading, error }] = useLazyQuery(
  //   exportOvertimeDetailaQuery,
  //   {
  //     fetchPolicy: "network-only",
  //     onCompleted: (response) => {
  //       if (
  //         response?.export_overtime_details &&
  //         response?.export_overtime_details?.status === "success"
  //       ) {
  //         HelperFns.downloadFile(response?.export_overtime_details?.file);
  //       } else {
  //         dispatch(showErrorToast(response?.export_overtime_details?.message));
  //       }
  //     },
  //     onError: (error) => {
  //       dispatch(showErrorToast(error?.message));
  //     },
  //   }
  // );

  // const handleExportOvertTimeDetails = () => {
  //   if (loading || props?.data?.title !== "overtime") {
  //     return;
  //   }
  //   exportOvertimeDetails({
  //     variables: {
  //       payroll_id: props?.data?.payroll_id,
  //     },
  //   });
  // };

  if (props?.isLoading) {
    return (
      <div className="loader_wrapper_style">
        <Loader />
      </div>
    );
  }

  if (!isModalVissible || !columns) {
    return null;
  }
  return (
    <MainModal
      toggle={dismissModal}
      isOpen={isModalVissible}
      size="lg"
      className="payroll-detatils-modal"
      modalTitle={props?.data?.title}
      modalHeader={
        <ModalHeader tag={"div"} wrapTag={"div"}>
          <div className="name-card">
            <strong>{props?.data?.name} </strong>
            <span className="mx-5">
              {moment(props?.data?.month_year, "YYYY-MM-DD").format(
                "YYYY-MM-DD"
              )}
            </span>
          </div>
        </ModalHeader>
      }
    >
      <>
        {/* {props?.data?.title === "overtime" ? (
          <button
            className="btn btn-dark mb-2 min-w-85 monthly-export mx-0 py-1 text-nowrap"
            type="button"
            onClick={handleExportOvertTimeDetails}
          >
            {loading ? (
              <Spinner
                style={{ width: "1rem", height: "1rem", color: "#fff" }}
              />
            ) : (
              <>
                <FontAwesomeIcon className="mr-2" icon={faFileExport} />
                {t("export")}
              </>
            )}
          </button>
        ) : null} */}

        {props?.data?.title?.includes("other") ? (
          columns?.map((type) => {
            const colKey = Object?.keys(type)[0];
            const columns = type[colKey];

            if (props?.data?.data?.[colKey]?.length) {
              return (
                <>
                  <h5 className="text-left table-title">{t(colKey)}</h5>
                  <TableContainer className="table">
                    <Table size="large" aria-label="a dense table">
                      <TableHead className="tabel-header">
                        <TableRow>
                          {columns?.map((col) => (
                            <TableCell align="center" key={col.fieldName}>
                              {t(col.title)}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Array.isArray(props?.data?.data?.[colKey]) &&
                          props?.data?.data?.[colKey]
                            ?.filter((r) => r?.amount !== 0)
                            ?.map((record) => (
                              <TableRow key={record.req_day}>
                                {columns?.map((col) => (
                                  <TableCell padding="normal" align="center">
                                    {col.renderCell(
                                      record[col.fieldName],
                                      record
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                    {!props?.data?.data?.[colKey]?.length ? (
                      <div className="mt-2 text-center">
                        <h6>{t("There Are No Records To Display")}</h6>
                      </div>
                    ) : null}
                  </TableContainer>
                </>
              );
            }

            return null;
          })
        ) : (
          <TableContainer className="table">
            <Table size="medium" aria-label="a dense table">
              <TableHead className="tabel-header">
                <TableRow>
                  {columns?.map((col) => (
                    <TableCell align="center" key={col.fieldName}>
                      {t(col.title)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(props?.data?.data) &&
                  props?.data?.data?.map((record) => (
                    <TableRow key={record.req_day}>
                      {columns?.map((col) => (
                        <TableCell
                          padding="normal"
                          align="center"
                          style={{ verticalAlign: "middle" }}
                        >
                          {col.renderCell(record[col.fieldName], record)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {!props?.data?.data?.length ? (
              <div className="mt-2 text-center">
                <h6>{t("There Are No Records To Display")}</h6>
              </div>
            ) : null}
          </TableContainer>
        )}
      </>
    </MainModal>
  );
};

export default ViewWeeklyPayrollDetails;
