import Privilages from "./Privilages";
import { CRM_PRIVILEGES, crmSidebarPrivileges } from "./CRM";
import { assignmentTabPrivileges } from "./Attendance";

export const CRMPrivileges = Object.values(CRM_PRIVILEGES);

export const ADMIN_PRIVILEGES = [
  Privilages.VIEW_COMPANIES,
  Privilages.ADD_EDIT_COMPANY,
  Privilages.DELETE_COMPANY,
  Privilages.SUSPEND_ACTIVATE_COMPANY,
  Privilages.ADD_NEW_SUBSCIPTION,
  Privilages.EDIT_DELETE_SUBSCIPTION,
  Privilages.EDIT_DELETE_SUBSCIPTION,
  Privilages.VIEW_ADMIN_USERS,
  Privilages.ADD_ADMIN_USERS,
  Privilages.REMOVE_ADMIN_USERS,
  Privilages.SIGN_IN_AS_BUSINESS_PARTNER,
  Privilages.EDIT_COMPANY_SETTINGS,

  Privilages.EDIT_ADMIN_USERS_PRIVILEGES,
  Privilages.VIEW_BUSINESS_PARTNERS,
  Privilages.ADD_EDIT_BUSINESS_PARTNERS,
  Privilages.DELETE_BUSINESS_PARTNERS,
  Privilages.SUSPEND_ACTIVATE_BUSINESS_PARTNERS,
  Privilages.VIEW_COMPANY_PROFILE,
  Privilages.VIEW_SUBSCRIPTIONS,
  Privilages.VIEW_ASSIGNED_ACCOUNT_MANAGERS,
  Privilages.ASSIGN_ACCOUNT_MANAGERS,
  Privilages.VIEW_ADMIN_USER_PROFILE,
  Privilages.ASSIGN_REMOVE_ADMIN_USER_TO_COMPANIES,
  Privilages.SUPER_PRIVILEGE,
  Privilages.EDIT_SUBSCRIPTION_CREATED_BY,
  Privilages.MANAGE_ADMIN_USER_PRIVILEGES,

  Privilages.ADD_EDIT_DELETE_PLANS,
  Privilages.ACTIVATE_DEACTIVATE_PLANS,
  Privilages.VIEW_PLAN_PROFILE,
  Privilages.VIEW_PLAN_PRIVILEGES,
  Privilages.VIEW_PLAN_ROLE_TEMPLATES,
  Privilages.ADD_EDIT_DELETE_ROLE_TEMPLATES,
  Privilages.VIEW_PLANS,
  Privilages.GENERATE_DEMO,
  Privilages.IMPORT_COMPANY_EMPLOYEES,
  Privilages.SEND_VERIFICATION_LINK,
  Privilages.EDIT_COMPANT_ACTIVITY_REPORT_DATA,
  ...CRMPrivileges,

  Privilages.ADD_EDIT_ACTIVITIES,
  Privilages.ADD_EDIT_COMPANY_CONTACTS,
  Privilages.ADMIN_MANUAL_VERIFY_EMPLOYEE
];

export const SettingsPrivilages = [
  Privilages.VIEW_OFFICES,
  Privilages.VIEW_DEPARTMENTS,
  Privilages.VIEW_POSITIONS,
  Privilages.VIEW_HOLIDAYS,
  Privilages.VIEW_EXPENSE_CATEGORIES,
  Privilages.VIEW_COMPANY_DOCUMENTS,
  Privilages.VIEW_ATTENDANCE_PROFILES,
  Privilages.VIEW_WORK_TIMINGS,
  Privilages.VIEW_LEAVE_AND_BREAK_PROFILES,
  Privilages.VIEW_ACCESS_LEVELS,
  Privilages.VIEW_LOCATIONS,
  Privilages.VIEW_COMPANY_ANNOUNCEMENT,
];

export const PublicNavList = [
  // { label: "Profile", path: "/employee-profile", iconName: "faUser" },
];

export const SidebarPrivilages = [
  Privilages.VIEW_EMPLOYEE_OVERTIME_CHART,
  Privilages.VIEW_EMPLOYEES_PER_DEPARTMENT_CHART,
  Privilages.VIEW_EMPLOYEE_ATTENDANCE_HISTORY_CHART,
  Privilages.VIEW_EMPLOYEE_DAILY_STATUSES,
  Privilages.VIEW_EMPLOYEE_REQUESTS,
  Privilages.VIEW_EMPLOYEES,
  Privilages.VIEW_EMPLOYEE_SALARY_CONFIGURATION,
  Privilages.VIEW_EMPLOYEE_ATTENDANCE_TYPE_CONFIGURATIONS,
  Privilages.VIEW_EMPLOYEE_SCHEDULES,
  Privilages.VIEW_EMPLOYEE_DOCUMENTS,
  Privilages.VIEW_EMPLOYEE_PETTY_CASH_BALANCE_IN_EMPLOYEE_PROFILE,
  Privilages.VIEW_EMPLOYEE_REQUESTS,
  Privilages.VIEW_ATTENDANCE_HISTORY_LOGS,
  Privilages.MANAGE_EMPLOYEE_ATTENDANCE,
  Privilages.VIEW_EMPLOYEE_PAYABLE_OVERTIME,
  Privilages.VIEW_EMPLOYEE_BALANCE_ADJUSTMENT,
  Privilages.VIEW_EMPLOYEE_CLAIMS,
  Privilages.VIEW_EMPLOYEE_BONUSES_DEDUCTIONS,
  Privilages.VIEW_PAYROLL_LIST,
  Privilages.VIEW_COMPANIES,
  Privilages.VIEW_ADMIN_USERS,
  Privilages.VIEW_PETTY_CASH_BALANCE_ADJUSTMENT_LIST,
  Privilages.VIEW_THE_EXPENSES_OF_MY_OFFICE,
  Privilages.VIEW_ATTENDANCE_MONTHLY_REPORT,
  Privilages.VIEW_REQUEST_RULES_TO_EMPLOYEES_IN_ALL_DEPARTMENTS,
  Privilages.VIEW_REQUEST_RULES_TO_EMPLOYEES_IN_MY_DEPARTMENT,
  Privilages.VIEW_PLANS,
  Privilages.VIEW_THE_EXPENSES_CHART_OF_MY_OFFICE,
  Privilages.VIEW_THE_EXPENSES_CHART_OF_ALL_OFFICES,
  Privilages.VIEW_MISSING_EXPIRED_DOCUMENT,
  Privilages.VIEW_TURNOVER_CHART,
  Privilages.VIEW_OPEN_PAYROLL_MONTHS,
  Privilages.VIEW_RECRUITMENT_PROCESSES,
  Privilages.VIEW_INTAKE_FORMS,
  Privilages.VIEW_JOB_POSTS,
  Privilages.VIEW_EMPLOYEE_PENALTIES,
  Privilages.VIEW_LOANS_LIST,
  Privilages.VIEW_ADMIN_ANNOUNCEMENT,
  Privilages.VIEW_FACIAL_RECOGNITION_REPORT,
  ...SettingsPrivilages,
  ...crmSidebarPrivileges,
  ...assignmentTabPrivileges,
];

export const SuperNavList = [
  {
    // privileges: [
    //   Privilages.VIEW_EMPLOYEE_OVERTIME_CHART,
    //   Privilages.VIEW_EMPLOYEES_PER_DEPARTMENT_CHART,
    //   Privilages.VIEW_EMPLOYEE_ATTENDANCE_HISTORY_CHART,
    //   Privilages.VIEW_EMPLOYEE_DAILY_STATUSES,
    //   Privilages.VIEW_EMPLOYEE_REQUESTS,
    //   Privilages.VIEW_THE_EXPENSES_CHART_OF_MY_OFFICE,
    //   Privilages.VIEW_THE_EXPENSES_CHART_OF_ALL_OFFICES,
    //   Privilages.VIEW_MISSING_EXPIRED_DOCUMENT,
    //   Privilages.VIEW_TURNOVER_CHART,
    //   Privilages.VIEW_OPEN_PAYROLL_MONTHS,
    // ],
    allowBP: true,
    label: "dashboard",
    path: "/",
    iconName: "faColumns",
    isHoverable: false,
    tabPrivileges: [
      Privilages.VIEW_EMPLOYEE_OVERTIME_CHART,
      Privilages.VIEW_EMPLOYEES_PER_DEPARTMENT_CHART,
      Privilages.VIEW_EMPLOYEE_ATTENDANCE_HISTORY_CHART,
      Privilages.VIEW_EMPLOYEE_DAILY_STATUSES,
      // Privilages.VIEW_EMPLOYEE_REQUESTS,
      Privilages.VIEW_THE_EXPENSES_CHART_OF_MY_OFFICE,
      Privilages.VIEW_THE_EXPENSES_CHART_OF_ALL_OFFICES,
      Privilages.VIEW_MISSING_EXPIRED_DOCUMENT,
      Privilages.VIEW_TURNOVER_CHART,
      Privilages.VIEW_OPEN_PAYROLL_MONTHS,
    ],
  },
  {
    // privileges: [Privilages.VIEW_PROFILE],
    allowBP: false,
    label: "profile",
    path: "/employee-profile",
    iconName: "faUser",
    isHoverable: false,
    tabPrivileges: [[Privilages.VIEW_PROFILE]],
  },
  {
    allowBP: true,
    label: "employees",
    path: "/employees",
    iconName: "faUsers",
    isHoverable: true,
    tabPrivileges: [
      [
        Privilages.VIEW_EMPLOYEES,
        Privilages.VIEW_EMPLOYEE_SCHEDULES,
        Privilages.VIEW_EMPLOYEE_SALARY_CONFIGURATION,
        Privilages.VIEW_EMPLOYEE_ATTENDANCE_TYPE_CONFIGURATIONS,
        Privilages.VIEW_EMPLOYEE_PETTY_CASH_BALANCE_IN_EMPLOYEE_PROFILE,
        Privilages.VIEW_EMPLOYEE_DOCUMENTS,
      ],
      // TODO: add correct privileges
      [Privilages.VIEW_EMPLOYEE_WORK_GROUPS],
      [Privilages.VIEW_WORK_TEAMS],
      [Privilages.EDIT_USER_PRIVILEGE, Privilages.ADD_EDIT_DELETE_EMPLOYEES],
      [Privilages.VIEW_MISSING_EXPIRED_DOCUMENT],
    ],
  },
  {
    // privileges: [
    //   Privilages.VIEW_EMPLOYEE_REQUESTS,
    //   Privilages.VIEW_REQUEST_RULES_TO_EMPLOYEES_IN_ALL_DEPARTMENTS,
    //   Privilages.VIEW_REQUEST_RULES_TO_EMPLOYEES_IN_MY_DEPARTMENT,
    //   Privilages.VIEW_EMPLOYEE_CLAIMS,
    // ],
    allowBP: true,
    label: "requests",
    path: "/requests",
    iconName: "faHands",
    hasBadge: true,
    badgeReducer: "user",
    badgeDataNameInReducer: "totalPendingRequestsCount",
    badgePrivileges: [
      Privilages.VIEW_EMPLOYEE_REQUESTS,
      Privilages.VIEW_EMPLOYEE_CLAIMS,
    ],
    badgeAllowBP: true,
    isHoverable: true,
    tabPrivileges: [
      [Privilages.VIEW_EMPLOYEE_REQUESTS],
      [Privilages.VIEW_EMPLOYEE_CLAIMS],
      [
        Privilages.VIEW_REQUEST_RULES_TO_EMPLOYEES_IN_ALL_DEPARTMENTS,
        Privilages.VIEW_REQUEST_RULES_TO_EMPLOYEES_IN_MY_DEPARTMENT,
      ],
      [Privilages.VIEW_APPROVAL_LAYERS],
      [Privilages.VIEW_LOAN_REQUEST],
    ],
  },
  {
    // privileges: [
    //   Privilages.VIEW_ATTENDANCE_HISTORY_LOGS,
    //   Privilages.MANAGE_EMPLOYEE_ATTENDANCE,
    //   Privilages.VIEW_ATTENDANCE_MONTHLY_REPORT,
    //   Privilages.VIEW_EMPLOYEE_PENALTIES,
    // ],
    allowBP: true,
    label: "attendance",
    path: "/attendance",
    iconName: "faAddressBook",
    isHoverable: true,
    tabPrivileges: [
      [
        Privilages.VIEW_ATTENDANCE_HISTORY_LOGS,
        Privilages.MANAGE_EMPLOYEE_ATTENDANCE,
      ],
      [Privilages.VIEW_EMPLOYEE_PENALTIES, Privilages.APPLY_REVERT_PENALTIES],
      assignmentTabPrivileges,
      [Privilages.VIEW_ATTENDANCE_MONTHLY_REPORT],
      [Privilages.VIEW_ATTENDANCE_MONTHLY_REPORT], //for Yearly report tab
      [Privilages.VIEW_FACIAL_RECOGNITION_REPORT],
      [Privilages.VIEW_EMPLOYEE_BALANCE_ADJUSTMENT],
      [
        Privilages.VIEW_EMPLOYEE_SCHEDULES,
        Privilages.PLAN_NEW_SCHEDULES_FOR_MANAGED_WORKGROUPS,
      ],
    ],
  },
  {
    allowBP: true,
    label: "financials",
    path: "/financials",
    iconName: "faMoneyBillAlt",
    isHoverable: true,
    tabPrivileges: [
      [Privilages.VIEW_EMPLOYEE_BONUSES_DEDUCTIONS],
      [Privilages.VIEW_THE_EXPENSES_OF_MY_OFFICE],
      [Privilages.VIEW_PETTY_CASH_BALANCE_ADJUSTMENT_LIST],
      [Privilages.VIEW_LOANS_LIST],
      [Privilages.VIEW_PAYROLL_LIST],
    ],
  },
  // {
  //   // privileges: [Privilages.VIEW_MISSING_EXPIRED_DOCUMENT],
  //   allowBP: true,
  //   label: "documents",
  //   path: "/employees?tab=employee-documents",
  //   iconName: "faFileAlt",
  //   isHoverable: false,
  //   tabPrivileges: [[Privilages.VIEW_MISSING_EXPIRED_DOCUMENT]],
  // },
  {
    // privileges: [
    //   Privilages.VIEW_RECRUITMENT_PROCESSES,
    //   Privilages.VIEW_INTAKE_FORMS,
    //   Privilages.VIEW_JOB_POSTS,
    //   Privilages.VIEW_JOB_POST_APPLICANTS,
    // ],
    allowBP: true,
    label: "recruitment",
    path: "/recruitment",
    iconName: "faBriefcase",
    isHoverable: true,

    tabPrivileges: [
      [Privilages.VIEW_RECRUITMENT_PROCESSES],
      [Privilages.VIEW_INTAKE_FORMS],
      [Privilages.VIEW_JOB_POSTS],
      [Privilages.VIEW_JOB_POST_APPLICANTS],
    ],
  },
  // {
  //   // privileges: [Privilages.VIEW_PROFILE],
  //   allowBP: true,
  //   label: "calendar",
  //   path: "/attendance?tab=work-calendar",
  //   iconName: "faCalendarAlt",
  //   isHoverable: false,
  //   tabPrivileges: [
  //     [
  //       Privilages.VIEW_EMPLOYEE_SCHEDULES,
  //       Privilages.PLAN_NEW_SCHEDULES_FOR_MANAGED_WORKGROUPS,
  //     ],
  //   ],
  // },
  {
    // privileges: SettingsPrivilages,
    allowBP: true,
    label: "settings",
    path: "/settings",
    iconName: "faCog",
    isHoverable: true,
    tabPrivileges: [
      [Privilages.VIEW_OFFICES],
      [Privilages.VIEW_DEPARTMENTS],
      [Privilages.VIEW_POSITIONS],
      [Privilages.VIEW_ATTENDANCE_PROFILES],
      [Privilages.VIEW_WORK_TIMINGS],
      [Privilages.VIEW_HOLIDAYS],
      [Privilages.VIEW_COMPANY_DOCUMENTS],
      [Privilages.VIEW_EXPENSE_CATEGORIES],
      [Privilages.VIEW_ACCESS_LEVELS],
      [Privilages.VIEW_LEAVE_AND_BREAK_PROFILES],
      [Privilages.VIEW_LOCATIONS],
      [Privilages.VIEW_COMPANY_ANNOUNCEMENT],
    ],
  },
  {
    allowBP: false,
    label: "Admin Panel",
    path: "/admin-panel",
    iconName: "faUserCog",
    isHoverable: true,
    tabPrivileges: [
      [Privilages.VIEW_COMPANIES],
      [Privilages.VIEW_ADMIN_USERS],
      [Privilages.VIEW_PLANS],
      [Privilages.VIEW_ADMIN_HOLIDAYS],
      [],
      [Privilages.VIEW_ADMIN_ANNOUNCEMENT],
      [Privilages.ADMIN_MANUAL_VERIFY_EMPLOYEE, Privilages.SUPER_PRIVILEGE],
    ],
  },
  {
    allowBP: false,
    label: "crm",
    path: "/crm",
    iconName: "faUserCog",
    isHoverable: true,
    tabPrivileges: crmSidebarPrivileges.map((p) => [p]),
  },
  // { label: "Suspension", path: "/suspension", iconName: "faMinusCircle" },
  // { label: "commission lists", path: "/commission-lists", iconName: "faThList" },
];
