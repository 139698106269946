import { put, call, takeLatest, takeEvery } from "redux-saga/effects";
import Types from "../Actions/types";
import swal from "sweetalert";

import {
  createNewAllowance,
  createNewDeductible,
  createNewEmployeeSalary,
  initSalaryConfigInfo,
} from "../../Services/Client";
import {
  createNewAllowanceFailed,
  createNewAllowanceSuccess,
  createNewDeductibleFailed,
  createNewDeductibleSuccess,
  createNewEmployeeSalaryFailed,
  createNewEmployeeSalarySuccess,
  showEditUpsertSalaryConfigSuccess,
  initSalaryConfigFailed,
  showNewUpsertSalaryConfigSuccess,
  initActivateNewSalaryConfigFailed,
  initActivateNewSalaryConfigSuccess,
  createNewEmployeeSalaryAction,
  fetchSalaryPresetFailed,
  fetchSalaryPresetSuccess,
  showErrorToast,
} from "../Actions";
import HelperFns from "../../Helpers/HelperFns";
import Store from "..";

export function* handleCreateNewAllowanceSaga({ payload }) {
  try {
    const response = yield call(createNewAllowance, payload);
    // console.log("s", refEmployee);
    if ("errors" in response) {
      yield put(createNewAllowanceFailed(payload.index));
      yield put(
        showErrorToast(
          response?.errors[0]?.extensions?.validation[
            "input.name"
          ]?.toString() || response?.errors[0]?.message
        )
      );
    } else {
      yield put(
        createNewAllowanceSuccess(response.data?.newAllowance, payload.index)
      );
    }

    // yield put(initEmployeeSuccess(refEmployee.data));
  } catch (error) {
    yield put(createNewAllowanceFailed(payload.index));
    yield put(showErrorToast(error?.message));
  }
}

function* onCreateNewAllowance() {
  yield takeEvery(Types.CREATE_ALLOWANCE_ATTEMPT, handleCreateNewAllowanceSaga);
}

export function* handleCreateNewDeductibleSaga({ payload }) {
  try {
    const response = yield call(createNewDeductible, payload);
    if ("errors" in response) {
      yield put(createNewDeductibleFailed(payload.index));
      yield put(
        showErrorToast(
          response?.errors[0]?.extensions?.validation[
            "input.name"
          ]?.toString() || response?.errors[0]?.message
        )
      );
    } else {
      yield put(
        createNewDeductibleSuccess(response.data?.newDeductible, payload.index)
      );
    }

    // yield put(initEmployeeSuccess(refEmployee.data));
  } catch (error) {
    yield put(createNewDeductibleFailed(payload.index));
    yield put(showErrorToast(error?.message));
  }
}

function* onCreateNewDeductible() {
  yield takeEvery(
    Types.CREATE_DEDUCTIBLE_ATTEMPT,
    handleCreateNewDeductibleSaga
  );
}

export function* handleCreateNewEmployeeSalarySaga({ payload }) {
  try {
    const response = yield call(createNewEmployeeSalary, payload);
    if ("errors" in response) {
      if (
        response?.errors[0]?.extensions?.validation &&
        "input.user_input.force_save" in
          response?.errors[0]?.extensions?.validation
      ) {
        swal({
          text: HelperFns.localizeFns("force_save"),
          icon: "warning",
          className: "swal-warning-style",
          buttons: true,
        }).then((forceSave) => {
          if (forceSave) {
            Store.dispatch(
              createNewEmployeeSalaryAction(payload.user_salary_config_input, {
                ...payload.user_input,
                force_save: true,
              })
            );
          }
        });
      }
      if (
        response?.errors[0]?.extensions?.validation &&
        "input.user_input.force_email" in
          response?.errors[0]?.extensions?.validation
      ) {
        swal({
          text: HelperFns.localizeFns("force_email"),
          icon: "warning",
          className: "swal-warning-style",
          buttons: true,
        }).then((forceSave) => {
          if (forceSave) {
            Store.dispatch(
              createNewEmployeeSalaryAction(payload.user_salary_config_input, {
                ...payload.user_input,
                force_email: true,
              })
            );
          }
        });
      }
      if (response?.errors[0]?.extensions?.validation) {
        yield put(
          createNewEmployeeSalaryFailed(
            HelperFns.parseValidatorErrors(
              response?.errors[0]?.extensions?.validation
            )
          )
        );
      } else {
        yield put(createNewEmployeeSalaryFailed(null));
        yield put(
          showErrorToast(
            response?.errors[0]?.extensions?.reason ||
              response?.errors[0]?.message
          )
        );
      }

      // yield put(
      //   showErrorToast(
      //     response?.errors[0]?.extensions?.validation[
      //       "input.name"
      //     ]?.toString() || response?.errors[0]?.message
      //   )
      // );
    } else {
      yield put(createNewEmployeeSalarySuccess(response));
      window.location.href = "/employees";
    }

    // yield put(initEmployeeSuccess(refEmployee.data));
  } catch (error) {
    yield put(createNewEmployeeSalaryFailed(null));
    yield put(showErrorToast(error?.message));
  }
}

function* onCreateNewEmployeeSalary() {
  yield takeEvery(
    Types.CREATE_NEW_EMPLOYEE_SALARY_ATTEMPT,
    handleCreateNewEmployeeSalarySaga
  );
}

export function* handleInitSalaryConfigInfoSaga({ payload }) {
  try {
    const response = yield call(initSalaryConfigInfo, payload);
    if (!response?.data) {
      yield put(initSalaryConfigFailed());
    } else {
      if (payload.isNew) {
        yield put(showNewUpsertSalaryConfigSuccess(response.data));
      } else {
        yield put(
          showEditUpsertSalaryConfigSuccess({
            ...response.data,
            salaryConfig: payload?.salaryConfig,
          })
        );
      }
    }
  } catch (error) {
    yield put(initSalaryConfigFailed());
    yield put(showErrorToast(error?.message));
  }
}

function* onInitEmployeeSalaryInfo() {
  yield takeEvery(
    Types.INIT_UPSERT_SALARY_CONFIG_FORM,
    handleInitSalaryConfigInfoSaga
  );
}

export function* handleInitActivateSalaryConfigInfoSaga({ payload }) {
  try {
    const response = yield call(initSalaryConfigInfo, payload);
    if (!"data" in response) {
      yield put(initActivateNewSalaryConfigFailed());
    } else {
      yield put(initActivateNewSalaryConfigSuccess(response.data));
    }
  } catch (error) {
    yield put(initActivateNewSalaryConfigFailed());
    yield put(showErrorToast(error?.message));
  }
}

function* onInitActivateEmployeeSalaryInfo() {
  yield takeEvery(
    Types.ADD_EMPLOYEE_ID_TO_ACTIVATION,
    handleInitActivateSalaryConfigInfoSaga
  );
}

export default {
  onCreateNewAllowance,
  onCreateNewDeductible,
  onCreateNewEmployeeSalary,
  onInitEmployeeSalaryInfo,
  onInitActivateEmployeeSalaryInfo,
};
