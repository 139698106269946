import React from "react";
import { BSelect, InputForm } from "form-builder";
import { useSelector, useDispatch } from "react-redux";
import {
  childInputChange,
  onInputResetWithValueAction,
} from "../../Store/Actions";
import { useTranslation } from "react-i18next";
import PaymentFactors from "../../Constants/PaymentFactors";
import { filterSalaryCofigPercentageOptions } from "../../Helpers/HelperFns";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";

const PenaltyCalculation = ({ i, FormProps, totalGross, totalNet }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const salaryConfigForm = useSelector((state) => state.super.salaryConfigForm);
  const penaltyConfiguration = salaryConfigForm?.penaltyConfiguration;
  const paymentFactors = useSelector((state) => state.super.paymentFactors);
  const monthOptions = useSelector((state) => state.super.monthOptions);

  const skipFirstREnder = useDidUpdateEffect(() => {
    handleChangePenaltyConfiguration("monthOption", null);
  }, [salaryConfigForm.payment_interval]);

  const renderCalcValue = () => {
    let base = +salaryConfigForm?.base_salary || 0;
    let gross = totalGross();
    let net = totalNet();

    // NOD : number of days
    const NOD = {
      1: new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate(),
      2: 30,
      3: 26,
      4: 22,
      5: +penaltyConfiguration?.customDays,
      6: 7,
      7: 5,
    };

    switch (penaltyConfiguration?.paymentFactor) {
      case PaymentFactors.BASE_PERCENTAGE:
        return (base / NOD[penaltyConfiguration?.monthOption]).toFixed(2);
      case PaymentFactors.GROSS_PERCENTAGE:
        return (gross / NOD[penaltyConfiguration?.monthOption]).toFixed(2);
      case PaymentFactors.NET_PERCENTAGE:
        return (net / NOD[penaltyConfiguration?.monthOption]).toFixed(2);
      default:
        return null;
    }
  };

  const handleChangePenaltyConfiguration = (name, value) => {
    dispatch(
      onInputResetWithValueAction(FormProps?.formName, "penaltyConfiguration", {
        ...penaltyConfiguration,
        [name]: value,
      })
    );
  };

  return (
    <div className="d-flex flex-fill align-items-end">
      <div className="col p-0">
        <BSelect
          {...FormProps}
          label="penalty day"
          labelStyle="font-weight-bold mb-2"
          name="paymentFactor"
          optionLabel="label"
          optionValue="value"
          options={paymentFactors}
          keepDefaultStyle
          containerStyle="my-0"
          placeholder={t("Select Payment Factor")}
          inputContainerStyle="w-100"
          validateBy="textRequired"
          optDependType="equal"
          optDependKey="calculation"
          optDependValue={["1", 1]}
          value={paymentFactors?.find(
            (opt) => opt?.value == penaltyConfiguration?.paymentFactor
          )}
          onChange={(value) =>
            handleChangePenaltyConfiguration("paymentFactor", value?.value)
          }
          validationName={`input.user_salary_config_input.salary_calculations.${i}.payment_factor_id`}
          icon="money"
          isDisabled={
            salaryConfigForm?.calculate_salary_by !== "payment_interval"
          }
        />
      </div>

      <div className="col-sm-12 col-md">
        {penaltyConfiguration?.paymentFactor == PaymentFactors.FIXED_AMOUNT ? (
          <InputForm
            name="value"
            type="number"
            onWheelCapture={(e) => {
              e.target.blur();
            }}
            {...FormProps}
            label="amount"
            placeholder={t("amount")}
            labelStyle="mb-2"
            containerStyle=""
            inputContainerStyle="w-100"
            validateBy="textRequired"
            onChange={(e) =>
              handleChangePenaltyConfiguration("amount", e?.target?.value)
            }
            value={penaltyConfiguration?.amount}
            validationName={`input.user_salary_config_input.salary_calculations.${i}.value`}
            icon="money"
          />
        ) : (
          <div className="d-flex align-items-baseline">
            <span className="min-w-85">
              {penaltyConfiguration?.paymentFactor ==
              PaymentFactors.BASE_PERCENTAGE
                ? `${t("base_salary")} /`
                : penaltyConfiguration?.paymentFactor ==
                  PaymentFactors.GROSS_PERCENTAGE
                ? `${t("gross_salary")} /`
                : `${t("net_salary")} /`}
            </span>
            <div className="col pr-lg-1 pl-lg-3">
              <BSelect
                {...FormProps}
                name="monthOption"
                optionLabel="label"
                optionValue="value"
                options={filterSalaryCofigPercentageOptions(
                  monthOptions,
                  salaryConfigForm.payment_interval
                )}
                keepDefaultStyle
                containerStyle=""
                placeholder="Select Days"
                inputContainerStyle="w-100"
                validateBy="textRequired"
                value={
                  monthOptions?.find(
                    (opt) => opt.value == penaltyConfiguration?.monthOption
                  ) ?? ""
                }
                onChange={(value) =>
                  handleChangePenaltyConfiguration("monthOption", value?.value)
                }
                validationName={`input.user_salary_config_input.salary_calculations.${i}.month_option_id`}
                icon="calendar"
              />
            </div>

            {penaltyConfiguration?.monthOption == "5" ? (
              <InputForm
                {...FormProps}
                name="customDays"
                type="number"
                onWheelCapture={(e) => {
                  e.target.blur();
                }}
                placeholder="Days"
                labelStyle="mb-0"
                containerStyle=""
                inputContainerStyle="days_wrapper_input_style"
                validateBy="textRequired"
                value={penaltyConfiguration?.customDays}
                onChange={(e) =>
                  handleChangePenaltyConfiguration(
                    "customDays",
                    e?.target?.value
                  )
                }
                validationName={`input.user_salary_config_input.salary_calculations.${i}.value`}
              />
            ) : null}

            {penaltyConfiguration?.monthOption == "1" ? null : (
              <span className="font-weight-bold ml-1">
                {!isNaN(renderCalcValue()) ? `= ${renderCalcValue()}` : `= 0`}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PenaltyCalculation;
