import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import HelperFns from "../../../Helpers/HelperFns";
import Privilages from "../../../Constants/Privilages";
import HasPrivileges from "../../../Helpers/HOC/HasPrivileges";

import {
  Reports,
  Calendar,
  Dashboard,
  LeadsList,
  TrialsList,
  AgentsList,
  RemindersList,
  ActivitiesList,
} from "../../../Containers/CRMLists";
import CRMTabs from "./CRMTabs";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CRMPage = () => {
  const query = useQuery();
  const history = useHistory();

  // Constants
  const activeValue = query.get("tab");

  /* ↓ State Effects ↓ */

  React.useEffect(() => {
    if (!activeValue) {
      renderfirstTab();
    }
  }, [activeValue]);

  /* ↓ Helpers ↓ */

  const renderfirstTab = () => {
    // Reports
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_CRM_REPORTS],
        allowBP: false,
      })
    ) {
      return history.push(`/crm?tab=reports`);
    }

    // Dashboard
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_CRM_DASHBOARD],
        allowBP: false,
      })
    ) {
      return history.push(`/crm?tab=dashboard`);
    }

    // Calender
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_CRM_CALENDAR],
        allowBP: false,
      })
    ) {
      return history.push(`/crm?tab=calendar`);
    }

    // Lead
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_LEADS],
        allowBP: false,
      })
    ) {
      return history.push(`/crm?tab=leads`);
    }

    // Activity
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_ACTIVITIES],
        allowBP: false,
      })
    ) {
      return history.push(`/crm?tab=activities`);
    }

    // Reminder
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_REMINDERS],
        allowBP: false,
      })
    ) {
      return history.push(`/crm?tab=reminders`);
    }

    // Trial
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_TRIALS],
        allowBP: false,
      })
    ) {
      return history.push(`/crm?tab=trials`);
    }

    // Agent
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_CRM_AGENTS],
        allowBP: false,
      })
    ) {
      return history.push(`/crm?tab=agents`);
    }

    return null;
  };

  const renderSelectedTab = () => {
    switch (activeValue?.toLowerCase()) {
      case "reports":
        return (
          <HasPrivileges reqireMain={[Privilages.VIEW_CRM_REPORTS]}>
            <Reports />
          </HasPrivileges>
        );
      case "dashboard":
        return (
          <HasPrivileges reqireMain={[Privilages.VIEW_CRM_DASHBOARD]}>
            <Dashboard />
          </HasPrivileges>
        );
      case "calendar":
        return (
          <HasPrivileges reqireMain={[Privilages.VIEW_CRM_CALENDAR]}>
            <Calendar />
          </HasPrivileges>
        );
      case "leads":
        return (
          <HasPrivileges reqireMain={[Privilages.VIEW_LEADS]}>
            <LeadsList />
          </HasPrivileges>
        );
      case "activities":
        return (
          <HasPrivileges reqireMain={[Privilages.VIEW_ACTIVITIES]}>
            <ActivitiesList />
          </HasPrivileges>
        );
      case "reminders":
        return (
          <HasPrivileges reqireMain={[Privilages.VIEW_REMINDERS]}>
            <RemindersList />
          </HasPrivileges>
        );
      case "trials":
        return (
          <HasPrivileges reqireMain={[Privilages.VIEW_TRIALS]}>
            <TrialsList />
          </HasPrivileges>
        );
      case "agents":
        return (
          <HasPrivileges reqireMain={[Privilages.VIEW_CRM_AGENTS]}>
            <AgentsList />
          </HasPrivileges>
        );
      default:
        break;
    }
  };

  return (
    <div className="content pt-3">
      <CRMTabs />
      {renderSelectedTab()}
    </div>
  );
};

export default CRMPage;
