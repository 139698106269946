import React from "react";
import { OverlayHeader } from "../../Routes/JobPostApplication";

const JobApplicationSuccess = () => {
  return (
    <div>
      <OverlayHeader />
      <div className="success-img-container">
        <img
          src={require("../../Assets/img/job-post-success.png")}
          className="job-post-success-image"
        />
        <h1 className="job-post-header">successfully submitted</h1>
        <p className="job-post-paragraph">
          Your Application has been {""}
          <i className="job-post-paragraph-success">successfully submitted</i>.
        </p>
        <p className="job-post-paragraph">
          Thank you for your interest in being a part of our team.
        </p>
        <p className="job-post-paragraph font-weight-bold">
          keep a close eye on your inbox, someone will contact you soon.
        </p>
      </div>
    </div>
  );
};

export default JobApplicationSuccess;
