import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Constants from "../../Helpers/Constants";
import moment from "moment";

import { InputForm } from "form-builder";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";

import {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import PaymentFactors from "../../Constants/PaymentFactors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import DeductionsPolicyModal from "./DeductionsPolicyModal";
import { DEDUCTION_POLICY_QUERY } from "../../Graphql/query";
import { useMutation, useQuery } from "@apollo/client";
import { DELETE_DEDUCTION_POLICY_MUTATION } from "../../Graphql/mutation";
import { showErrorToast } from "../../Store/Actions";

const dataTableRef = "DeductionsList";
const deductionPolicyModalInitState = {
  isOpen: false,
  data: null
}

const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};
const DeductionsPolicyList = (props) => {
  const { t } = useTranslation();
  const [deductionPolicyModal, setDeductionPolicyModal] = useState(deductionPolicyModalInitState);

  const handleTogglePolicy = () => {
    setDeductionPolicyModal(deductionPolicyModalInitState)
  }

  const [search, setSearch] = useState("")
  const [paginationData, setPaginationData] = useState(paginationInitState)


  const { loading, error, data, refetch } = useQuery(DEDUCTION_POLICY_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      search,
      page: 1
    },
    onCompleted: (data) => {
      if (data) {
        setPaginationData(data?.deduction_policies?.paginatorInfo)
      }
    }
  })

  const handleSearchInput = (e) => { setSearch(e?.target?.value) }



  const handlePaginate = (page = paginationData.currentPage) => {
    setPaginationData((prev) => ({ ...prev, currentPage: page }));
  };


  // for add new deductions modal
  const handleAddDeductions = () => {
    setDeductionPolicyModal((prevState) => ({
      ...prevState,
      isOpen: true
    }))

  };

  // for edit deductions modal
  const handleEditDeductionsModal = (data) => {
    let editRecord = {
      name: data?.name,
      violations: data?.violations,
      id: data?.id

    };
    setDeductionPolicyModal({
      isOpen: true,
      data: editRecord
    })
  };

  const dispatch = useDispatch();

  const [deleteDeductionPolicy, { data: deleteData, loading: deleteLoading, error: deleteError }] = useMutation(DELETE_DEDUCTION_POLICY_MUTATION, {
    onCompleted(res) {
      // do someting
      if (res?.delete_deduction_policy) {
        refetch();
      }
    },
    onError(error) {
      // do something
      if (error) {
        dispatch(
          showErrorToast(
            error?.graphQLErrors?.[0]?.extensions?.reason ||
            error?.graphQLErrors?.[0]?.message ||
            error?.message
          )
        );
      }
    }
  })

  // to delete deduction record
  const handleDeleteDeduction = (row) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: true,
      className: "swal-warning-style",
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        deleteDeductionPolicy({
          variables: {
            id: row?.id
          }
        })

      }
    });
  };

  // to approximate the numbers
  function roundToTwo(num) {
    return (+num)?.toFixed(2);
  }

  const columns = [
    {
      name: t("name"),
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 2.5,
    },
    {
      name: t("Violations"),
      wrap: true,
      selector: "amount",
      sortable: false,
      grow: 2.5,
      cell: (row) => (
        <div className="d-flex flex-row flex-wrap">
          {row?.violations?.map((viol, key) => (
            <div className="d-flex p-2 border" key={key}>
              {(viol?.amount)}{" "}
              <span className="pl-1">
                {viol?.type === "Fixed" ? (
                  <FontAwesomeIcon icon={faCoins} />
                )
                  : (
                    t("days")
                  )}
              </span>

            </div>
          ))}
        </div>
      ),
    },
    {
      name: t("actions"),
      wrap: true,
      sortable: false,
      right: true,
      grow: 1.2,
      cell: (row) =>
        <HasPrivileges
          reqireMain={[Privilages.ADD_EDIT_DELETE_BONUSES_DEDUCTIONS]}
          allowBP
          avalibleOnExpire={false}
          altExpireChildren={
            <div className="cards_table_actions">
              <EditIconButton />
              <RemoveIconButton />
            </div>
          }
        >
          <div className="cards_table_actions">
            <EditIconButton onClick={() => handleEditDeductionsModal(row)} />
            <RemoveIconButton onClick={() => handleDeleteDeduction(row)} />
          </div>
        </HasPrivileges>

    },
  ];

  return (
    <div className="extra_deductions_wrapper_style">
      <div className="row my-3 mx-0 justify-content-between align-items-baseline">
        <InputForm
          formName={dataTableRef}
          validateBy="textRequired"
          name="name"
          type="text"
          placeholder={t("search")}
          containerStyle="d-flex flex-grow-1"
          rootStyle="d-flex flex-grow-1"
          inputContainerStyle="d-flex flex-grow-1"
          value={search}
          onChange={handleSearchInput}
        />


        <div className="mx-2">
          <HasPrivileges
            reqireMain={[Privilages.ADD_EDIT_DELETE_BONUSES_DEDUCTIONS]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={
              <div>
                <button
                  className="btn add_new_btn_style"
                  type="button"
                  disabled
                >
                  {t("New Policy")}
                </button>
              </div>
            }
          >
            <div>
              <button
                className="btn add_new_btn_style"
                type="button"
                onClick={handleAddDeductions}
              >
                {t("New Policy")}
              </button>
            </div>
          </HasPrivileges>
        </div>
      </div>

      <DataTable
        noDataComponent={<div className="p-4">{t("no_records")}</div>}
        className="cards_table"
        columns={columns}
        data={data?.deduction_policies?.data ?? []}
        noHeader
        persistTableHead
        paginationComponent={() => (
          <Pagination
            customPaginator={paginationData}
            // tableRef={dataTableRef}
            styleWraper=""
            onPaginate={handlePaginate}
          // reducer="super"
          />
        )}
        pagination={true}
        paginationServer={true}
        progressPending={loading || deleteLoading}
        progressComponent={<Loader />}
      />

      {/* Start of  Add new deductions modal */}
      {deductionPolicyModal?.isOpen ? (
        <DeductionsPolicyModal modalData={deductionPolicyModal} toggle={handleTogglePolicy} refetch={refetch} />
      ) : null}
      {/* End of  Add new deductions modal */}

    </div>
  );
};



export default DeductionsPolicyList;
