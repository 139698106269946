import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  fetchApplicantProfileAction,
  deleteApplicantsAction,
  showEditApplicantEmailModalAction,
  deleteApplicantPhoneNumberAction,
  showEditApplicantPhoneModalAction,
  showAddApplicantPhoneModalAction,
} from "../../Store/Actions";
import Loader from "../../Components/Loader";
import ApplicantJobPost from "../../Components/ApplicantJobPost";
import { useTranslation } from "react-i18next";
import { KeyboardBackspace } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faTimes,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import swal from "sweetalert";
import EditApplicantEmailModal from "./EditApplicantEmailModal";
import EditApplicantPhoneModal from "./EditApplicantPhoneModal";
import AddApplicantPhoneModal from "./AddApplicantPhoneModal";

const ApplicantProfile = (props) => {
  const { applicantId } = useParams();
  const { t } = useTranslation();

  const [activeJobPost, setActiveJobPost] = useState(null);
  useEffect(() => {
    props.fetchApplicantProfileAction({
      id: applicantId,
    });
    return () => {};
  }, []);

  useEffect(() => {
    return () => {};
  }, [props.isLoading]);
  if (props.isLoading) {
    return <Loader />;
  }

  // to delete Applicant
  const handleDeleteApplicant = () => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: true,
      className: "swal-warning-style",
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteApplicantsAction(applicantId);
      }
    });
  };

  // handling edit applicant email modal
  const handleEditApplicantEmail = () => {
    let data = {
      applicant_id: applicantId,
      email: props?.selectedApplicantProfile?.email,
    };
    props.showEditApplicantEmailModalAction({
      data,
      ref: "editApplicantEmailModalActions",
      formName: "editApplicantEmailModal",
    });
  };

  // handling add applicant phone modal
  const handleAddApplicantPhoneNumber = () => {
    let data = {
      id: null,
      applicant_id: applicantId,
    };
    props.showAddApplicantPhoneModalAction({
      data,
      ref: "addApplicantPhoneModalActions",
      formName: "addApplicantPhoneModal",
    });
  };

  // handling edit applicant phone modal
  const handleEditApplicantPhone = (phone, phoneId) => {
    let data = {
      id: phoneId,
      applicant_id: applicantId,
      phone_number: phone,
    };
    props.showEditApplicantPhoneModalAction({
      data,
      ref: "editApplicantPhoneModalActions",
      formName: "editApplicantPhoneModal",
    });
  };

  // to delete Applicant phone number
  const handleDeleteApplicantPhoneNumber = (phone, phoneId) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: true,
      className: "swal-warning-style",
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteApplicantPhoneNumberAction(phoneId, applicantId, phone);
      }
    });
  };

  const handleToggleJobPostChange = (jobPost) => {
    if (activeJobPost && activeJobPost === jobPost) {
      setActiveJobPost(null);
    } else {
      setActiveJobPost(jobPost);
    }
  };

  return (
    <div className="content pt-2 applicant_profile_wrapper_style">
      {props?.data?.deleteApplicantLoading ||
      props?.data?.deleteApplicantPhoneNumberLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <Link to="/recruitment?tab=job-posts">
        <div className="back-btn d-flex align-items-center position-sticky mb-3">
          <KeyboardBackspace className="back-icon" />
          <span>{t("back")}</span>
        </div>
      </Link>

      <div className="row">
        <div className="col-12">
          <h3 className="page-title-bold">{t("applicant profile")}</h3>
        </div>
      </div>

      <div className="d-flex align-items-baseline justify-content-between mt-1 mb-3 subscription_list_wrapper_style employee-form">
        <div className="mb-2">
          <h4 className="sub-title-style mb-1">
            {props?.selectedApplicantProfile?.name}
          </h4>
          <hr />
        </div>

        <div className="d-flex mx-3">
          <div className="cursor-pointer">
            <FontAwesomeIcon
              style={{ color: "#ff6a6a" }}
              icon={faTrash}
              onClick={handleDeleteApplicant}
            />
          </div>
        </div>
      </div>

      <div className="row my-2">
        <div className="col-12 d-flex flex-column flex-md-row align-items-baseline">
          {props?.selectedApplicantProfile?.email ? (
            <div className="d-flex align-items-baseline flex-wrap">
              <div>
                <p className="mb-2 applicant_info_style text-lowercase">
                  {props?.selectedApplicantProfile?.email}
                </p>
              </div>

              <div className="cursor-pointer mx-md-3">
                <FontAwesomeIcon
                  icon={faPen}
                  onClick={handleEditApplicantEmail}
                />
              </div>
            </div>
          ) : null}

          {props?.selectedApplicantProfile?.phone_records &&
          props?.selectedApplicantProfile?.phone_records?.length ? (
            <div className="row">
              {props?.selectedApplicantProfile?.phone_records?.map(
                (phone, i) => (
                  <div className="col-12 d-flex align-items-baseline flex-wrap">
                    <div className="mx-md-4">
                      <p className="mb-0 applicant_info_style">
                        {phone?.phone_number}
                      </p>
                    </div>

                    <div className="cursor-pointer mx-3 mx-md-0">
                      <FontAwesomeIcon
                        icon={faPen}
                        onClick={() =>
                          handleEditApplicantPhone(
                            phone?.phone_number,
                            phone?.id
                          )
                        }
                      />
                    </div>

                    <div className="cursor-pointer mx-md-3">
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={() =>
                          handleDeleteApplicantPhoneNumber(
                            phone?.phone_number,
                            phone?.id
                          )
                        }
                      />
                    </div>

                    {i == 0 ? (
                      <div className="mx-2 subscription_list_wrapper_style employee-form">
                        <button
                          className="btn add_new_btn_style"
                          style={{ background: "#2764ac" }}
                          type="button"
                          onClick={handleAddApplicantPhoneNumber}
                        >
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                      </div>
                    ) : null}
                  </div>
                )
              )}
            </div>
          ) : null}
        </div>
      </div>

      <div className="row my-2">
        <div className="col-12">
          <label>{t("Applications")}</label>
        </div>
      </div>

      {props?.selectedApplicantProfile?.applicant_job_posts?.map(
        (jobPost, i) => (
          <ApplicantJobPost
            {...jobPost}
            key={jobPost.id}
            applicantId={applicantId}
            onToggleJobPostChange={handleToggleJobPostChange}
            activeJobPost={activeJobPost}
            i={i}
          />
        )
      )}

      {/* Start of Edit Applicant Email Modal */}
      <EditApplicantEmailModal />
      {/* End of Edit Applicant Email Modal */}

      {/* Start of Add Applicant Phone Number Modal */}
      <AddApplicantPhoneModal />
      {/* End of Add Applicant Phone Number Modal */}

      {/* Start of Edit Applicant Phone Number Modal */}
      <EditApplicantPhoneModal />
      {/* End of Edit Applicant Phone Number Modal */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state?.recruitment,
    selectedApplicantProfile: state?.recruitment?.selectedApplicantProfile,
    isLoading: state?.recruitment?.applicantProfileLoading,
  };
};

export default connect(mapStateToProps, {
  fetchApplicantProfileAction,
  deleteApplicantsAction,
  showEditApplicantEmailModalAction,
  deleteApplicantPhoneNumberAction,
  showEditApplicantPhoneModalAction,
  showAddApplicantPhoneModalAction,
})(ApplicantProfile);
