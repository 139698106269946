import React, { useEffect, useState } from "react";
import MainModal from "../../Components/MainModal";
import { useTranslation } from "react-i18next";
import { BSelect, RadioboxForm, CheckboxBooleanForm } from "form-builder";
import { InboundRequestTypeOptions, RequestTypeOptions } from "../../Constants/Requests";
import Constants from "../../Constants";
import AddIcon from "@mui/icons-material/Add";
import { TimesIconButton } from "../../Components/IconButtonWithTooltip";
import { useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { upsertRequestApprovalRule } from "../../Graphql/mutation";
import Swal from "sweetalert2";
import { requestsApprovalLayersListQuery } from "../../Graphql/query";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import HelperFns from "../../Helpers/HelperFns";

const formNameValidation = "requestApprovalFormValidations";

const ApplicableValuesInput = ({
  options = [],
  placeholder,
  value,
  onChange,
  optionValue = "value",
  optionLabel = "label",
  ...props
}) => {
  return (
    <BSelect
      name="applicableValues"
      options={options}
      optionValue={optionValue}
      optionLabel={optionLabel}
      keepDefaultStyle
      isClearable
      placeholder={placeholder}
      rootStyle="mr-0 mr-md-2 w-100"
      icon="type"
      value={value}
      onChange={onChange}
      isMulti
      {...props}
    />
  );
};

const AppliesOnAllEmployeeCheckBox = ({ checked, onChange }) => {
  return (
    <CheckboxBooleanForm
      options={["apply on all employees"]}
      name="appliesOnAllEmployees"
      type="checkbox"
      checked={checked}
      onChange={onChange}
      containerStyle="mb-2"
    />
  );
};

const RequestsApprovalLayersModal = ({
  isModalVissible,
  handleCloseModal,
  formData,
  handleInputChange,
  handleLayerChange,
  handleAddLayer,
  officesOptions,
  departmentsOptoins,
  employeesOptions,
  managersOptions,
  handleDeleteLayer,
  refetchQueries,
}) => {
  const { t } = useTranslation();

  const auth = useSelector((state) => state?.auth?.userProfile);

  const authOfficeId = auth?.office?.id;

  const authDepartmentId = auth?.department?.id;

  const canViewAllOffices = HelperFns.checkPrivileges({
    privileges: [Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_ALL_OFFICE],
    allowBP: true,
  });

  const canViewAllDepartments = HelperFns.checkPrivileges({
    privileges: [Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_ALL_DEPARTMENT],
    allowBP: true,
  });

  const [generalMessage, setGeneralMessage] = useState("");

  useEffect(() => {
    return () => {
      setGeneralMessage("");
    };
  }, []);

  const [formSubmitting, setFormSubmitting] = useState(false);

  const formProps = { formSubmitting, formNameValidation };

  const formValidations = useSelector(
    (state) => state.super?.requestApprovalFormValidations
  );

  const inputVariables = {
    rule_id: formData?.id,
    request_type_ids: formData?.requestsTypes?.map(
      (requestType) => requestType?.value
    ),
    applicable: {
      type: formData?.applicableType,
      values: formData?.applicableValues?.map(
        (applicableValue) => applicableValue?.id
      ),
      excluded_employee_ids: formData?.excludedEmployees?.map(
        (excludedEmployee) => excludedEmployee?.id
      ),
      applies_on_all: formData?.appliesOnAllEmployees,
    },
    layers: formData?.approvalLayers?.map((approvalLayer, i) => ({
      level: i + 1,
      acceptor_ids: approvalLayer?.acceptors?.map((acceptor) => acceptor?.id),
    })),
  };

  const [
    attemptUpsertRequestApprovalRule,
    { loading, error: requestRuleError },
  ] = useMutation(upsertRequestApprovalRule, {
    variables: inputVariables,
    onCompleted: (data) => {
      if (!!data?.save_request_multi_layer_rule) {
        handleCloseModal();
      }
    },
    onError: (error) => {
      setGeneralMessage(
        error?.graphQLErrors?.[0]?.extensions?.reason ??
          t("something went wrong")
      );
    },
    refetchQueries,
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (requestRuleError) {
      setGeneralMessage(
        requestRuleError?.graphQLErrors[0]?.extensions?.reason ??
          t("something went wrong")
      );
    } else {
      setGeneralMessage("");
    }
  }, [requestRuleError]);

  const handleSubmit = () => {
    setGeneralMessage("");
    setFormSubmitting(true);

    if (formValidations?.length) return;

    attemptUpsertRequestApprovalRule();
  };

  const allSelectedAccepotrs = formData?.approvalLayers
    ?.map((approvalLayer) =>
      approvalLayer?.acceptors?.map((acceptor) => acceptor?.id)
    )
    .flat(Infinity);

  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={handleCloseModal}
      modalTitle={t("new rule")}
      className="request_approval_layers_modal"
      btnLabel={t("save")}
      btnOnClick={handleSubmit}
      btnSubmitLoading={loading}
    >
      <BSelect
        name="requestsTypes"
        label={t("request types")}
        options={RequestTypeOptions}
        optionValue="value"
        optionLabel="label"
        users_by_role
        keepDefaultStyle
        isClearable
        placeholder={t("request types")}
        rootStyle="mr-0 mr-md-2 w-100"
        icon="type"
        value={formData?.requestsTypes}
        onChange={(value, target) => handleInputChange(target?.name, value)}
        isMulti
        validateBy="arrayRequired"
        {...formProps}
        // validationName="input.attendanceProfileId"
      />

      <h4 className="assignment-form-header">{t("applicable on")}</h4>

      <HasPrivileges
        allowBP
        reqireMain={[
          Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_MY_OFFICE,
          Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_ALL_OFFICE,
        ]}
      >
        <RadioboxForm
          name="applicableType"
          options={[
            {
              label: Constants.requestApprovalsApplicableTypes.Offices,
              value: Constants.requestApprovalsApplicableTypes.Offices,
              optProps: {
                onChange: (e) =>
                  handleInputChange(e.target.name, e.target.value),
              },
            },
          ]}
          value={formData.applicableType}
          type="radio"
          labelStyle="label-style"
          optionsContainerStyle="optionsContainerStyle row"
          optionItemStyle="col-6 px-0"
          optionInputStyle=" "
          optionLabelStyle="mb-0 mr-3"
        />
      </HasPrivileges>

      {formData.applicableType ===
      Constants.requestApprovalsApplicableTypes.Offices ? (
        <>
          <ApplicableValuesInput
            options={
              canViewAllOffices
                ? officesOptions
                : officesOptions?.filter((office) => office?.id == authOfficeId)
            }
            placeholder={t("select offices")}
            value={formData.applicableValues}
            onChange={(value, target) => handleInputChange(target?.name, value)}
            optionValue="id"
            optionLabel="name"
            validateBy="arrayRequired"
            {...formProps}
          />

          <AppliesOnAllEmployeeCheckBox
            checked={formData.appliesOnAllEmployees}
            onChange={(e) =>
              handleInputChange(e.target.name, !formData.appliesOnAllEmployees)
            }
          />

          {!formData?.appliesOnAllEmployees ? (
            <BSelect
              name="excludedEmployees"
              options={employeesOptions?.filter((employee) =>
                formData?.applicableValues
                  ?.map((applicableValue) => applicableValue?.id)
                  ?.includes(employee?.office?.id)
              )}
              optionValue="id"
              optionLabel="name"
              keepDefaultStyle
              isClearable
              placeholder={"exclude employees"}
              rootStyle="mr-0 mr-md-2 w-100"
              icon="type"
              value={formData?.excludedEmployees}
              onChange={(value, target) =>
                handleInputChange(target.name, value)
              }
              isMulti
              validateBy="arrayRequired"
              {...formProps}
            />
          ) : null}
        </>
      ) : null}

      <HasPrivileges
        allowBP
        reqireMain={[
          Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_MY_DEPARTMENT,
          Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_ALL_DEPARTMENT,
        ]}
      >
        <RadioboxForm
          name="applicableType"
          options={[
            {
              label: Constants.requestApprovalsApplicableTypes.Departments,
              value: Constants.requestApprovalsApplicableTypes.Departments,
              optProps: {
                onChange: (e) =>
                  handleInputChange(e.target.name, e.target.value),
              },
            },
          ]}
          value={formData.applicableType}
          type="radio"
          labelStyle="label-style"
          optionsContainerStyle="optionsContainerStyle row"
          optionItemStyle="col-6 px-0"
          optionInputStyle=" "
          optionLabelStyle="mb-0 mr-3"
        />
      </HasPrivileges>

      {formData.applicableType ===
      Constants.requestApprovalsApplicableTypes.Departments ? (
        <>
          <ApplicableValuesInput
            options={
              canViewAllDepartments
                ? departmentsOptoins
                : departmentsOptoins?.filter(
                    (department) => department?.id == authDepartmentId
                  )
            }
            placeholder={t("select departments")}
            value={formData.applicableValues}
            onChange={(value, target) => handleInputChange(target?.name, value)}
            optionValue="id"
            optionLabel="name"
            validateBy="arrayRequired"
            {...formProps}
          />

          <AppliesOnAllEmployeeCheckBox
            checked={formData.appliesOnAllEmployees}
            onChange={(e) =>
              handleInputChange(e.target.name, !formData.appliesOnAllEmployees)
            }
          />

          {!formData?.appliesOnAllEmployees ? (
            <BSelect
              name="excludedEmployees"
              options={employeesOptions?.filter((employee) =>
                formData?.applicableValues
                  ?.map((applicableValue) => applicableValue?.id)
                  ?.includes(employee?.department?.id)
              )}
              optionValue="id"
              optionLabel="name"
              keepDefaultStyle
              isClearable
              placeholder={"exclude employees"}
              rootStyle="mr-0 mr-md-2 w-100"
              icon="type"
              value={formData?.excludedEmployees}
              onChange={(value, target) =>
                handleInputChange(target.name, value)
              }
              isMulti
              validateBy="arrayRequired"
              {...formProps}
            />
          ) : null}
        </>
      ) : null}

      <HasPrivileges
        allowBP
        reqireMain={[Privilages.ADD_EDIT_DELETE_APPROVAL_LAYERS_ON_EMPLOYEES]}
      >
        <RadioboxForm
          name="applicableType"
          options={[
            {
              label: Constants.requestApprovalsApplicableTypes.Employees,
              value: Constants.requestApprovalsApplicableTypes.Employees,
              optProps: {
                onChange: (e) =>
                  handleInputChange(e.target.name, e.target.value),
              },
            },
          ]}
          value={formData.applicableType}
          type="radio"
          labelStyle="label-style"
          optionsContainerStyle="optionsContainerStyle row"
          optionItemStyle="col-6 px-0"
          optionInputStyle=" "
          optionLabelStyle="mb-0 mr-3"
        />
      </HasPrivileges>

      {formData.applicableType ===
      Constants.requestApprovalsApplicableTypes.Employees ? (
        <ApplicableValuesInput
          options={employeesOptions}
          placeholder={t("select employees")}
          value={formData.applicableValues}
          onChange={(value, target) => handleInputChange(target?.name, value)}
          optionValue="id"
          optionLabel="name"
          validateBy="arrayRequired"
          {...formProps}
        />
      ) : null}

      <div className="d-flex align-items-center">
        <h4 className="assignment-form-header mr-1">{t("approval layers")}</h4>
        <button
          onClick={handleAddLayer}
          className="btn add_new_btn_style add_new_approval_layer_button_style"
        >
          <AddIcon />
        </button>
      </div>

      {formData?.approvalLayers?.map((approvalLayer, i) => (
        <div>
          <p className="mb-0 select-def-label">
            {t("layer-level", {
              level: i + 1,
              suffix:
                i + 1 == 1
                  ? "st"
                  : i + 1 == 2
                  ? "nd"
                  : i + 1 == 3
                  ? "rd"
                  : "th",
            })}
          </p>
          <div className="d-flex align-items-center justify-content-between">
            <BSelect
              name={`applicableValues-${i}`}
              options={managersOptions?.filter(
                (employee) => !allSelectedAccepotrs?.includes(employee?.id)
              )}
              optionValue="id"
              optionLabel="name"
              keepDefaultStyle
              isClearable
              placeholder={t("select employees")}
              rootStyle="mr-0 mr-md-2 w-100"
              icon="type"
              value={approvalLayer?.acceptors}
              onChange={(value) => {
                handleLayerChange(i, value);
              }}
              isMulti
              validateBy="arrayRequired"
              {...formProps}
            />
            {formData?.approvalLayers?.length > 1 ? (
              <TimesIconButton
                className="layer-delete-icon"
                onClick={() => handleDeleteLayer(i)}
              />
            ) : null}
          </div>
        </div>
      ))}

      {formSubmitting ? (
        <div className="invalid-container-style">
          <p className="mb-0 mt-4 validity-msg-style">{generalMessage}</p>
        </div>
      ) : null}
    </MainModal>
  );
};

export default RequestsApprovalLayersModal;
