import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BSelect } from "form-builder";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";

import {
  fetchRequiredDocumentsAttempt,
  showAddDocumentsModalAction,
  showEditDocumentModalAction,
  onFormResetAction,
} from "../../Store/Actions";
import HelperFns from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";

// import Loader from "../../Components/Loader";
import Skeleton from "@mui/material/Skeleton";
import DataTable from "react-data-table-component";
import Documents from "../EmployeeProfile/Documents";
import UpsertDocumentsModal from "../EmployeeProfile/UpsertDocumentsModal";
import HistoryDocumentsModal from "../EmployeeProfile/HistoryDocumentsModal";

const dataTableRef = "required_documents";
const page_flag = "company_profile";

const RequiredDocuments = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Reducer State
  const companyID = useSelector(
    (state) => state.auth?.userProfile?.company?.id
  );
  const {
    missingDocuments,
    expiredDocuments,
    filterByEmployee,
    filterByDocument,
    employeesListOptions,
    documentPresetsOptions,
    isLoading: requiredDocumentsLoading,
  } = useSelector((state) => state.documents[dataTableRef]);

  /* ↓ State Effects ↓ */

  const fetchRequiredDocuments = () => {
    dispatch(
      fetchRequiredDocumentsAttempt(
        {
          company_id: companyID,
          employee_id: filterByEmployee,
          document_id: filterByDocument,
          page_flag,
        },
        HelperFns.checkPrivileges({
          allowBP: true,
          privileges: [Privilages.VIEW_MISSING_EXPIRED_DOCUMENT],
        })
      )
    );
  };

  useEffect(() => {
    fetchRequiredDocuments();
    return () => {
      dispatch(onFormResetAction(dataTableRef));
    };
  }, []);

  const handleFilter = () => {
    fetchRequiredDocuments();
  };

  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    filterByEmployee,
    filterByDocument,
  ]);

  /* ↓ Helpers ↓ */

  const handleshowAddDocumentsModal = (
    document_id,
    fields,
    userId,
    document_name,
    country
  ) => {
    dispatch(
      showAddDocumentsModalAction({
        ref: "upsertDocumentsModalActions",
        formName: "upsertDocumentsModal",
        fields,
        document_id,
        inputs: fields?.reduce(
          (obj, item) => Object.assign(obj, { [item.id]: "" }),
          {}
        ),
        userId,
        document_name,
        country,
      })
    );
  };

  const handleshowEditDocumentModal = (
    document_id,
    fields,
    inputs,
    files,
    user_document_id,
    userId,
    document_name,
    country
  ) => {
    dispatch(
      showEditDocumentModalAction({
        ref: "EditDocumentModalActions",
        formName: "EditDocumentModal",
        fields,
        document_id,
        inputs,
        files,
        user_document_id,
        userId,
        document_name,
        country,
      })
    );
  };

  const columns = [
    {
      // name: t("employee"),
      wrap: true,
      sortable: false,
      grow: 1.5,

      cell: (row) => (
        <div className="w-100 d-flex flex-column flex-md-row align-items-start justify-content-between">
          <div className="font-weight-bold">{row?.employee?.user?.name}</div>
          <div>
            <span className="mr-2">{t("no. of documents")}</span>
            <span className="font-weight-bold">{row?.documents?.length}</span>
          </div>
        </div>
      ),
    },
  ];

  // data provides access to your row data
  const ExpandedMissingComponent = ({ data }) => {
    return data?.documents?.map((doc) => (
      <Documents
        key={doc?.id}
        {...{
          document: doc,
          userId: data?.employee?.user?.id,
        }}
        handleshowAddDocumentsModal={handleshowAddDocumentsModal}
        handleshowEditDocumentModal={handleshowEditDocumentModal}
        containerClass="w-100"
        page_flag={page_flag}
      />
    ));
  };
  const ExpandedExpiredComponent = ({ data }) => {
    return data?.documents?.map((doc) => (
      <Documents
        key={doc?.id}
        {...doc}
        userId={data?.employee?.user?.id}
        handleshowAddDocumentsModal={handleshowAddDocumentsModal}
        handleshowEditDocumentModal={handleshowEditDocumentModal}
        containerClass="w-100"
        page_flag={page_flag}
      />
    ));
  };

  return (
    <div className="content required_documents_style no-padding-first-child pt-4">
      <div className="row">
        <div className="col-12">
          <h4 className="page-title">{t("employee documents")}</h4>
        </div>

        <BSelect
          reducer="documents"
          name="filterByEmployee"
          optionLabel="name"
          optionValue="id"
          options={employeesListOptions}
          formName={dataTableRef}
          placeholder={t("select employee")}
          keepDefaultStyle
          containerStyle="form-group"
          rootStyle="col-6"
          isClearable
          icon="person"
        />

        <BSelect
          reducer="documents"
          name="filterByDocument"
          // optionLabel="name"
          optionValue="id"
          options={documentPresetsOptions}
          formName={dataTableRef}
          placeholder={t("select document")}
          keepDefaultStyle
          containerStyle="form-group"
          rootStyle="col-6"
          isClearable
          getOptionLabel={(option) => HelperFns.getCountryShortName(option)}
          icon="document"
        />
      </div>

      <div className="row mt-3">
        {/* (Start) Documents Block */}
        {requiredDocumentsLoading ? (
          <>
            <div className="col">
              <Skeleton variant="rectangular" width="100%" height={90} />
            </div>
            <div className="col">
              <Skeleton variant="rectangular" width="100%" height={90} />
            </div>
          </>
        ) : (
          <>
            <div className="col documents-block">
              <div className="side-title">{t("missing documents")}</div>
              <div className="d-flex flex-column flex-lg-row justify-content-between">
                <div className="block-title">
                  {t("no. of documents")}
                  <span>{missingDocuments?.number_of_documents}</span>
                </div>
                <div className="block-title">
                  {t("no. of employees")}
                  <span>{missingDocuments?.number_of_employees}</span>
                </div>
              </div>
            </div>

            <div className="col documents-block">
              <div className="side-title">{t("expired documents")}</div>
              <div className="d-flex justify-content-between flex-column flex-lg-row">
                <div className="block-title">
                  {t("no. of documents")}
                  <span>{expiredDocuments?.number_of_documents}</span>
                </div>
                <div className="block-title">
                  {t("no. of employees")}
                  <span>{expiredDocuments?.number_of_employees}</span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className="row mt-1">
        <div className="col-12 col-md">
          {requiredDocumentsLoading ? (
            <Skeleton variant="rectangular" width="100%" height={270} />
          ) : (
            <DataTable
              noDataComponent={<div className="p-4"> {t("no_records")} </div>}
              columns={columns}
              data={missingDocuments?.employees_with_missing_documents || []}
              noHeader
              persistTableHead
              // progressPending={requiredDocumentsLoading}
              // progressComponent={<Loader />}
              expandableRows
              expandableRowsComponent={<ExpandedMissingComponent />}
            />
          )}
        </div>
        <div className="col">
          {requiredDocumentsLoading ? (
            <Skeleton variant="rectangular" width="100%" height={270} />
          ) : (
            <DataTable
              noDataComponent={<div className="p-4"> {t("no_records")} </div>}
              columns={columns}
              data={
                expiredDocuments?.employees_with_Expired_user_documents || []
              }
              noHeader
              persistTableHead
              // progressPending={requiredDocumentsLoading}
              // progressComponent={<Loader />}
              expandableRows
              expandableRowsComponent={<ExpandedExpiredComponent />}
            />
          )}
        </div>
      </div>

      {/* (End) Documents Block */}

      {/* Start of Upsert document Modal */}
      <UpsertDocumentsModal page_flag={page_flag} />
      <HistoryDocumentsModal
        page_flag={page_flag}
        handleshowAddDocumentsModal={handleshowAddDocumentsModal}
        handleshowEditDocumentModal={handleshowEditDocumentModal}
      />
      {/* End of Upsert document Modal */}
    </div>
  );
};

export default RequiredDocuments;
