import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import MyRequests from "../../Containers/MyRequests";
import MyBalance from "../../Containers/MyBalance";
import AttendanceLogs from "../../Containers/EmployeeProfile/AttendanceLogs";
import MyClaims from "../../Containers/MyClaims";
import {
  fetchEmployeeProfileSuccess,
  resetEmployeeProfileViewAction,
  setDataTableSortingAction,
} from "../../Store/Actions";
import Loader from "../../Components/Loader";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import HelperFns from "../../Helpers/HelperFns";
import { useQuery } from "@apollo/client";
import {
  employeeBalanceQuery,
  employeeClaimsQuery,
  employeeProfileQuery,
  employeeRequestsQuery,
} from "../../Graphql/query";
import { useSelector } from "react-redux";

const requestsRef = "employeeRequestsList";
const balanceRef = "employeeBalanceList";
const claimRef = `employeeProfileClaimsList`;

const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const GeneralTab = (props) => {
  const dispatch = useDispatch();
  const { userId } = useParams();

  const [requestsPagination, setRequestsPagination] =
    useState(paginationInitState);

  const [balancePagination, setBalancePagination] =
    useState(paginationInitState);

  const [claimsPagination, setClaimsPagination] = useState(paginationInitState);

  const employeeRequestsList = useSelector(
    (state) => state.super?.[requestsRef]
  );

  const employeeBalanceList = useSelector((state) => state.super?.[balanceRef]);

  const employeeClaimsList = useSelector((state) => state.super?.[claimRef]);

  const hasActiveSubscription = useSelector(
    (state) => state?.auth?.userProfile?.company?.hasActiveSubscription
  );

  const refetchQueries = [
    {
      query: employeeProfileQuery,
      variables: {
        id: userId,
        isExpired: !hasActiveSubscription,
      },
    },
    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_EMPLOYEE_REQUESTS],
      allowBP: true,
    })
      ? [
          {
            query: employeeRequestsQuery,
            variables: {
              id: userId,
              rows: 10,
              page: requestsPagination?.currentPage ?? 1,
              field: employeeRequestsList.sorting.key,
              order: employeeRequestsList.sorting.dir.toUpperCase(),
              status: employeeRequestsList.statusFilter,
              from: employeeRequestsList?.from,
              to: employeeRequestsList?.to,
              route: "employee_profile",
            },
          },
        ]
      : []),
    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_EMPLOYEE_BALANCE_ADJUSTMENT],
      allowBP: true,
    })
      ? [
          {
            query: employeeBalanceQuery,
            variables: {
              id: userId,
              rows: 10,
              page: balancePagination.currentPage ?? 1,
              field: employeeBalanceList?.sorting.key,
              order: employeeBalanceList?.sorting.dir.toUpperCase(),
              type: employeeBalanceList?.type,
              year: employeeBalanceList?.yearFilter,
            },
          },
        ]
      : []),

    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_EMPLOYEE_CLAIMS],
      allowBP: true,
    })
      ? [
          {
            query: employeeClaimsQuery,
            variables: {
              claimRows: 10,
              claimPage: claimsPagination?.currentPage ?? 1,
              id: userId,
              claimStatus: employeeClaimsList?.status,
              from: employeeClaimsList?.from,
              to: employeeClaimsList?.to,
            },
          },
        ]
      : []),
  ];

  // const isExpired = useSelector(
  //   (state) => state?.auth?.userProfile?.company?.hasActiveSubscription
  // );

  // const { loading: employeeProfileLoading } = useQuery(employeeProfileQuery, {
  //   variables: {
  //     id: userId,
  //     isExpired,
  //   },
  //   fetchPolicy: "network-only",
  //   nextFetchPolicy: "cache-first",
  //   onCompleted: (data) => {
  //     dispatch(fetchEmployeeProfileSuccess(data?.user));
  //   },
  // });

  const { data: employeeRequestsData, loading: employeesRequestsLoading, refetch } =
    useQuery(employeeRequestsQuery, {
      variables: {
        id: userId,
        rows: 10,
        page: requestsPagination?.currentPage,
        field: employeeRequestsList.sorting.key,
        order: employeeRequestsList.sorting.dir.toUpperCase(),
        status: employeeRequestsList.statusFilter,
        from: employeeRequestsList?.from,
        to: employeeRequestsList?.to,
        route: "employee_profile",
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      onCompleted: (data) => {
        setRequestsPagination(data?.users_requests?.paginatorInfo);
      },
    });

  // console.log(employeesRequestsLoading);

  const { data: employeeBalanceData, loading: employeeBalanceLoading } =
    useQuery(employeeBalanceQuery, {
      skip: !HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_EMPLOYEE_BALANCE_ADJUSTMENT],
        allowBP: true,
      }),
      variables: {
        id: userId,
        rows: 10,
        page: balancePagination.currentPage,
        field: employeeBalanceList?.sorting.key,
        order: employeeBalanceList?.sorting.dir.toUpperCase(),
        type: employeeBalanceList?.type,
        year: employeeBalanceList?.yearFilter,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-first",
      onCompleted: (data) => {
        setBalancePagination(data?.users_transactions?.paginatorInfo);
      },
    });

  const { data: employeeClaimsData, loading: employeeClaimsLoading } = useQuery(
    employeeClaimsQuery,
    {
      skip: !HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_EMPLOYEE_CLAIMS],
        allowBP: true,
      }),
      variables: {
        claimRows: 10,
        claimPage: claimsPagination.currentPage,
        id: userId,
        claimStatus: employeeClaimsList?.status,
        from: employeeClaimsList?.from,
        to: employeeClaimsList?.to,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-first",
      onCompleted: (data) => {
        setClaimsPagination(data?.employeeClaims?.paginatorInfo);
      },
    }
  );

  useEffect(() => {
    return () => {
      setRequestsPagination(paginationInitState);
      setBalancePagination(paginationInitState);
      setClaimsPagination(paginationInitState);
    };
  }, []);

  const handleRequestsSorting = (field, dir) => {
    props.setDataTableSortingAction(requestsRef, field.selector, dir);
  };

  const handleRequestsPaginate = (page = requestsPagination.currentPage) => {
    setRequestsPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const handleBalanceSorting = (field, dir) => {
    props.setDataTableSortingAction(balanceRef, field.selector, dir);
  };

  const handleBalancePaginate = (
    page = props.data[balanceRef].pagination.currentPage
  ) => {
    setBalancePagination((prev) => ({ ...prev, currentPage: page }));
  };

  const handleClaimsPaginate = (
    page = props.data[claimRef].pagination.currentPage
  ) => {
    setClaimsPagination((prev) => ({ ...prev, currentPage: page }));
  };

  // if (employeesRequestsLoading) {
  //   return <Loader />;
  // }

  return (
    <div className="profile-detials">
      <div className="row content align-items-start ml-0">
        {props.shouldRenderComponent([Privilages.VIEW_EMPLOYEE_REQUESTS]) && (
          <>
            <MyRequests
              reducer="super"
              dataTableRef={requestsRef}
              onSorting={handleRequestsSorting}
              onPaginate={handleRequestsPaginate}
              employeeId={userId}
              employeeRequestsData={
                employeeRequestsData?.users_requests?.data ?? []
              }
              pagination={requestsPagination}
              refetchQueries={refetchQueries}
              isLoading={employeesRequestsLoading}
              refetchRequests = {refetch}
            />
          </>
        )}

        {props.shouldRenderComponent([
          Privilages.VIEW_EMPLOYEE_BALANCE_ADJUSTMENT,
        ]) && (
          <>
            <MyBalance
              employeeId={userId}
              reducer="super"
              dataTableRef={balanceRef}
              onSorting={handleBalanceSorting}
              onPaginate={handleBalancePaginate}
              user={props.profile}
              employeeBalanceData={
                employeeBalanceData?.users_transactions?.data ?? []
              }
              isLoading={employeeBalanceLoading}
              pagination={balancePagination}
            />
          </>
        )}
      </div>

      <div className="row content ml-0">
        {props.shouldRenderComponent([Privilages.VIEW_EMPLOYEE_CLAIMS]) && (
          <div className="col-12 col-lg-6 px-0">
            <MyClaims
              employeeId={userId}
              reducer="super"
              dataTableRef={claimRef}
              onPaginate={handleClaimsPaginate}
              isEmployee
              employeeClaimsData={
                employeeClaimsData?.employeeClaims?.data ?? []
              }
              pagination={claimsPagination ?? {}}
              isLoading={employeeClaimsLoading}
            />
          </div>
        )}

        {/* {props.shouldRenderComponent([
          Privilages.VIEW_ATTENDANCE_HISTORY_LOGS,
          Privilages.MANAGE_EMPLOYEE_ATTENDANCE,
        ]) && (
          <div className="col-12 col-lg-6 px-0">
            <AttendanceLogs />
          </div>
        )} */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.super.selectedEmployeeProfile,
    data: state.super,
    isLoading: state?.super?.employeeProfileLoading,
  };
};

export default connect(mapStateToProps, {
  setDataTableSortingAction,
})(GeneralTab);
