import React from "react";
import { InputForm, CheckboxBooleanForm } from "form-builder";

// not in reducer
const SickLeavesSection = ({ formProps }) => {
  return (
    <>
      <CheckboxBooleanForm
        {...formProps}
        options={["Allow Employees To Request Sick Leaves"]}
        name="allow_sick_leaves"
        validationName="input.sick_leave_setting.sick_leaves"
        type="checkbox"
        containerStyle="leaves_checkbox_container mb-2"
      />

      
    <CheckboxBooleanForm
        {...formProps}
        options={["Employee must submit leave request before the start of work day"]}
        name="allow_sick_leave_cutoff"
        validationName="input.sick_leave_setting.sick_leaves"
        type="checkbox"
        containerStyle="leaves_checkbox_container mb-2"
        dependOn="allow_sick_leaves"
        dependancyType="equal"
        dependancyValue={[1]}
        setWithValue
      />

      <InputForm
        {...formProps}
        validateBy="textRequired"
        label="Employee must submit leave request before the start of work day by"
        name="sick_leave_cutoff"
        validationName="input.sick_leave_setting.sick_leave_cutoff"
        type="text"
        hasSuffix
        suffixTitle="Hours"
        containerStyle="leaves_field_container my-2"
        inputContainerStyle="d-flex align-items-center"
        dependOn="allow_sick_leave_cutoff"
        dependancyType="equal"
        dependancyValue={[true]}
      />

      <InputForm
        {...formProps}
        validateBy="textRequired"
        label="Number Of Sick Leaves Per Year"
        name="allowed_sick_leaves"
        validationName="input.sick_leave_setting.allowed_sick_leaves"
        type="text"
        dependOn="allow_sick_leaves"
        dependancyType="equal"
        dependancyValue={[1]}
        hasSuffix
        suffixTitle="Days"
        containerStyle="leaves_field_container"
        inputContainerStyle="d-flex align-items-center"
      />

      <CheckboxBooleanForm
        {...formProps}
        options={[
          "Include Holidays and Weekends When Calculating Sick Leave Durations",
        ]}
        name="include_weekends_and_holidays_in_sick"
        validationName="input.sick_leave_setting.include_weekends_and_holidays_in_sick"
        type="checkbox"
        dependOn="allow_sick_leaves"
        dependancyType="equal"
        dependancyValue={[1]}
        containerStyle="leaves_checkbox_container mt-2"
      />
    </>
  );
};

export default SickLeavesSection;
