// This is the validation rules used to validate each feild in the form
import * as Yup from "yup";
import moment from "moment";

const validationSchema = Yup.object().shape({
  id: Yup.string(),
  idRequired: Yup.string().required("validation.filled"),
  text: Yup.string().nullable(),
  textRequired: Yup.string().required("validation.filled").nullable(),
  email: Yup.string().email().nullable(true),
  emailRequired: Yup.string()
    .email("validation.email")
    .required("validation.filled"),
  selectorId: Yup.string().matches(/^(?!0*(\.0+)?$)(\d+|\d*\.\d+)$/, {
    message: "Please Select an Option",
  }),
  number: Yup.string().matches(/^\d*[.]?\d*$/, {
    message: "validation.numeric",
  }),
  numberRequired: Yup.string().matches(/^\d*[.]?\d*$/, {
    message: "validation.numeric",
  }),
  selectorIdRequired: Yup.string()
    .matches(/^(?!0*(\.0+)?$)(\d+|\d*\.\d+)$/, {
      message: "Please Select an Option",
    })
    .required("validation.filled"),
  selectorBoolean: Yup.string(),
  selectorBooleanRequired: Yup.string().required("validation.filled"),
  array: Yup.array(),
  arrayRequired: Yup.array().required("validation.filled"),
  time: Yup.string().required("validation.filled"),
  start_time: Yup.string().test(
    "is-greater",
    "validation.start_time_before_end_time",
    function (startTime) {
      const { endTime } = this.options.context;

      return moment(endTime, "HH:mm").isSameOrAfter(moment(startTime, "HH:mm"));
    }
  ),
  end_time: Yup.string().test(
    "is-greater",
    "validation.end_time_before_start_time",
    function (endTime) {
      const { startTime } = this.options.context;

      return moment(endTime, "HH:mm").isSameOrAfter(moment(startTime, "HH:mm"));
    }
  ),
  start_date: Yup.string().test(
    "is-greater",
    "validation.start_date_before_end_date",
    function (startDate) {
      const { endDate } = this.options.context;

      return moment(endDate, "yyyy/MM/DD").isSameOrAfter(
        moment(startDate, "yyyy/MM/DD")
      );
    }
  ),
  end_date: Yup.string()
    .required("validation.end_date")
    .test(
      "is-greater",
      "validation.end_date_before_start_date",
      function (endDate) {
        const { startDate } = this.options.context;        

        return moment(endDate, "yyyy/MM/DD").isSameOrAfter(
          moment(startDate, "yyyy/MM/DD")
        );
      }
    ),

  week_day: Yup.string().test(
    "is-week-day",
    "validation.is_week_day",
    function (week_day) {
      return week_day && week_day >= 1 && week_day <= 7;
    }
  ),
});

const ValidateEmailSchema = Yup.object().shape({
  email: Yup.string().email("validation.email").required("validation.filled"),
});
const ValidateTextSchema = Yup.object().shape({
  text: Yup.string().required("validation.filled"),
});

const workTimingsValidation = Yup.object().shape({
  name: Yup.string().required("FIRST"),
  sign_in_start_time: Yup.string().when("sign_in_req", {
    is: 1,
    then: Yup.string()
      .required("FIRST")
      .test("is-greater", "FIRST", function (startTime) {
        const { time_allowed_before_sign_in } = this.options.parent;
        return true;
        // moment(time_allowed_before_sign_in, "HH:mm").isSameOrBefore(
        //   moment(startTime, "HH:mm")
        // );
      }),
  }),
  sign_in_end_time: Yup.string().when("sign_in_req", {
    is: 1,
    then: Yup.string()
      .required("FIRST")
      .test("is-greater", "FIRST", function (endTime) {
        const { sign_in_start_time } = this.options.parent;
        return true;
        // moment(sign_in_start_time, "HH:mm").isSameOrBefore(
        //   moment(endTime, "HH:mm")
        // );
      }),
  }),
  work_hours: Yup.string().when("sign_in_req", {
    is: 1,
    then: Yup.string().required("FIRST"),
  }),
  end_of_day: Yup.string().when("sign_in_req", {
    is: 1,
    then: Yup.string().required("FIRST"),
  }),
  time_allowed_before_sign_in: Yup.string().when("sign_in_req", {
    is: 1,
    then: Yup.string()
      .required("FIRST")
      .test("is-greater", "FIRST", function (time_allowed_before_sign_in) {
        const { sign_in_start_time } = this.options.parent;
        return true;
        // moment(time_allowed_before_sign_in, "HH:mm").isSameOrBefore(
        //   moment(sign_in_start_time, "HH:mm")
        // );
      }),
  }),

  // tardiness validations
  late_reset_period: Yup.string().when(
    ["apply_minor_tardiness", "sign_in_req"],
    {
      is: 1,
      then: Yup.string().required("FIRST"),
    }
  ),
  late_tardiness_penalty_type: Yup.string().when(
    ["apply_minor_tardiness", "sign_in_req"],
    {
      is: 1,
      then: Yup.string().required("FIRST"),
    }
  ),
  max_lateness_permissibility: Yup.string().when(
    ["apply_minor_tardiness", "sign_in_req"],
    {
      is: 1,
      then: Yup.string()
        .required("FIRST")
        .test("is-greater", "FIRST", function (max_lateness_permissibility) {
          const { sign_in_end_time } = this.options.parent;
          return true;
          moment(sign_in_end_time, "HH:mm").isSameOrBefore(
            moment(max_lateness_permissibility, "HH:mm")
          );
        }),
      // then: Yup.string().required("FIRST"),
    }
  ),
  minor_violations: Yup.array().when(["apply_minor_tardiness", "sign_in_req"], {
    is: 1,
    then: Yup.array().of(
      Yup.object({
        value: Yup.string().required("FIRST"),
      })
    ),
  }),

  minor_tardiness_range: Yup.string().when(
    ["apply_minor_tardiness", "sign_in_req"],
    {
      is: 1,
      then: Yup.string()
        .required("FIRST")
        .test("is-greater", "FIRST", function (minor_tardiness_range) {
          const { max_lateness_permissibility } = this.options.parent;
          return true;
          moment(max_lateness_permissibility, "HH:mm").isSameOrBefore(
            moment(minor_tardiness_range, "HH:mm")
          );
        }),
    }
  ),
  major_tardiness_range: Yup.string().when(
    ["apply_major_tardiness", "sign_in_req"],
    {
      is: 1,
      then: Yup.string()
        .required("FIRST")
        .test("is-greater", "FIRST", function (major_tardiness_range) {
          const { minor_tardiness_range } = this.options.parent;
          return true;
          moment(minor_tardiness_range, "HH:mm").isSameOrBefore(
            moment(major_tardiness_range, "HH:mm")
          );
        }),
    }
  ),
  major_violations: Yup.array().when(["apply_major_tardiness", "sign_in_req"], {
    is: 1,
    then: Yup.array().of(
      Yup.object({
        value: Yup.string().required("FIRST"),
      })
    ),
  }),
  major_tardiness_range1: Yup.string().when(
    ["apply_major_tardiness1", "sign_in_req"],
    {
      is: 1,
      then: Yup.string()
        .required("FIRST")
        .test("is-greater", "FIRST", function (major_tardiness_range1) {
          const { major_tardiness_range } = this.options.parent;
          return true;
          moment(major_tardiness_range, "HH:mm").isSameOrBefore(
            moment(major_tardiness_range1, "HH:mm")
          );
        }),
    }
  ),
  major_violations1: Yup.array().when(
    ["apply_major_tardiness1", "sign_in_req"],
    {
      is: 1,
      then: Yup.array().of(
        Yup.object({
          value: Yup.string().required("FIRST"),
        })
      ),
    }
  ),
  major_tardiness_range2: Yup.string().when(
    ["apply_major_tardiness2", "sign_in_req"],
    {
      is: 1,
      then: Yup.string()
        .required("FIRST")
        .test("is-greater", "FIRST", function (major_tardiness_range2) {
          const { major_tardiness_range1 } = this.options.parent;
          return true;
          moment(major_tardiness_range1, "HH:mm").isSameOrBefore(
            moment(major_tardiness_range2, "HH:mm")
          );
        }),
    }
  ),
  major_violations2: Yup.array().when(
    ["apply_major_tardiness2", "sign_in_req"],
    {
      is: 1,
      then: Yup.array().of(
        Yup.object({
          value: Yup.string().required("FIRST"),
        })
      ),
    }
  ),
  major_tardiness_range3: Yup.string().when(
    ["apply_major_tardiness3", "sign_in_req"],
    {
      is: 1,
      then: Yup.string()
        .required("FIRST")
        .test("is-greater", "FIRST", function (major_tardiness_range3) {
          const { major_tardiness_range2 } = this.options.parent;
          return true;
          moment(major_tardiness_range2, "HH:mm").isSameOrBefore(
            moment(major_tardiness_range3, "HH:mm")
          );
        }),
    }
  ),
  major_violations3: Yup.array().when(
    ["apply_major_tardiness3", "sign_in_req"],
    {
      is: 1,
      then: Yup.array().of(
        Yup.object({
          value: Yup.string().required("FIRST"),
        })
      ),
    }
  ),
  major_tardiness_range4: Yup.string().when(
    ["apply_major_tardiness4", "sign_in_req"],
    {
      is: 1,
      then: Yup.string()
        .required("FIRST")
        .test("is-greater", "FIRST", function (major_tardiness_range4) {
          const { major_tardiness_range3 } = this.options.parent;
          return true;
          moment(major_tardiness_range3, "HH:mm").isSameOrBefore(
            moment(major_tardiness_range4, "HH:mm")
          );
        }),
    }
  ),
  major_violations4: Yup.array().when(
    ["apply_major_tardiness4", "sign_in_req"],
    {
      is: 1,
      then: Yup.array().of(
        Yup.object({
          value: Yup.string().required("FIRST"),
        })
      ),
    }
  ),
  major_tardiness_range5: Yup.string().when(
    ["apply_major_tardiness5", "sign_in_req"],
    {
      is: 1,
      then: Yup.string()
        .required("FIRST")
        .test("is-greater", "FIRST", function (major_tardiness_range5) {
          const { major_tardiness_range4 } = this.options.parent;
          return true;
          moment(major_tardiness_range4, "HH:mm").isSameOrBefore(
            moment(major_tardiness_range5, "HH:mm")
          );
        }),
    }
  ),
  major_violations5: Yup.array().when(
    ["apply_major_tardiness5", "sign_in_req"],
    {
      is: 1,
      then: Yup.array().of(
        Yup.object({
          value: Yup.string().required("FIRST"),
        })
      ),
    }
  ),
  major_tardiness_range6: Yup.string().when(
    ["apply_major_tardiness6", "sign_in_req"],
    {
      is: 1,
      then: Yup.string()
        .required("FIRST")
        .test("is-greater", "FIRST", function (major_tardiness_range6) {
          const { major_tardiness_range5 } = this.options.parent;
          return true;
          moment(major_tardiness_range5, "HH:mm").isSameOrBefore(
            moment(major_tardiness_range6, "HH:mm")
          );
        }),
    }
  ),
  major_violations6: Yup.array().when(
    ["apply_major_tardiness6", "sign_in_req"],
    {
      is: 1,
      then: Yup.array().of(
        Yup.object({
          value: Yup.string().required("FIRST"),
        })
      ),
    }
  ),
  major_tardiness_range7: Yup.string().when(
    ["apply_major_tardiness7", "sign_in_req"],
    {
      is: 1,
      then: Yup.string()
        .required("FIRST")
        .test("is-greater", "FIRST", function (major_tardiness_range7) {
          const { major_tardiness_range6 } = this.options.parent;
          return true;
          moment(major_tardiness_range6, "HH:mm").isSameOrBefore(
            moment(major_tardiness_range7, "HH:mm")
          );
        }),
    }
  ),
  major_violations7: Yup.array().when(
    ["apply_major_tardiness7", "sign_in_req"],
    {
      is: 1,
      then: Yup.array().of(
        Yup.object({
          value: Yup.string().required("FIRST"),
        })
      ),
    }
  ),
  major_tardiness_range8: Yup.string().when(
    ["apply_major_tardiness8", "sign_in_req"],
    {
      is: 1,
      then: Yup.string()
        .required("FIRST")
        .test("is-greater", "FIRST", function (major_tardiness_range8) {
          const { major_tardiness_range7 } = this.options.parent;
          return true;
          moment(major_tardiness_range7, "HH:mm").isSameOrBefore(
            moment(major_tardiness_range8, "HH:mm")
          );
        }),
    }
  ),
  major_violations8: Yup.array().when(
    ["apply_major_tardiness8", "sign_in_req"],
    {
      is: 1,
      then: Yup.array().of(
        Yup.object({
          value: Yup.string().required("FIRST"),
        })
      ),
    }
  ),
  major_tardiness_range9: Yup.string().when(
    ["apply_major_tardiness9", "sign_in_req"],
    {
      is: 1,
      then: Yup.string()
        .required("FIRST")
        .test("is-greater", "FIRST", function (major_tardiness_range9) {
          const { major_tardiness_range8 } = this.options.parent;
          return true;
          moment(major_tardiness_range8, "HH:mm").isSameOrBefore(
            moment(major_tardiness_range9, "HH:mm")
          );
        }),
    }
  ),
  major_violations9: Yup.array().when(
    ["apply_major_tardiness9", "sign_in_req"],
    {
      is: 1,
      then: Yup.array().of(
        Yup.object({
          value: Yup.string().required("FIRST"),
        })
      ),
    }
  ),
  major_tardiness_range10: Yup.string().when(
    ["apply_major_tardiness10", "sign_in_req"],
    {
      is: 1,
      then: Yup.string()
        .required("FIRST")
        .test("is-greater", "FIRST", function (major_tardiness_range10) {
          const { major_tardiness_range9 } = this.options.parent;
          return true;
          moment(major_tardiness_range9, "HH:mm").isSameOrBefore(
            moment(major_tardiness_range10, "HH:mm")
          );
        }),
    }
  ),
  major_violations10: Yup.array().when(
    ["apply_major_tardiness10", "sign_in_req"],
    {
      is: 1,
      then: Yup.array().of(
        Yup.object({
          value: Yup.string().required("FIRST"),
        })
      ),
    }
  ),

  // absent Validations
  absent_reset_period: Yup.string().required("FIRST"),
  emergency_penalty_type: Yup.string().required("FIRST"),
  absent_violations: Yup.array().of(
    Yup.object({
      value: Yup.string().required("FIRST"),
    })
  ),

  // sign out validations
  early_signout_reset_period: Yup.string().when(
    ["allow_early_signout", "sign_in_req"],
    {
      is: 1,
      then: Yup.string().required("SECOND"),
    }
  ),
  early_signout_deduction_type: Yup.string().when(
    ["allow_early_signout", "sign_in_req"],
    {
      is: 1,
      then: Yup.string().required("SECOND"),
    }
  ),
  early_sign_out_violations: Yup.array().when(
    ["allow_early_signout", "sign_in_req"],
    {
      is: 1,
      then: Yup.array().of(
        Yup.object({
          value: Yup.string().required("SECOND"),
        })
      ),
    }
  ),
  sign_out_cuttoff_time: Yup.string().when(
    ["absent_early_sign_out", "sign_in_req"],
    {
      is: 1,
      then: Yup.string().required("SECOND"),
    }
  ),
  missing_sign_out_violations: Yup.array().when(
    ["apply_missing_sign_out", "sign_in_req"],
    {
      is: 1,
      then: Yup.array().of(
        Yup.object({
          value: Yup.string().required("SECOND"),
        })
      ),
    }
  ),

  //overtime validations

  min_overtime_cuttoff: Yup.string().when(
    ["overtime_permissible", "sign_in_req"],
    {
      is: 1,
      then: Yup.string().required("SECOND"),
    }
  ),

  max_overtime_per_day: Yup.string().when(["overtime_limit", "sign_in_req"], {
    is: 1,
    then: Yup.string().required("SECOND"),
  }),
  // max_overtime_per_month: Yup.string().when(["overtime_limit", "sign_in_req"], {
  //   is: 1,
  //   then: Yup.string().required("SECOND"),
  // }),

  // Sign (in-out) Settings validations
  auto_sign_out_time: Yup.string().when(["auto_sign_out", "sign_in_req"], {
    is: 1,
    then: Yup.string().required("THIRD"),
  }),

  sign_in_reminder_time: Yup.string().nullable().when(
    ["sign_in_reminder", "sign_in_req"],
    {
      is: 1,
      then: Yup.string().required("THIRD"),
    }
  ),
  sign_out_reminder_time: Yup.string().when(
    ["sign_out_reminder", "sign_in_req"],
    {
      is: 1,
      then: Yup.string().required("THIRD"),
    }
  ),
});

export default {
  ValidateEmailSchema,
  ValidateTextSchema,
  validationSchema,
  workTimingsValidation,
};
