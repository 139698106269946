import React from "react";
import { useTranslation } from "react-i18next";

const LoginLayout = (props) => {
  const { t } = useTranslation();
  return (
    <main className="login-body-wrapper vh-100">
      <div className="container-fluid">
        <div className="row vh-100">
          <div className="col-12 col-lg-7 order-lg-2 px-0 bg_img_style">
            <img
              aria-hidden="true"
              src={require("../../Assets/img/login-bg-mobile.png")}
              className="img-fluid vw-100 vh-100 mobile_bg_img_style"
              alt=""
            />
            <div className="overlay"></div>
          </div>

          <div className="col-12 col-lg-5 contents">
            <div className="row justify-content-center h-100 align-items-center">
              <div className="col-12 col-lg-8 padding-md-style">
                <div className="my-5 pb-4 text-center">
                  <img
                    src={require("../../Assets/img/logo.svg").default}
                    width="230"
                    height="230"
                    className="img-fluid mb-3"
                    alt="Mawared Logo"
                  />
                  <p className="mb-0 first_welcome_text_style">
                    {t("welcome")}
                  </p>
                  <p className="second_welcome_text_style">
                    {t("to Mawared HR system")}
                  </p>
                </div>

                {props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default LoginLayout;
