import React, { useEffect, useState } from "react";
import AnnualLeavesSection from "./AnnualLeavesSection/AnnualLeavesSection";
import SickLeavesSection from "./SickLeavesSection/SickLeavesSection";
import UnpaidLeavesSection from "./UnpaidLeavesSection/UnpaidLeavesSection";
import PermissionsSection from "./PermissionsSection/PermissionsSection";
import BreaksSection from "./BreaksSection/BreaksSection";
import FooterSection from "./FooterSection/FooterSection";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

import { useTranslation } from "react-i18next";

import { InputForm, RadioboxForm } from "form-builder";
import AbilitySubmitRequestsSection from "./AbilitySubmitRequestsSection";
import CustomLeavesSection from "./CustomLeavesSection";
import { useDispatch } from "react-redux";
import { onFormResetAction } from "../../Store/Actions";

// props is only passed if it is rendered inside leaves breaks modal
const LeavesBreaksForm = ({
  setIsModalOpen,
  fetchLeavesBreaks,
  isSetupWizard = false,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(onFormResetAction("isAnnualLeavesAllowedInLeaveBreakProfile"));
    };
  }, []);

  //   form props
  const formName = "leavesBreaksForm";
  const formNameValidation = "leavesBreaksFormValidation";
  const formServerValidation = "leavesBreaksFormServerValidation";

  const formProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
  };

  return (
    <div className="leaves_breaks_form">
      {/* Step 1 */}
      {currentStep === 1 ? (
        <>
          <div className="name_field">
            <InputForm
              {...formProps}
              validateBy="textRequired"
              label={t("name")}
              name="name"
              validationName="input.name"
              type="text"
              placeholder={t("name")}
              containerStyle="name_field_container"
              inputContainerStyle="name_field_input"
              labelStyle="name_field_label"
              icon="person"
            />
          </div>
          <RadioboxForm
            {...formProps}
            name="applicable_type"
            label="apply to employees working according to"
            validationName="input.annual_leave_setting.applicable_type"
            options={[
              {
                label: t("fixed work timings"),
                value: "fixed",
              },
              {
                label: t("flexible work schedule"),
                value: "flexible",
                optProps: { disabled: true },
              },
            ]}
            optionsContainerStyle="d-flex gap-10"
            containerStyle="d-flex gap-20 mt-4"
            optionInputStyle=" "
          />

          <h1 className="subtitle">{t("leaves")}</h1>

          <div className="options_container annual_leaves">
            <span className="options_container_title">{t("annual leave")}</span>
            <AnnualLeavesSection formProps={formProps} />
          </div>

          <div className="options_container sick_leaves">
            <span className="options_container_title">{t("sick leaves")}</span>
            <SickLeavesSection formProps={formProps} />
          </div>

          <div className="options_container unpaid_leaves">
            <span className="options_container_title">
              {t("unpaid leaves")}
            </span>
            <UnpaidLeavesSection formProps={formProps} />
          </div>
        </>
      ) : null}

      {/* Step 2 */}
      {currentStep === 2 ? (
        <>
          <h1 className="subtitle">{t("permissions_breaks")}</h1>

          <div className="options_container permissions">
            <span className="options_container_title">{t("permissions")}</span>
            <PermissionsSection formProps={formProps} />
          </div>

          <div className="options_container breaks">
            <span className="options_container_title">{t("breaks")}</span>
            <BreaksSection formProps={formProps} />
          </div>

          <div className="options_container">
            <span className="options_container_title">
              {t("custom leaves")}
            </span>
            <CustomLeavesSection formProps={formProps} />
          </div>

          <div className="options_container">
            <span className="options_container_title">
              {t("Ability to submit requests")}
            </span>
            <AbilitySubmitRequestsSection formProps={formProps} />
          </div>
        </>
      ) : null}

      <FooterSection
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        setIsSubmitting={setIsSubmitting}
        setIsModalOpen={setIsModalOpen}
        fetchLeavesBreaks={fetchLeavesBreaks}
        isSetupWizard={isSetupWizard}
      />
    </div>
  );
};

export default LeavesBreaksForm;
