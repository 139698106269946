import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "react-dropzone-uploader/dist/styles.css";
import {
  closeEditEmployeeAction,
  upsertEmployeeAction,
  resetEmployeeFormAction,
  updateObjectMultiSelectAction,
  showEmployeeLocationModalAction,
  setSelectedAttProfileAction,
  createNewEmployeeSalaryAction,
  onInputResetWithValueAction,
} from "../../Store/Actions";
import Select from "react-select";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
// import AddLocationModal from "../../Components/AddLocationModal";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import SalaryConfigForm from "../../Components/SalaryConfigForm";
import moment from "moment";
// import "../../Assets/sass/phone-input.scss";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import {
  OfficeInfoSection,
  PersonalInfoSection,
  WorkRemotelySection,
  WorkScheduleSection,
} from "./EmployeeFormSections";
import Constants, { EgyptId } from "../../Constants";
import HelperFns, {
  serializeUpsertDayOffException,
  serializeUpsertHolidayException,
  normalizeSalaryConfigInput,
} from "../../Helpers/HelperFns";
import CountryFieldsSection from "./EmployeeFormSections/CountryFieldsSection";
import UpdateOrCreateUserPayment from "../../Components/UpdateOrCreateUserPayment";

const formNameValidation = `employeeFormValidation`;
const formServerValidation = "employeeServerValidation";
const reducer = "super";

const EmployeeForm = (props) => {
  const { t } = useTranslation();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [selectedAttProfileState, setSelectedAttProfileState] = useState({});
  const formName = props.formName;
  const FormProps = {
    formName,
    formNameValidation,
    formSubmitting,
    formServerValidation,
    reducer,
  };

  // Redux Hooks
  const dispatch = useDispatch();
  const editEmployeeFormData = useSelector(
    (state) => state.super.editEmployeeFormData
  );

  const isOpen = useSelector((state) => state.super.editEmployeeForm);
  const employeeForm = useSelector((state) => state.super?.[formName]);

  const employeeFormValidation = useSelector(
    (state) => state.super?.[formNameValidation]
  );
  const empServerValidation = useSelector(
    (state) => state.super?.[formServerValidation]
  );
  const salaryConfigForm = useSelector(
    (state) => state.super?.salaryConfigForm
  );
  const salaryConfigFormValidation = useSelector(
    (state) => state.super?.salaryConfigFormValidation
  );

  const paymentMethodForm = useSelector(
    (state) => state.super?.paymentMethodForm
  );

  const paymentMethodFormValidation = useSelector(
    (state) => state.super?.paymentMethodFormValidation
  );

  const user = useSelector((state) => state?.auth?.userProfile);
  const requestStatus = useSelector((state) => state.super.employeeFormStatus);

  useEffect(() => {
    if (editEmployeeFormData?.attendance_profiles) {
      const selectedAttProfile =
        editEmployeeFormData?.attendance_profiles?.filter(
          (attProfile) => attProfile.id == employeeForm.att_profile_id
        );

      if (selectedAttProfile) {
        setSelectedAttProfileState(...selectedAttProfile);
        // To Use It At Create New Employee
        dispatch(setSelectedAttProfileAction(...selectedAttProfile));
      }
    }
  }, [employeeForm.att_profile_id, editEmployeeFormData?.attendance_profiles]);

  // Form Clean Up
  useEffect(() => {
    return () => {
      setFormSubmitting(false);
      dispatch(resetEmployeeFormAction());
    };
  }, []);

  // Set manager depend on the department
  useEffect(() => {
    if (employeeForm?.department_id && !employeeForm?.manager_id) {
      const department = editEmployeeFormData?.departments?.find(
        (department) => department.id === employeeForm?.department_id
      );
      department?.manager &&
        dispatch(
          onInputResetWithValueAction(
            formName,
            "manager_id",
            department?.manager.id
          )
        );
    }
  }, [employeeForm?.department_id]);

  // Start of handling each attendnace profile if it has annual leaves and sick leaves and emergency leaves or not
  const [attProfileHasAnnualLeaves, setAttProfileHasAnnualLeaves] = useState(0);
  const [attProfileHasSickLeave, setAttProfileSickLeaves] = useState(0);
  const [attProfileHasEmergencyLeave, setAttProfileEmergencyLeaves] =
    useState(0);

  const handleAttProfileSelectionUpdate = () => {
    const selectedSettings = editEmployeeFormData?.attendance_profiles?.find(
      (attProfile) => attProfile.id == employeeForm.att_profile_id
    )?.permission_leaves_break_setting;

    let selectedAttProfileAnnualLeaves =
      selectedSettings?.annualLeaveSetting?.allow_annual_leaves;

    let selectedAttProfileSickLeaves =
      selectedSettings?.sickLeaveSetting?.sick_leaves;

    let selectedAttProfileEmergencyLeaves =
      selectedSettings?.annualLeaveSetting?.allowed_emergency_leaves;

    setAttProfileHasAnnualLeaves(selectedAttProfileAnnualLeaves);
    setAttProfileSickLeaves(selectedAttProfileSickLeaves);
    setAttProfileEmergencyLeaves(selectedAttProfileEmergencyLeaves);
  };

  const useDidAttProfileSickLeaveUpdate = useDidUpdateEffect(
    handleAttProfileSelectionUpdate,
    [employeeForm.att_profile_id]
  );
  // End of handling each attendnace profile if it has sick leaves or not

  // getting the selected leave and break profile in case of shift based
  const selectedLeaveAndBreakProfile = editEmployeeFormData?.leavesBreaks?.find(
    (profile) => profile?.id == employeeForm?.permissionLeavesBreakSettingId
  );
  // check if selected leaves and breaks profile allow annual leaves
  const leaveAndBreakProfileHasAnnualLeaves =
    selectedLeaveAndBreakProfile?.annualLeaveSetting?.allow_annual_leaves;
  // check if selected leaves and breaks profile allow emergency leaves
  const leaveAndBreakProfileHasEmergencyLeaves =
    selectedLeaveAndBreakProfile?.annualLeaveSetting?.allowed_emergency_leaves;
  // check if selected leaves and breaks profile allow sick leaves
  const leaveAndBreakProfileHasSickLeaves =
    selectedLeaveAndBreakProfile?.sickLeaveSetting?.allowed_sick_leaves;

  // Form Clean Up
  useEffect(() => {
    return () => {
      setFormSubmitting(false);
      dispatch(resetEmployeeFormAction());
    };
  }, []);

  const handleSubmitForm = () => {
    setFormSubmitting(true);

    if (
      !employeeFormValidation.length &&
      !salaryConfigFormValidation.length &&
      (!paymentMethodFormValidation.length ||
        !paymentMethodForm?.has_payment_method)
    ) {
      let workday_locations = [];

      Constants.Days?.forEach((day) => {
        if (employeeForm?.[`${day}_sign_in_location`]?.length) {
          workday_locations.push({
            workday: day,
            locations_id: employeeForm?.[`${day}_sign_in_location`]?.map(
              (location) => location?.value
            ),
            type: "sign_in",
          });
        }

        if (employeeForm?.[`${day}_sign_out_location`]?.length) {
          workday_locations.push({
            workday: day,
            locations_id: employeeForm?.[`${day}_sign_out_location`]?.map(
              (location) => location?.value
            ),
            type: "sign_out",
          });
        }
      });

      const {
        Friday_sign_in_location,
        Friday_sign_out_location,
        Saturday_sign_in_location,
        Saturday_sign_out_location,
        Sunday_sign_in_location,
        Sunday_sign_out_location,
        Monday_sign_in_location,
        Monday_sign_out_location,
        Tuesday_sign_in_location,
        Tuesday_sign_out_location,
        Wednesday_sign_in_location,
        Wednesday_sign_out_location,
        Thursday_sign_in_location,
        Thursday_sign_out_location,
        annual_leaves,
        sick_leaves,
        emergency_leaves,
        value,
        type,
        optionsName,
        face_path,
        workGroupId,
        workTeamId,
        att_profile_id,
        permissionLeavesBreakSettingId,
        workTeamStartDate,
        starts_at,
        has_credentials,
        email,
        phone,
        user_image,
        attendanceType,
        // work remotely keys start
        can_work_home,
        employee_can_request,
        max_homeDays_per_week,
        flexible_home,
        home_days,
        can_ex_days,
        first_day_of_the_week,
        // work remotely keys end
        attendance_type_configuration_start_date = null,

        allow_half_days,

        // day off exception
        allow_work_on_day_off,
        day_off_exception_id,
        normal_work_timing_id,
        first_half_work_timing_id,
        second_half_work_timing_id,
        apply_compensation,
        weight,
        treat_as_normal,
        allow_permission,
        allow_overtime,
        offices_ids,
        compensation_type,
        payment_factor_id,
        compensation_quantity,
        workplace_setting,
        additional_work_places,
        workplace_setting_signout,
        additional_work_places_signout,
        workplace_setting_identical,

        check_in_form_id,
        check_out_form_id,
        allow_customize_check_ins,
        date_of_birth_year,
        date_of_birth_month,
        date_of_birth_day,
        metres,
        check_in_settings_as_work_timing_sign_in_settings,
        allowMultipleCheckIns,
        check_in_setting_id,
        reuiqre_facial_recognition,
        employee_can_check_in_from,
        check_in_specific_work_laces,
        apply_same_setting_when_checking_out,
        employee_can_check_out_from,
        check_out_specific_work_laces,
        total_income_amount,
        total_tax_amount,

        // holiday exception
        allow_work_on_holiday,
        same_config,
        holiday_exception_id,
        holiday_normal_work_timing_id,
        holiday_first_half_work_timing_id,
        holiday_second_half_work_timing_id,
        holiday_weight,
        holiday_treat_as_normal,
        holiday_workplace_setting,
        holiday_workplace_setting_identical,
        holiday_additional_work_places,
        holiday_workplace_setting_signout,
        holiday_additional_work_places_signout,
        holiday_allow_overtime,
        holiday_allow_permission,
        holiday_payment_factor_id,
        holiday_compensation_type,
        holiday_compensation_quantity,
        holiday_apply_compensation,
        holiday_allow_half_days,

        ...restEmployeeForm
      } = employeeForm;

      let dateOfBirth =
        date_of_birth_year || date_of_birth_month || date_of_birth_day
          ? `${date_of_birth_year}-${date_of_birth_month}-${date_of_birth_day}`
          : null;

      if (props.isFirst) {
        let user_salary_config_input = {
          ...normalizeSalaryConfigInput(
            null,
            salaryConfigForm,
            moment(employeeForm?.starts_at).format("YYYY-MM-DD")
          ),
        };

        let userInput = {
          ...restEmployeeForm,
          workday_locations,
          country_short_name: restEmployeeForm?.phone_country_code,
          starts_at: moment(starts_at).format("YYYY-MM-DD"),
          has_credentials,
          company_id: user.company.id,
          email: has_credentials ? email : null,
          phone: has_credentials ? phone : null,
          // max_homeDays_per_week: +max_homeDays_per_week, // work remotely
          probation_period:
            value === "" || type === "" ? null : { value, type },
          beginning_balance: {
            annual_leaves:
              (attendanceType === Constants.attendanceTypes.OFFICE_BASED &&
                !!attProfileHasAnnualLeaves) ||
              (attendanceType === "shifts" &&
                !!leaveAndBreakProfileHasAnnualLeaves)
                ? annual_leaves
                : null,
            emergency_leaves:
              (attendanceType === Constants.attendanceTypes.OFFICE_BASED &&
                !!attProfileHasEmergencyLeave) ||
              (attendanceType === "shifts" &&
                !!leaveAndBreakProfileHasEmergencyLeaves)
                ? emergency_leaves
                : "0",
            sick_leaves:
              (attendanceType === "office based" && !!attProfileHasSickLeave) ||
              (attendanceType === "shifts" &&
                !!leaveAndBreakProfileHasSickLeaves)
                ? sick_leaves
                : null,
          },
          user_image: face_path ? undefined : user_image,
          attendanceType: {
            type: attendanceType,
            start: moment(starts_at)?.format("YYYY-MM-DD"),
            workRemotelySettings: {
              canWorkRemote: can_work_home ? true : false,
              employee_can_request,
              maxRemoteDaysPerWeek: +max_homeDays_per_week,
              flexibleRemote:
                attendanceType === Constants.attendanceTypes.OFFICE_BASED
                  ? !!flexible_home
                  : !!can_work_home,
              canExchangeDays:
                attendanceType === Constants.attendanceTypes.OFFICE_BASED
                  ? !!can_ex_days
                  : false,
              remoteDays:
                attendanceType === Constants.attendanceTypes.OFFICE_BASED
                  ? home_days
                  : [],
            },
            ...(attendanceType === Constants.attendanceTypes.SHIFT_BASED
              ? {
                  weekStartDay: +first_day_of_the_week,
                  permissionLeavesBreakSettingId,
                  workGroupId,
                  workTeamId,
                  joiningFrom: workTeamStartDate
                    ? moment(workTeamStartDate).format("YYYY-MM-DD")
                    : null,

                  // day off exception
                  ...serializeUpsertDayOffException({
                    allow_work_on_day_off,
                    day_off_exception_id,
                    normal_work_timing_id,
                    first_half_work_timing_id,
                    second_half_work_timing_id,
                    apply_compensation,
                    weight,
                    treat_as_normal,
                    allow_permission,
                    allow_overtime,
                    offices_ids,
                    compensation_type,
                    payment_factor_id,
                    compensation_quantity,
                    workplace_setting,
                    additional_work_places,
                    workplace_setting_signout,
                    additional_work_places_signout,
                    workplace_setting_identical,
                  }),

                  //check in out settings
                  allowMultipleCheckIns: !!allowMultipleCheckIns,
                  checkInFormId: check_in_form_id,
                  checkOutFormId: check_out_form_id,
                  check_in_distance_setting: !!metres ? parseFloat(metres) : 0,
                  checkin_setting:
                    check_in_settings_as_work_timing_sign_in_settings ||
                    !allowMultipleCheckIns
                      ? null
                      : {
                          id: check_in_setting_id,
                          face_recognition_check_in:
                            !!reuiqre_facial_recognition,
                          face_recognition_check_out:
                            !!reuiqre_facial_recognition,

                          workplace_setting: employee_can_check_in_from,

                          additional_work_places:
                            employee_can_check_in_from == "DEFAULT"
                              ? check_in_specific_work_laces
                              : [],

                          workplace_setting_identical:
                            apply_same_setting_when_checking_out,

                          workplace_setting_signout:
                            apply_same_setting_when_checking_out
                              ? employee_can_check_in_from
                              : employee_can_check_out_from,

                          additional_work_places_signout:
                            apply_same_setting_when_checking_out
                              ? employee_can_check_in_from == "DEFAULT"
                                ? check_in_specific_work_laces
                                : []
                              : employee_can_check_out_from == "DEFAULT"
                              ? check_out_specific_work_laces
                              : [],
                        },
                  attendanceProfileId: null,

                  // holiday exception
                  ...serializeUpsertHolidayException({
                    allow_work_on_day_off,
                    allow_work_on_holiday,
                    same_config,
                    holiday_exception_id,
                    holiday_normal_work_timing_id,
                    holiday_first_half_work_timing_id,
                    holiday_second_half_work_timing_id,
                    holiday_weight,
                    holiday_treat_as_normal,
                    holiday_workplace_setting,
                    holiday_workplace_setting_identical,
                    holiday_additional_work_places,
                    holiday_workplace_setting_signout,
                    holiday_additional_work_places_signout,
                    holiday_allow_overtime,
                    holiday_allow_permission,
                    holiday_payment_factor_id,
                    holiday_compensation_type,
                    holiday_compensation_quantity,
                    holiday_apply_compensation,
                    holiday_allow_half_days,
                  }),
                }
              : {
                  attendanceProfileId: att_profile_id,
                  allowMultipleCheckIns: false,
                }),
          },

          date_of_birth: dateOfBirth,

          previousIncome: {
            total_income_amount: !!total_income_amount
              ? parseFloat(total_income_amount)
              : null,
            total_tax_amount: !!total_tax_amount
              ? parseFloat(total_tax_amount)
              : null,
          },
        };
        dispatch(
          createNewEmployeeSalaryAction(
            user_salary_config_input,
            userInput,
            Boolean(paymentMethodForm?.payments?.[0]?.payment_method)
              ? HelperFns.serializePaymentMethodInput({
                  ...paymentMethodForm?.payments[0],
                  user_id: employeeForm?.id,
                  has_payment_method:  Boolean(paymentMethodForm?.payments?.[0]?.payment_method)
                })
              : undefined
          )
        );
      } else {
        dispatch(
          upsertEmployeeAction({
            ...restEmployeeForm,
            starts_at: moment(starts_at)?.format("YYYY-MM-DD"),
            has_credentials,
            user_image,
            face_path,
            att_profile_id,
            value,
            type,
            company_id: user.company.id,
            workday_locations,
            email: has_credentials ? email : null,
            phone: has_credentials ? phone : null,
            force_update_att_profile: true,
            attendanceType: {
              attendanceProfileId: undefined,
              type: "",
              workGroupId: undefined,
              workTeamId: undefined,
              permissionLeavesBreakSettingId: undefined,
              allowMultipleCheckIns: undefined,
              start: undefined,
              weekStartDay: undefined,
              workRemotelySettings: undefined,
            },

            date_of_birth: dateOfBirth,
            userPaymentInput: HelperFns.serializePaymentMethodInput({
              ...paymentMethodForm?.payments[0],
              user_id: employeeForm?.id,
              has_payment_method:  Boolean(paymentMethodForm?.payments?.[0]?.payment_method)
            }),
            includePaymentMethod: Boolean(
              paymentMethodForm?.payments?.[0]?.payment_method
            ),
            userPaymentId: paymentMethodForm?.payments?.[0]?.user_payment_id,
            deletePayment: Boolean(
              !Boolean(paymentMethodForm?.payments?.[0]?.payment_method) && paymentMethodForm?.payments?.[0]?.user_payment_id
            ),
          })
        );
      }
    }
  };

  const handlechange = (value, e) => {
    dispatch(
      updateObjectMultiSelectAction({
        data: value ?? [],
        formName,
        name: e.name,
      })
    );
  };

  // set toggle function for the add location modal
  const handleAddOfficeLocation = (optionsName) => {
    dispatch(
      showEmployeeLocationModalAction({
        ref: "officeLocationModalActions",
        formName: "locationForm",
        optionsName,
      })
    );
  };

  const handleEditOfficeLocation = (data, optionsName) => {
    dispatch(
      showEmployeeLocationModalAction({
        data,
        ref: "officeLocationModalActions",
        formName: "locationForm",
        optionsName,
      })
    );
  };

  const CustomLocationsSelect = ({ type }) =>
    Constants.Days?.map((day, i) => (
      <div
        key={`${day}_${type}_location`}
        className="d-flex align-items-center"
      >
        <div className="w-110px">{t(day)}</div>
        <div className="d-flex align-items-center w-100 ">
          <div className="w-100 my-2">
            <button
              className="link-btn mb-1"
              onClick={() =>
                handlechange(editEmployeeFormData.locations, {
                  name: `${day}_${type}_location`,
                })
              }
            >
              {t("select all")}
            </button>
            <Select
              key={`${day}_${type}_location-${i}`}
              name={`${day}_${type}_location`}
              placeholder={t("select location")}
              options={editEmployeeFormData.locations}
              onChange={handlechange}
              isMulti={true}
              classNamePrefix="custom_select_style"
              className="select-def-input-container b-select-style"
              value={employeeForm?.[`${day}_${type}_location`]}
            />
          </div>
          {/* <HasPrivileges
            reqireMain={[Privilages.ADD_EDIT_DELETE_LOCATIONS]}
            allowBP
            avalibleOnExpire={false}
          >
            <div className="px-0 d-flex justify-content-end align-items-center office_form_wrapper">
              <button
                className="add-btn float-right btn ml-3"
                onClick={() =>
                  handleAddOfficeLocation(`${day}_${type}_location`)
                }
              >
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
          </HasPrivileges> */}
        </div>
      </div>
    ));

  const employeeFormSelectedProfile =
    editEmployeeFormData?.attendance_profiles?.find(
      (attProfile) => attProfile.id == employeeForm.att_profile_id
    );

  const selectedOffice = editEmployeeFormData?.offices?.find(
    (office) => office?.id == employeeForm?.office_id
  );

  return (
    <div className="content employee-form">
      {/* Start of Add Location Modal */}
      {/* <AddLocationModal formName="editEmployeeFormData" /> */}
      {/* End of Add Location Modal */}

      <div className="page-title">
        {t(employeeForm?.id ? "edit employee" : "new employee")}
      </div>

      {empServerValidation?.["input.user_input.id"] ? (
        <div
          className="alert alert-danger fit-width mx-5 py-2 my-0 "
          role="alert"
        >
          {empServerValidation?.["input.user_input.id"] ?? ""}
        </div>
      ) : null}

      <PersonalInfoSection
        FormProps={FormProps}
        formSubmitting={formSubmitting}
      />

      <OfficeInfoSection FormProps={FormProps} />

      {!employeeForm?.id ? (
        <>
          <WorkScheduleSection
            isEditable={!employeeForm?.id}
            FormProps={FormProps}
            attProfileHasAnnualLeaves={attProfileHasAnnualLeaves}
            attProfileHasSickLeave={attProfileHasSickLeave}
            attProfileHasEmergencyLeave={attProfileHasEmergencyLeave}
            leaveAndBreakProfileHasAnnualLeaves={
              leaveAndBreakProfileHasAnnualLeaves
            }
            leaveAndBreakProfileHasEmergencyLeaves={
              leaveAndBreakProfileHasEmergencyLeaves
            }
            leaveAndBreakProfileHasSickLeaves={
              leaveAndBreakProfileHasSickLeaves
            }
          />
          <WorkRemotelySection
            FormProps={FormProps}
            isEmployeeForm={true}
            selectedAttProfile={employeeFormSelectedProfile}
          />
        </>
      ) : null}

      {Boolean(
        selectedAttProfileState?.activeWorkTiming
          ?.allow_locations_custom_override ||
          (Boolean(
            +selectedAttProfileState?.activeWorkTiming
              ?.sign_out_locations_custom_override
          ) &&
            !Boolean(+selectedAttProfileState?.activeWorkTiming?.same_policies))
      ) && (
        <div className="boxContainer">
          <h4 className="sub-title-style mb-1">
            {t("custom offices/work places")}
          </h4>
          <hr />

          <div className="mt-4 mb-2 row custom-locations-style">
            {selectedAttProfileState?.workdays &&
              Boolean(
                selectedAttProfileState?.activeWorkTiming
                  ?.allow_locations_custom_override
              ) && (
                <div className="col-12">
                  <h5 className="text-primary h5">{t("sign in")}</h5>
                  <CustomLocationsSelect type="sign_in" />
                </div>
              )}

            {selectedAttProfileState?.workdays &&
              Boolean(
                +selectedAttProfileState?.activeWorkTiming
                  ?.sign_out_locations_custom_override
              ) &&
              !Boolean(
                +selectedAttProfileState?.activeWorkTiming?.same_policies
              ) && (
                <div className="col-12">
                  <h5 className="text-primary h5 mt-4">{t("sign out")}</h5>
                  <CustomLocationsSelect type="sign_out" />
                </div>
              )}
          </div>
        </div>
      )}

      {selectedOffice?.country?.id == EgyptId ? (
        <CountryFieldsSection formProps={FormProps} />
      ) : null}

      {/* (Start) Error Message */}
      {requestStatus.errorMsg && formSubmitting && (
        <div className="warnig-msg-style boxContainer">
          {requestStatus.errorMsg}
        </div>
      )}
      {/* (End) Error Message */}
      <HasPrivileges
        reqireMain={[Privilages.ADD_EMPLOYEE_SALARY_CONFIGURATION]}
        allowBP
      >
        {props.isFirst && (
          <SalaryConfigForm
            formName={"salaryConfigForm"}
            isFirst={true}
            formSubmitting={formSubmitting}
            formServerValidation={formServerValidation}
            countryId={selectedOffice?.country?.id}
            userId={employeeForm?.office_id}
            office={selectedOffice}
          />
        )}
      </HasPrivileges>

      <HasPrivileges
        reqireMain={[Privilages.ADD_EMPLOYEE_SALARY_CONFIGURATION]}
        allowBP
      >
        <UpdateOrCreateUserPayment
          formName={"paymentMethodForm"}
          isFirst={props.isFirst}
          formSubmitting={formSubmitting}
          formServerValidation={formServerValidation}
          countryId={selectedOffice?.country?.id}
          userId={employeeForm?.office_id}
          office={selectedOffice}
        />
      </HasPrivileges>

      <div className="mt-1 mb-1 text-right">
        {isOpen && (
          <button
            color="danger"
            onClick={() => dispatch(closeEditEmployeeAction())}
            className="btn btn-danger submit_btn_style mr-3"
          >
            {t("Cancel")}
          </button>
        )}
        <button
          disabled={requestStatus.isLoading}
          type="submit"
          onClick={handleSubmitForm}
          className="btn btn-primary submit_btn_style"
        >
          {requestStatus.isLoading ? (
            <Spinner style={{ width: "1rem", height: "1rem", color: "#fff" }} />
          ) : (
            t("save")
          )}
        </button>
      </div>
    </div>
  );
};

export default EmployeeForm;
