import React from "react";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Spinner } from "reactstrap";
import { connect } from "react-redux";

const EmailNotificationsList = (props) => {
  const { t } = useTranslation();

  // Start of Ant Switch style options
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 34,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(18px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(18px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#27b40c",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "#e6e6e6",
      boxSizing: "border-box",
    },
  }));
  // End of Ant Switch style options

  return (
    <>
      <div className="col-12 d-flex my-2 notification-drawer-content-card">
        <div className="col-6 col-lg-6 px-0">
          <p className="mb-0">{t(props?.emailNotificationsInfo?.name)}</p>
        </div>

        <div className="col-6 col-lg-6 p-0 d-flex justify-content-start switchs_wrapper_style">
          <div className="col-6">
            {props?.emailNotificationsInfo?.type == "email" ||
            props?.emailNotificationsInfo?.type == "both" ? (
              <>
                {props?.emailSwitchLoader.includes(
                  props?.emailNotificationsInfo?.id
                ) ? (
                  <div className="spinner_wrapper_style">
                    <Spinner
                      style={{
                        width: "1rem",
                        height: "1rem",
                        color: "#27b40c",
                      }}
                    />
                  </div>
                ) : (
                  <AntSwitch
                    name="email"
                    value={props?.emailNotificationsInfo?.id}
                    onChange={props?.handleEmailSettingsSwitchChange}
                    checked={props?.emailNotificationsInfo?.email_status}
                  />
                )}
              </>
            ) : (
              <AntSwitch
                name="email"
                value={props?.emailNotificationsInfo?.id}
                disabled
              />
            )}
          </div>

          <div className=" col-6">
            {props?.emailNotificationsInfo?.type == "phone" ||
            props?.emailNotificationsInfo?.type == "both" ? (
              <>
                {props?.mobileSwitchLoader.includes(
                  props?.emailNotificationsInfo?.id
                ) ? (
                  <div className="spinner_wrapper_style">
                    <Spinner
                      style={{
                        width: "1rem",
                        height: "1rem",
                        color: "#27b40c",
                      }}
                    />
                  </div>
                ) : (
                  <AntSwitch
                    name="phone"
                    value={props?.emailNotificationsInfo?.id}
                    onChange={props?.handleEmailSettingsSwitchChange}
                    checked={props?.emailNotificationsInfo?.phone_status}
                  />
                )}
              </>
            ) : (
              <AntSwitch
                name="phone"
                value={props?.emailNotificationsInfo?.id}
                disabled
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    emailSwitchLoader: state.super.emailSwitchLoader,
    mobileSwitchLoader: state.super.mobileSwitchLoader,
  };
};

export default connect(mapStateToProps)(EmailNotificationsList);
