import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { CheckboxBooleanForm, DateTimePickerForm, BSelect } from "form-builder";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import { faPlus, faUndo } from "@fortawesome/free-solid-svg-icons";

import {
	onInputResetAction,
	showWorkTimingModalAction,
	childInputChange,
	resetOldSignInConfig,
	removeSignInConfig,
	viewWorkTimeAction,
	childCheckboxChange,
	updateValueAction,
} from "../../Store/Actions";
import Privilages from "../../Constants/Privilages";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { Tooltip } from "@mui/material";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import { components } from "react-select/dist/react-select.cjs.prod";
import HelperFns, { showToast } from "../../Helpers/HelperFns";
import { useQuery } from "@apollo/client";
import { GET_WORKING_TIMMING_OPTIONS_QUERY } from "../../Graphql/query";
import _ from "lodash";
import { name } from "file-loader";
import { useParams } from "react-router-dom";

const childName = "attendance_profile_work_timing_input";
const NewAttWorkTimeSection = ({ showRemoveButton, ...props }) => {
	const { t } = useTranslation();

	const [normalWorkTimingList, setNormalWorkTimingList] = useState([]);
	const [halfWorkTimingList, setHalfWorkTimingList] = useState([]);

	const workTimingsLimit = props.authPlanLimits?.filter((feature) => feature?.id === "5")[0]?.pivot?.limits ?? Infinity;

	const { formProps, index = 0 } = props;

	const { attId } = useParams();

	const { loading: workTimmingLoading } = useQuery(GET_WORKING_TIMMING_OPTIONS_QUERY, {
		variables: {
			work_timming_date: attId
				? props.data?.[formProps.formName]?.[childName][index]?.start
				: moment().format("YYYY-MM-DD"),
		},
		onCompleted: (res) => {
			setNormalWorkTimingList(res?.work_timings_menu);
			setHalfWorkTimingList(res?.half_work_timings_menu);

			if (
				!res?.work_timings_menu?.find(
					(wt) => wt.id == props.data[formProps.formName]?.[childName][index]?.work_timing_id
				) &&
				!previously_selected_worktimngs?.normal_work_timings?.find(
					(wt) => wt.id == props.data[formProps.formName]?.[childName][index]?.first_half_work_timing_id
				)
			) {
				handleSelectInputChange(null, { name: "work_timing_id" });
			}

			if (
				!res?.work_timings_menu?.find(
					(wt) => wt.id == props.data[formProps.formName]?.[childName][index]?.first_half_work_timing_id
				) &&
				!previously_selected_worktimngs?.half_work_timings?.find(
					(wt) => wt.id == props.data[formProps.formName]?.[childName][index]?.first_half_work_timing_id
				)
			) {
				handleSelectInputChange(null, {
					name: "first_half_work_timing_id",
				});
			}

			if (
				!res?.work_timings_menu?.find(
					(wt) => wt.id == props.data[formProps.formName]?.[childName][index]?.second_half_work_timing_id
				) &&
				!previously_selected_worktimngs?.half_work_timings?.find(
					(wt) => wt.id == props.data[formProps.formName]?.[childName][index]?.first_half_work_timing_id
				)
			) {
				handleSelectInputChange(null, {
					name: "second_half_work_timing_id",
				});
			}
		},
		onError: (err) => {
			showToast("error", err?.graphQLErrors[0]?.extensions?.reason || err?.graphQLErrors[0]?.message || err?.message);
		},
	});

	const getWorkTimings = (workTimingList, actualWorkTiming = []) => {
		let returnOptions = [...workTimingList, ...actualWorkTiming];
		return _.uniqBy(returnOptions, "id");
	};

	// handle add normal work time
	const addNewWorkTime = () => {
		props.showWorkTimingModalAction("normal", formProps.formName, index, "normal");
	};

	// handle add New First Half WorkTime
	const addNewFirstHalfWorkTime = () => {
		props.showWorkTimingModalAction(
			"half",
			formProps.formName,
			index,
			"first",
			props.data[formProps.formName]?.[childName][index]?.work_timing_id
		);
	};

	// handle add New Second Half WorkTime
	const addNewSecondHalfWorkTime = () => {
		props.showWorkTimingModalAction(
			"half",
			formProps.formName,
			index,
			"second",
			props.data[formProps.formName]?.[childName][index]?.work_timing_id
		);
	};

	const handleChildDateInputChange = (value) => {
		if (moment(value).isValid()) {
			props.childInputChange(formProps.formName, childName, "start", index, moment(value).format("yyyy-MM-DD"));
		} else {
			props.childInputChange(formProps.formName, childName, "start", index, null);
		}
	};

	const handleSelectInputChange = (val, event) => {
		props.childInputChange(formProps.formName, childName, event.name, index, val ? val?.id : "");
	};

	// handle allow empployee request half day checkbox change
	const handleAttSignInCheckboxChange = (event) => {
		props.childCheckboxChange(formProps.formName, childName, event, index);
	};

	const resetSignInConfig = () => {
		let id = props.data[formProps.formName][childName][index]?.id;
		props.resetOldSignInConfig(formProps.formName, id);
		props.handleEndEdit(id);
	};

	const removeSignInConfig = () => {
		props.removeSignInConfig(formProps.formName, index);
	};

	// to handle preview work timing modal
	const handleViewWorkTime = (workTimingId) => {
		props.viewWorkTimeAction(workTimingId);
	};

	const Option = ({ children, ...props }) => {
		let { sign_in_start_time, sign_in_end_time, work_hours, sign_in_req } = props.data;
		return (
			<components.Option {...props}>
				<div>
					<div>{children?.toString()}</div>
					<span className={`work-timing-time-range-style ${props.isSelected ? "text-white" : ""}`}>
						{!sign_in_req
							? t("Don't Need to Sign In At The Start Of Each Work Day")
							: `Sign in range from ${HelperFns.formatWorkTiming(
									sign_in_start_time,
									"time_range"
							  )} to ${HelperFns.formatWorkTiming(
									sign_in_end_time,
									"time_range"
							  )} - work hours ${HelperFns.formatWorkTiming(work_hours, "hours")}`}
					</span>
				</div>
			</components.Option>
		);
	};

	const Control = ({ children, ...props }) => {
		return (
			<components.Control {...props}>
				<PeopleOutlineIcon className="b-select-style_select_icon" />
				{children}
			</components.Control>
		);
	};

	const previously_selected_worktimngs = {
		normal_work_timings: [
			{
				name: props.data[formProps.formName]?.[childName][index]?.work_timing_name,
				id: props.data[formProps.formName]?.[childName][index]?.work_timing_id,
			},
		],
		half_work_timings: [
			{
				name: props.data[formProps.formName]?.[childName][index]?.first_half_work_timing_name,
				id: props.data[formProps.formName]?.[childName][index]?.first_half_work_timing_id,
			},
			{
				name: props.data[formProps.formName]?.[childName][index]?.second_half_work_timing_name,
				id: props.data[formProps.formName]?.[childName][index]?.second_half_work_timing_id,
			},
		],
	};

	const getValue = (selectedWorkTimming) => {
		if (!selectedWorkTimming) return null;
		return getWorkTimings([
			...normalWorkTimingList,
			...halfWorkTimingList,
			...previously_selected_worktimngs.half_work_timings,
			...previously_selected_worktimngs.normal_work_timings,
		])?.filter((opt) => opt?.id == selectedWorkTimming)[0];
	};

	const addedWorkTime = useSelector((state) => state?.super?.addedWorkTime);
	console.log(addedWorkTime);

	useEffect(() => {
		if (addedWorkTime) {
			if (addedWorkTime?.which_half == "first" || addedWorkTime?.which_half == "second") {
				setHalfWorkTimingList((prevState) => [...(prevState ?? []), addedWorkTime?.worktime]);
				handleSelectInputChange(addedWorkTime?.worktime, { name: `${addedWorkTime?.which_half}_half_work_timing_id` });
				return;
			}
			if (addedWorkTime?.which_half == "normal") {
				setNormalWorkTimingList((prevState) => [...(prevState ?? []), addedWorkTime?.worktime]);
				handleSelectInputChange(addedWorkTime?.worktime, { name: `work_timing_id` });
				return;
			}
		}
	}, [addedWorkTime]);

	return (
		<>
			<div className="row boxContainer align-items-baseline sign-in-config position-relative">
				{/*  Start of Normal work timing sections */}
				<div className="d-flex align-items-center w-100">
					{props.data[formProps.formName][childName]?.length == 1 ||
					"1970-01-01" == props.data[formProps.formName][childName][index]?.start ? null : (
						<div className="col-lg-6 pl-0 signin_start_container">
							<DateTimePickerForm
								{...formProps}
								datePickerContainer="w-100"
								label="Starting From"
								containerStyle="containerStyle"
								labelStyle="custom-label-style mb-2"
								name="start"
								validationName={`input.${props.validationInputName}.${index}.start`}
								onChange={handleChildDateInputChange}
								onSelect={handleChildDateInputChange}
								value={
									Boolean(props.data[formProps.formName][childName][index]?.start)
										? moment(props.data[formProps.formName][childName][index]?.start)
										: null
								}
							/>
						</div>
					)}

					<div className="col-lg-6 signin_work_timing_container position-relative">
						<div>
							<HasPrivileges
								reqireMain={[Privilages.ADD_EDIT_CLONE_WORK_TIMINGS]}
								allowBP
								avalibleOnExpire={false}
								altExpireChildren={
									<button className="btn add_new_btn_style" type="button" disabled>
										<FontAwesomeIcon icon={faPlus} />
									</button>
								}
							>
								<Tooltip
									disableFocusListener
									disableTouchListener
									title={
										props.workTimeOptions?.filter((workTime) => workTime?.work_timing_profile_type === "normal") >=
										workTimingsLimit
											? t("you have reached the maximum number of Work Timings")
											: ""
									}
								>
									<button
										className="btn add_new_btn_style"
										type="button"
										onClick={addNewWorkTime}
										disabled={
											props.workTimeOptions?.filter((workTime) => workTime?.work_timing_profile_type === "normal") >=
											workTimingsLimit
										}
									>
										<FontAwesomeIcon icon={faPlus} />
									</button>
								</Tooltip>
							</HasPrivileges>
						</div>

						<BSelect
							{...formProps}
							name="work_timing_id"
							validationName={`input.${props.validationInputName}.${index}.work_timing_id`}
							label={"Normal Work timing"}
							optDependType="equal"
							optDependKey="work_timing_profile_type"
							optDependValue="normal"
							keepDefaultStyle
							rootStyle="w-100"
							options={getWorkTimings(normalWorkTimingList, previously_selected_worktimngs.normal_work_timings)}
							// options={props.workTimeOptions}
							optionLabel="name"
							optionValue="id"
							components={{ Option, Control }}
							skipLocalization
							labelStyle="custom-label-style"
							containerStyle="containerStyle"
							inputContainerStyle="w-100"
							value={getValue(props.data[formProps.formName]?.[childName][index]?.work_timing_id)}
							onChange={(e, val) => handleSelectInputChange(e, val)}
							isDisabled={workTimmingLoading}
							isLoading={workTimmingLoading}
						/>

						{props.data[formProps.formName]?.[childName][index]?.work_timing_id ? (
							<HasPrivileges reqireMain={[Privilages.PREVIEW_WORK_TIMINGS]} allowBP>
								<VisibilityIcon
									className="work_timing_preview_icon_style"
									onClick={() => handleViewWorkTime(props.data[formProps.formName]?.[childName][index]?.work_timing_id)}
								/>
							</HasPrivileges>
						) : (
							""
						)}
					</div>
				</div>
				{/*  End of Normal work timing sections */}

				{/*  Start of half work timing sections */}
				<div className="col flex-fill row align-items-baseline">
					<div className="px-0 col-12">
						<CheckboxBooleanForm
							{...formProps}
							validationName={`input.${props.validationInputName}.${index}.allow_half_day`}
							options={["Allow Employees To Take Half Day"]}
							name="allow_half_day"
							type="checkbox"
							value={props.data[formProps.formName]?.[childName][index]?.allow_half_day}
							checked={props.data[formProps.formName]?.[childName][index]?.allow_half_day}
							optionLabelStyle={
								props.data[formProps.formName]?.[childName][index]?.allow_half_day
									? "lightActiveColor font-weight-bold"
									: "booleanInputLabelStyle font-weight-bold"
							}
							onChange={(e) => handleAttSignInCheckboxChange(e)}
						/>
					</div>

					{props.data[formProps.formName]?.[childName][index]?.allow_half_day ? (
						<div className="d-flex w-100 align-items-center ">
							{/* First half  */}
							<div className="col-lg-6 w-100 signin_half_container px-0">
								<div>
									<HasPrivileges
										reqireMain={[Privilages.ADD_EDIT_CLONE_WORK_TIMINGS]}
										allowBP
										avalibleOnExpire={false}
										altExpireChildren={
											<button className="btn add_new_btn_style ml-2 my-0 my-md-1" type="button" disabled>
												<FontAwesomeIcon icon={faPlus} />
											</button>
										}
									>
										<Tooltip
											disableFocusListener
											disableTouchListener
											title={
												props.workTimeOptions?.filter((workTime) => workTime?.work_timing_profile_type === "normal") >=
												workTimingsLimit
													? t("you have reached the maximum number of Work Timings")
													: ""
											}
										>
											<button
												className="btn add_new_btn_style ml-2 my-0 my-md-1"
												type="button"
												onClick={addNewFirstHalfWorkTime}
												disabled={
													props.workTimeOptions?.filter(
														(workTime) => workTime?.work_timing_profile_type === "normal"
													) >= workTimingsLimit
												}
											>
												<FontAwesomeIcon icon={faPlus} />
											</button>
										</Tooltip>
									</HasPrivileges>
								</div>

								<BSelect
									{...formProps}
									name="first_half_work_timing_id"
									validationName={`input.${props.validationInputName}.${index}.first_half_work_timing_id`}
									label={"First-Half Work timing"}
									optDependType="equal"
									optDependKey="work_timing_profile_type"
									optDependValue="half"
									keepDefaultStyle
									rootStyle="w-100"
									options={getWorkTimings(halfWorkTimingList, previously_selected_worktimngs.half_work_timings)}
									optionLabel="name"
									optionValue="id"
									labelStyle="custom-label-style"
									containerStyle="containerStyle"
									components={{ Option, Control }}
									skipLocalization
									inputContainerStyle="flex-grow-1 mx-0 w-100"
									value={getValue(props.data[formProps.formName]?.[childName][index]?.first_half_work_timing_id)}
									onChange={(e, val) => handleSelectInputChange(e, val)}
									isDisabled={workTimmingLoading}
									isLoading={workTimmingLoading}
									validateBy="textRequired"
								/>

								{props.data[formProps.formName]?.[childName][index]?.first_half_work_timing_id ? (
									<HasPrivileges reqireMain={[Privilages.PREVIEW_WORK_TIMINGS]} allowBP>
										<VisibilityIcon
											className="work_timing_preview_icon_style"
											onClick={() =>
												handleViewWorkTime(
													props.data[formProps.formName]?.[childName][index]?.first_half_work_timing_id
												)
											}
										/>
									</HasPrivileges>
								) : (
									""
								)}
							</div>

							{/*  Second Half */}
							<div className="col-lg-6 w-100 signin_half_container second_half_container">
								<div>
									<HasPrivileges
										reqireMain={[Privilages.ADD_EDIT_CLONE_WORK_TIMINGS]}
										allowBP
										avalibleOnExpire={false}
										altExpireChildren={
											<button className="btn add_new_btn_style second_half_btn" type="button" disabled>
												<FontAwesomeIcon icon={faPlus} />
											</button>
										}
									>
										<Tooltip
											disableFocusListener
											disableTouchListener
											title={
												props.workTimeOptions?.filter((workTime) => workTime?.work_timing_profile_type === "normal") >=
												workTimingsLimit
													? t("you have reached the maximum number of Work Timings")
													: ""
											}
										>
											<button
												className="btn add_new_btn_style ml-2 my-0 my-md-1 second_half_btn"
												type="button"
												onClick={addNewSecondHalfWorkTime}
												disabled={
													props.workTimeOptions?.filter(
														(workTime) => workTime?.work_timing_profile_type === "normal"
													) >= workTimingsLimit
												}
											>
												<FontAwesomeIcon icon={faPlus} />
											</button>
										</Tooltip>
									</HasPrivileges>
								</div>

								<BSelect
									{...formProps}
									name="second_half_work_timing_id"
									validationName={`input.${props.validationInputName}.${index}.second_half_work_timing_id`}
									label={"Second-Half Work timing"}
									optDependType="equal"
									optDependKey="work_timing_profile_type"
									optDependValue="half"
									keepDefaultStyle
									options={getWorkTimings(halfWorkTimingList, previously_selected_worktimngs.half_work_timings)}
									optionLabel="name"
									optionValue="id"
									labelStyle="custom-label-style"
									skipLocalization
									containerStyle="containerStyle"
									components={{ Option, Control }}
									inputContainerStyle="flex-grow-1 mr-0 w-100"
									value={getValue(props.data[formProps.formName]?.[childName][index]?.second_half_work_timing_id)}
									onChange={(e, val) => handleSelectInputChange(e, val)}
									isDisabled={workTimmingLoading}
									isLoading={workTimmingLoading}
									validateBy="textRequired"
								/>

								{props.data[formProps.formName]?.[childName][index]?.second_half_work_timing_id ? (
									<HasPrivileges reqireMain={[Privilages.PREVIEW_WORK_TIMINGS]} allowBP>
										<VisibilityIcon
											className="work_timing_preview_icon_style"
											onClick={() =>
												handleViewWorkTime(
													props.data[formProps.formName]?.[childName][index]?.second_half_work_timing_id
												)
											}
										/>
									</HasPrivileges>
								) : (
									""
								)}
							</div>
						</div>
					) : null}
				</div>
				{/*  End of half work timing sections */}

				{props.data[formProps.formName][childName][index]?.id ? (
					<div className="reset-btn-container">
						<button className="btn reset-btn-style" type="button" onClick={resetSignInConfig}>
							<FontAwesomeIcon icon={faUndo} />
						</button>
					</div>
				) : null}

				{!props.data[formProps.formName][childName][index]?.id && showRemoveButton ? (
					<div className="reset-btn-container">
						<CloseIcon className="cancel-btn-style" onClick={removeSignInConfig} />
					</div>
				) : null}
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		data: state.super,
		workTimeOptions: state.super.workTimeOptions,
		authPlanLimits: state.auth?.userProfile?.company?.activeSubscription?.plan?.features,
	};
};

export default connect(mapStateToProps, {
	onInputResetAction,
	showWorkTimingModalAction,
	childInputChange,
	resetOldSignInConfig,
	removeSignInConfig,
	viewWorkTimeAction,
	childCheckboxChange,
})(NewAttWorkTimeSection);
