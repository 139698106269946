import React, { useState } from "react";
import { connect } from "react-redux";

import { useLocation } from "react-router-dom";

import { onInputResetAction, viewWorkTimeAction } from "../../Store/Actions";
import { useTranslation } from "react-i18next";

import EditIcon from "@mui/icons-material/Edit";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HelperFns from "../../Helpers/HelperFns";

const AttWorkTimeSectionPreview = (props) => {
  const { t } = useTranslation();

  // handle view normal work timing
  const handleViewNormalWorkTime = () => {
    props.viewWorkTimeAction(props?.work_timing_id);
  };

  // handle view first half work timing
  const handleViewFirstHalfWorkTime = () => {
    props.viewWorkTimeAction(props?.first_half_work_timing_id);
  };

  // handle view second half work timing
  const handleViewSecondHalfWorkTime = () => {
    props.viewWorkTimeAction(props?.second_half_work_timing_id);
  };

  return (
    <>
      <div className="row boxContainer align-items-center sign-in-config">
        <div className="col-lg-3 sign-in-out-time">
          {!("1970-01-01" == props?.start) ? (
            <>
              <span className="h6 sign-in-out-time_start">{props?.start}</span>
              <span className="mx-3 h6">--</span>
            </>
          ) : (
            <span className="mr-3 h6">{t("ended on")}</span>
          )}

          <span className="h6 sign-in-out-time-end">
            {props?.end ?? "-----"}
          </span>
        </div>

        <div
          className={
            props?.first_half_work_timing_name ||
            props?.second_half_work_timing_name
              ? "col-sm-12 col-lg-3 work_timing"
              : "work_timing"
          }
        >
          <label className="h6 text-muted work_timing_title">
            {t("Normal Work timing")}
          </label>

          <div onClick={handleViewNormalWorkTime}>
            <span
              className="h6 work_timing_name cursor-pointer"
              dir={
                HelperFns.isStringRTL(props?.normal_work_timing_name)
                  ? "rtl"
                  : "ltr"
              }
            >
              {props?.normal_work_timing_name}
            </span>
          </div>
        </div>

        {props?.first_half_work_timing_name ? (
          <div className="col-sm-12 col-lg-3 work_timing">
            <label className="h6 text-muted work_timing_title">
              {t("First-Half Work timing")}
            </label>

            <div className="d-flex" onClick={handleViewFirstHalfWorkTime}>
              <span
                className="h6 work_timing_name cursor-pointer"
                dir={
                  HelperFns.isStringRTL(props?.first_half_work_timing_name)
                    ? "rtl"
                    : "ltr"
                }
              >
                {props?.first_half_work_timing_name}
              </span>
            </div>
          </div>
        ) : null}

        {props?.second_half_work_timing_name ? (
          <div className="col-sm-12 col-lg-3 work_timing">
            <label className="h6 text-muted work_timing_title">
              {t("Second-Half Work timing")}
            </label>

            <div className="d-flex" onClick={handleViewSecondHalfWorkTime}>
              <span
                className="h6 work_timing_name cursor-pointer"
                dir={
                  HelperFns.isStringRTL(props?.second_half_work_timing_name)
                    ? "rtl"
                    : "ltr"
                }
              >
                {props?.second_half_work_timing_name}
              </span>
            </div>
          </div>
        ) : null}

        {props.can_edit ? (
          <div className="ml-auto">
            <button
              className="btn add_new_btn_style edit_sign_in_btn"
              type="button"
              onClick={() => props.handleEdit(props?.id)}
            >
              <EditIcon className="edit_btn_icon" />
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
  };
};

export default connect(mapStateToProps, {
  onInputResetAction,
  viewWorkTimeAction,
})(AttWorkTimeSectionPreview);
