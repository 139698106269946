import { ArrowDownward, ArrowUpward, Home } from "@mui/icons-material";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

const AttendanceLogsExpandedRow = ({ data }) => {
  const { t } = useTranslation();
  return (
    <>
      {data?.multiple_sign_ins?.map((data) => {
        return (
          <div className="attendance-logs-expanded-row">
            <div className="attendnace-logs-date">
              {data?.from_home == 1 ? (
                <>
                  {moment(data?.date).format("dddd DD-MM-YYYY")}{" "}
                  <Home className="home-icon ml-2" />
                </>
              ) : (
                <>{moment(data?.date).format("dddd DD-MM-YYYY")}</>
              )}
            </div>
            <div className="attendane-logs-sign-in-out-status-time">
              {data?.sign_in_time != null ? (
                <>
                  <ArrowDownward className="green-color mr-2" />
                  {moment(data?.sign_in_time).format("hh:mm A")}
                </>
              ) : (
                <>{""}</>
              )}
            </div>
            <div className="attendane-logs-sign-in-out-status-time">
              {data?.sign_out_time != null ? (
                <>
                  <ArrowUpward className="red-color mr-2" />
                  {moment(data?.sign_out_time).format("hh:mm A")}
                </>
              ) : (
                <>{""}</>
              )}
            </div>
            <div
              className={`${data?.status}_style attendane-logs-sign-in-out-status-time`}
            >
              {t(data?.status?.toLowerCase())}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default AttendanceLogsExpandedRow;
