import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import OfficesList from "../../Containers/OfficesList";
import DepartmentsList from "../../Containers/DepartmentsList";
import PositionsList from "../../Containers/PositionsList";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import HelperFns from "../../Helpers/HelperFns";
import DocumentsList from "../../Containers/DocumentsList";
import HolidaysList from "../../Containers/HolidaysList";
import CompanySettings from "../../Containers/CompanySettings";
import AttendanceProfile from "../AttendanceProfile";
import WorkTimingsListPage from "../WorkTimingsListPage";
import AccessLevels from "../../Containers/AccessLevels";
import Announcements from "../../Containers/Announcements";
import SettingsTabs from "./SettingsTabs";
import { ViewLeavesBreaks } from "../ViewLeavesBreaks";
import GeneralSettings from "../../Containers/GeneralSettings";
// import { LocationsList } from "../../Containers/LocationsList";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SettingsPage = (props) => {
  const history = useHistory();
  let query = useQuery();
  const activeValue = query.get("tab");
  useEffect(() => {
    if (!activeValue) {
      renderfirstTab();
    }
  }, [activeValue]);

  const renderfirstTab = () => {
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_OFFICES],
        allowBP: true,
      })
    ) {
      return history.push(`/settings?tab=offices`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_DEPARTMENTS],
        allowBP: true,
      })
    ) {
      return history.push(`/settings?tab=departments`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_POSITIONS],
        allowBP: true,
      })
    ) {
      return history.push(`/settings?tab=positions`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_ATTENDANCE_PROFILES],
        allowBP: true,
      })
    ) {
      return history.push(`/settings?tab=att-profile`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_WORK_TIMINGS],
        allowBP: true,
      })
    ) {
      return history.push(`/settings?tab=work-timings`);
    }
    // if (
    //   HelperFns.checkPrivileges({
    //     privileges: [Privilages.VIEW_LOCATIONS],
    //     allowBP: true,
    //   })
    // ) {
    //   return history.push(`/settings?tab=locations`);
    // }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_LEAVE_AND_BREAK_PROFILES],
        allowBP: true,
      })
    ) {
      return history.push(`/settings?tab=leaves-breaks`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_HOLIDAYS],
        allowBP: true,
      })
    ) {
      return history.push(`/settings?tab=holidays`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_COMPANY_DOCUMENTS],
        allowBP: true,
      })
    ) {
      return history.push(`/settings?tab=documents`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_COMPANY_DOCUMENTS],
        allowBP: true,
      })
    ) {
      return 5;
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_EXPENSE_CATEGORIES],
        allowBP: true,
      })
    ) {
      return history.push(`/settings?tab=expense-categories`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_ACCESS_LEVELS],
        allowBP: true,
      })
    ) {
      return history.push(`/settings?tab=access-levels`);
    }
    if (
      HelperFns.checkPrivileges({
        privileges: [Privilages.VIEW_COMPANY_ANNOUNCEMENT],
        allowBP: true,
      })
    ) {
      return history.push(`/settings?tab=announcements`);
    }
    return null;
  };

  const renderSelectedTab = () => {
    switch (activeValue?.toLowerCase()) {
      case "offices":
        return (
          <HasPrivileges reqireMain={[Privilages.VIEW_OFFICES]} allowBP>
            <OfficesList />
          </HasPrivileges>
        );
      case "departments":
        return (
          <HasPrivileges reqireMain={[Privilages.VIEW_DEPARTMENTS]} allowBP>
            <DepartmentsList />
          </HasPrivileges>
        );
      case "positions":
        return (
          <HasPrivileges reqireMain={[Privilages.VIEW_POSITIONS]} allowBP>
            <PositionsList />
          </HasPrivileges>
        );
      case "att-profile":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_ATTENDANCE_PROFILES]}
            allowBP
          >
            <AttendanceProfile />
          </HasPrivileges>
        );
      case "work-timings":
        return (
          <HasPrivileges reqireMain={[Privilages.VIEW_WORK_TIMINGS]} allowBP>
            <WorkTimingsListPage />
          </HasPrivileges>
        );
      // case "locations":
      //   return (
      //     <HasPrivileges reqireMain={[Privilages.VIEW_LOCATIONS]} allowBP>
      //       <LocationsList />
      //     </HasPrivileges>
      //   );
      case "leaves-breaks":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_LEAVE_AND_BREAK_PROFILES]}
            allowBP
          >
            <ViewLeavesBreaks />
          </HasPrivileges>
        );
      case "holidays":
        return (
          <HasPrivileges reqireMain={[Privilages.VIEW_HOLIDAYS]} allowBP>
            <HolidaysList />
          </HasPrivileges>
        );
      case "documents":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_COMPANY_DOCUMENTS]}
            allowBP
          >
            <DocumentsList />
          </HasPrivileges>
        );
      case "expense-categories":
        return (
          <HasPrivileges
            reqireMain={[Privilages.VIEW_EXPENSE_CATEGORIES]}
            allowBP
          >
            <CompanySettings />
          </HasPrivileges>
        );
      case "access-levels":
        return (
          <HasPrivileges reqireMain={[Privilages.VIEW_ACCESS_LEVELS]} allowBP>
            <AccessLevels />
          </HasPrivileges>
        );
      case "announcements":
        return (
          <HasPrivileges reqireMain={[Privilages.VIEW_COMPANY_ANNOUNCEMENT]} allowBP>
            <Announcements />
          </HasPrivileges>
        );
      // case "general-settings":
      //   return <GeneralSettings />;
      default:
        break;
    }
  };

  return (
    <div className="content pt-3">
      <SettingsTabs />
      {renderSelectedTab()}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.userProfile,
  };
};

export default connect(mapStateToProps)(SettingsPage);
