import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BSelect } from "form-builder";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";

import { components } from "react-select/dist/react-select.cjs.prod";

const LeavesBreaksSection = ({ formProps }) => {
  const leavesBreaksOptions = useSelector(
    (state) => state.super.leavesBreaksOptions
  );

  const Control = ({ children, ...props }) => {
    return (
      <components.Control {...props}>
        <PeopleOutlineIcon className="b-select-style_select_icon" />
        {children}
      </components.Control>
    );
  };

  const { t } = useTranslation();

  return (
    <div>
      <h4 className="att_profile_header my-3">{t("leave and break config")}</h4>

      <div className="boxContainer leaves_breaks_section">
        <BSelect
          {...formProps}
          name="permission_leaves_break_setting"
          validateBy="textRequired"
          label={"leave and break profile"}
          keepDefaultStyle
          options={leavesBreaksOptions}
          optionLabel="name"
          optionValue="id"
          labelStyle="custom-label-style"
          containerStyle="containerStyle"
          components={{ Control }}
          inputContainerStyle="flex-grow-1 mr-0 w-100"
          skipLocalization
        />
      </div>
    </div>
  );
};

export default LeavesBreaksSection;
