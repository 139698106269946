import React from "react";
import { useTranslation } from "react-i18next";

import clsx from "clsx";
import moment from "moment";
import HelperFns, {
  calculateAllowance,
  calculateDeductibles,
} from "../../Helpers/HelperFns";

import { Box, Divider } from "@mui/material";
import MainModal from "../../Components/MainModal";
import PaymentFactors from "../../Constants/PaymentFactors";

const OVERTIME_CALC_ID = "3";

const ViewSalaryConfigModal = ({
  isOpen,
  handleToggleModal = () => {},
  salaryConfig,
  ...props
}) => {
  const { t } = useTranslation();

  const baseSalary = salaryConfig?.details?.typeConfiguration?.salary;

  const totalAllowances = calculateAllowance(
    salaryConfig?.details?.allowanceConfiguration ?? [],
    baseSalary
  );

  const grossSalary = +baseSalary + totalAllowances;

  const totalDeductibles =
    calculateDeductibles(
      salaryConfig?.details?.deductionConfiguration ?? [],
      baseSalary,
      grossSalary
    ) +
    +salaryConfig?.details?.socialDeduction +
    +salaryConfig?.details?.medicalDeduction +
    +salaryConfig?.details?.taxDeduction;

  const overtimeConfigurations = !!salaryConfig?.details?.typeConfiguration
    ?.overtimeConfiguration?.periods?.length
    ? salaryConfig?.details?.typeConfiguration?.overtimeConfiguration?.periods
    : [
        {
          from: "00:00:00",
          to: "00:00:00",
          factor:
            salaryConfig?.details?.typeConfiguration?.overtimeConfiguration
              ?.factor,
        },
      ];

  // Constants
  // const overtimeCalc = props?.userSalaryConfigCalculations?.find(
  //   (c) => c?.calculation?.id === OVERTIME_CALC_ID
  // );

  /* ↓ Helpers ↓ */

  const formatCost = (cost) =>
    cost + " " + t(salaryConfig?.details?.currency?.name);

  const getSum = (arr = []) =>
    formatCost(arr.reduce((acc, val) => acc + +val?.value, 0));

  return (
    <MainModal
      hasModalFooter={false}
      isOpen={isOpen}
      toggle={handleToggleModal}
      modalTitle={t("salary configuration")}
    >
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          <time datetime={salaryConfig?.from}>
            {moment(salaryConfig?.from).format("Do MMM YYYY")}
          </time>{" "}
          -{" "}
          {salaryConfig?.to ? (
            <time datetime={salaryConfig?.to}>
              {moment(salaryConfig?.to).format("Do MMM YYYY")}
            </time>
          ) : (
            t("now")
          )}
        </div>

        <div className="d-flex gap-10">
          <span
            className={clsx({
              "blue-color": salaryConfig?.type === "Weekly",
            })}
          >
            {t("interval")}
          </span>
          <span
            className={clsx({
              "blue-color": salaryConfig?.type === "Monthly",
            })}
          >
            {t(salaryConfig?.type)}
          </span>
        </div>
      </div>

      {/* Full/Part Time */}
      {salaryConfig?.details?.type === "Fulltime" ||
      salaryConfig?.details?.type === "Parttime" ? (
        <div className="d-flex gap-20 mb-3">
          <strong className="dark-color text-16">{t("base_salary")}</strong>
          {formatCost(salaryConfig?.details?.typeConfiguration?.salary)}
        </div>
      ) : null}

      {/* Daily Rate */}
      {salaryConfig?.details?.type === "Day" ? (
        <div className="d-flex gap-20 mb-3">
          <strong className="dark-color text-16">{t("rate per day")}</strong>
          {formatCost(salaryConfig?.details?.typeConfiguration?.rpd)}
        </div>
      ) : null}

      {/* Hourly Rate */}
      {salaryConfig?.details?.type === "Hour" ? (
        <div className="mb-3">
          <strong
            style={{ backgroundColor: "#f3f9ff" }}
            className="d-block dark-color text-16 mb-1 py-2 px-1"
          >
            {t("rate per hour")}
          </strong>
          <PerHourRates
            rates={salaryConfig?.details?.typeConfiguration?.periods?.map(
              (period) => ({
                ...period,
                rate: period?.rph,
              })
            )}
            unit={t(salaryConfig?.details?.currency?.name) + "/" + t("hour")}
            isEveryday={salaryConfig?.details?.typeConfiguration?.periods?.find(
              (range) => !!!range?.day
            )}
          />
          <Divider sx={{ my: 1 }} />
          <RowUnit
            label={t("hours_per_paid_leave")}
            txt={salaryConfig?.details?.typeConfiguration?.hppl}
          />
          <RowUnit
            label={t("paid_leave_hourly_rate")}
            txt={salaryConfig?.details?.typeConfiguration?.rpph}
          />
        </div>
      ) : null}

      {/* Allowances */}
      <Unit
        name={t("allowances")}
        arr={salaryConfig?.details?.allowanceConfiguration}
        txt={
          <div className="d-flex gap-10">
            total
            <b>{totalAllowances}</b>
          </div>
        }
        cb={(allowance) => (
          <RowUnit
            key={allowance?.id}
            label={allowance?.name}
            txt={
              allowance?.paymentFactor == "Fixed"
                ? formatCost(allowance?.value)
                : formatCost(
                    (+allowance?.value / 100) *
                      +salaryConfig?.details?.typeConfiguration?.salary
                  )
            }
          />
        )}
      />

      {/* Deductibles */}

      <div>
        <div
          style={{ backgroundColor: "#f3f9ff" }}
          className="d-flex align-items-center gap-20 mb-1 py-2 px-1"
        >
          <strong className="dark-color text-16">{t("deductibles")}</strong>
          <div className="d-flex gap-10">
            total
            <b>{totalDeductibles}</b>
          </div>
        </div>
        <Box
          sx={{
            rowGap: 1.25,
            columnGap: 2.5,
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            justifyContent: "space-between",
          }}
        >
          <RowUnit
            key={Math.random()}
            label={"social deduction"}
            txt={formatCost(salaryConfig?.details?.socialDeduction)}
          />
          <RowUnit
            key={Math.random()}
            label={"medical deduction"}
            txt={formatCost(salaryConfig?.details?.medicalDeduction)}
          />
          <RowUnit
            key={Math.random()}
            label={"taxes"}
            txt={formatCost(salaryConfig?.details?.taxDeduction)}
          />
          {salaryConfig?.details?.deductionConfiguration?.map((deduction) => {
            return (
              <RowUnit
                key={deduction?.id}
                label={deduction?.name}
                txt={
                  deduction?.paymentFactor == "Fixed"
                    ? formatCost(+deduction?.value)
                    : deduction?.paymentFactor == "Percent of base salary"
                    ? formatCost(
                        (+deduction?.value / 100) *
                          +salaryConfig?.details?.typeConfiguration?.salary
                      )
                    : formatCost(
                        (+deduction?.value / 100) *
                          (+salaryConfig?.details?.typeConfiguration?.salary +
                            calculateAllowance(
                              salaryConfig?.details?.allowanceConfiguration,
                              +salaryConfig?.details?.typeConfiguration?.salary
                            ))
                      )
                }
              />
            );
          })}
        </Box>
      </div>

      <Unit
        name={t("deductibles")}
        arr={totalDeductibles}
        txt={
          <div className="d-flex gap-10">
            total
            <b>{totalDeductibles}</b>
          </div>
        }
        cb={(deductible) => (
          <RowUnit
            key={deductible?.id}
            label={deductible?.name}
            txt={formatCost(deductible?.value)}
          />
        )}
      />

      {/* Calculations */}

      <div
        style={{ backgroundColor: "#f3f9ff" }}
        className="d-flex align-items-center gap-20 mb-1 py-2 px-1"
      >
        <strong className="dark-color text-16">{t("calculations")}</strong>
      </div>

      <Box
        sx={{
          rowGap: 1.25,
          columnGap: 2.5,
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          justifyContent: "space-between",
        }}
      >
        <RowUnit
          label={"day of penalty"}
          txt={
            salaryConfig?.details?.penaltyConfiguration?.paymentFactor !=
              "Fixed" &&
            !!!salaryConfig?.details?.penaltyConfiguration?.monthOption
              ? "calendar month"
              : salaryConfig?.details?.penaltyConfiguration?.value
          }
          extra={t(salaryConfig?.details?.penaltyConfiguration?.paymentFactor)}
        />

        <RowUnit
          label={t("one_hour_penalty")}
          txt={salaryConfig?.details?.penaltyConfiguration?.oneHour}
        />

        <RowUnit
          label={"day of bonus"}
          txt={
            salaryConfig?.details?.bonusConfiguration?.paymentFactor !=
              "Fixed" &&
            !!!salaryConfig?.details?.bonusConfiguration?.monthOption
              ? "calendar month"
              : salaryConfig?.details?.bonusConfiguration?.value
          }
          extra={t(salaryConfig?.details?.bonusConfiguration?.paymentFactor)}
        />

        <RowUnit
          label={"day of unpaid leave"}
          txt={
            salaryConfig?.details?.unpaidConfiguration?.paymentFactor !=
              "Fixed" &&
            !!!salaryConfig?.details?.unpaidConfiguration?.monthOption
              ? "calendar month"
              : salaryConfig?.details?.unpaidConfiguration?.value
          }
          extra={t(salaryConfig?.details?.unpaidConfiguration?.paymentFactor)}
        />
      </Box>
      <p className="mb-0 mt-2">{t("day of overtime")}</p>

      {overtimeConfigurations?.map((setting) => {
        return (
          <div
            style={{ rowGap: 10 }}
            className="d-flex flex-wrap gap-20 mt-1 px-1"
          >
            <p className="font-weight-bold">{t("range")}</p>
            <p>{`${moment(setting?.from, "hh:mm:ss").format(
              "hh:mm A"
            )} - ${moment(setting?.to, "hh:mm:ss").format("hh:mm A")}`}</p>
            <p className="font-weight-bold">{t("factor")}</p>
            <p>{<span className="blue-color">{setting?.factor}</span>}</p>
            {!!setting?.amount ? (
              <p className="m-0">
                <b>{t("amount")}: </b>
                {formatCost(
                  !!setting?.amount
                    ? parseFloat(setting?.amount)?.toFixed(2)
                    : 0
                )}
              </p>
            ) : null}
          </div>
        );
      })}

      {/* <RowUnit
        label={t("one_hour_bonus")}
        txt={props?.userSalaryConfigDetail?.one_hour_bonus}
      /> */}

      {/* <Unit
        name={t("calculations")}
        arr={props?.userSalaryConfigCalculations}
        cb={(calc, i) => {
          return calc?.calculation?.id !== OVERTIME_CALC_ID ? (
            <>
              <RowUnit
                label={calc?.calculation?.name.replace("1 ", "")}
                txt={
                  calc?.MonthOption?.id == 1 // if it's calendar month
                    ? t("Calendar Month")
                    : formatCost(
                        !!calc?.amount
                          ? parseFloat(calc?.amount)?.toFixed(2)
                          : 0
                      )
                }
                extra={t(calc?.PaymentFactor?.factor)}
              />
              {i === 1 ? (
                <>
                  <RowUnit
                    label={t("one_hour_bonus")}
                    txt={props?.userSalaryConfigDetail?.one_hour_bonus}
                  />
                  <RowUnit
                    label={t("one_hour_penalty")}
                    txt={props?.userSalaryConfigDetail?.one_hour_penalty}
                  />
                </>
              ) : null}
            </>
          ) : null;
        }}
        extra={
          <div className="mt-2">
            {overtimeCalc?.calculation?.name.replace("1 ", "")}: <br />
            {overtimeCalc?.userSalaryConfigCalculationOvertimeSettings?.map(
              (setting) => (
                <div
                  style={{ rowGap: 10 }}
                  className="d-flex flex-wrap gap-20 mt-1 px-1"
                >
                  <p className="font-weight-bold">{t("range")}</p>
                  <p>{`${moment(setting?.from, "hh:mm:ss").format(
                    "hh:mm A"
                  )} - ${moment(setting?.to, "hh:mm:ss").format(
                    "hh:mm A"
                  )}`}</p>
                  <p className="font-weight-bold">{t("factor")}</p>
                  <p>
                    {
                      <span className="blue-color">
                        {setting?.overtime_payment_factor}
                      </span>
                    }
                  </p>
                  <p className="m-0">
                    <b>{t("amount")}: </b>
                    {formatCost(
                      !!setting?.amount
                        ? parseFloat(setting?.amount)?.toFixed(2)
                        : 0
                    )}
                  </p>
                </div>
              )
            )}
          </div>
        }
      /> */}
    </MainModal>
  );
};

const PerHourRates = ({ isEveryday, rates, unit }) => {
  const { t } = useTranslation();

  const formattedRates = HelperFns.serializeFetchedHourlyRating(rates);

  return isEveryday ? (
    <div className="d-flex gap-20">
      <span>{t("range")}: </span>
      <Rates rates={formattedRates?.Everyday} unit={unit} />
    </div>
  ) : (
    <>
      {Object?.keys(formattedRates)?.map((day) => (
        <div className="d-flex align-items-center gap-20 border-bottom py-2">
          <strong className="blue-color" style={{ width: 60 }}>
            {t(day.toLowerCase())}
          </strong>
          <Rates rates={formattedRates?.[day]} unit={unit} />
        </div>
      ))}
    </>
  );
};

const Rates = ({ rates, unit }) => {
  const getFormattedTime = (time) => moment(time, "hh:mm").format("hh:mm A");

  return (
    <div
      style={{ rowGap: 10 }}
      className="flex-1 d-flex flex-wrap gap-20 justify-content-between dark-color"
    >
      {rates?.map((rate, i) => (
        <div key={i} className="d-flex gap-20 justify-content-between">
          <b>
            {getFormattedTime(rate?.from)} - {getFormattedTime(rate?.to)}
          </b>
          <b>
            {rate?.rate} {unit}
          </b>
        </div>
      ))}
    </div>
  );
};

const Unit = ({ arr = [], name, txt = "", cb, extra = "" }) =>
  arr?.length ? (
    <div className="mb-2">
      <div
        style={{ backgroundColor: "#f3f9ff" }}
        className="d-flex align-items-center gap-20 mb-1 py-2 px-1"
      >
        <strong className="dark-color text-16">{name}</strong>
        {txt}
      </div>

      <Box
        sx={{
          rowGap: 1.25,
          columnGap: 2.5,
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          justifyContent: "space-between",
        }}
      >
        {arr.map(cb)}
      </Box>

      {extra}
    </div>
  ) : null;

const RowUnit = ({ label, txt, extra = "" }) => (
  <div className="d-flex gap-20">
    <p className="m-0 w-100">{label}</p>
    <p className="m-0 w-100 font-weight-bold">{txt}</p>
    <p className="m-0 w-100">{extra}</p>
  </div>
);

export default ViewSalaryConfigModal;
