import React, { useEffect, useState } from "react";
import MainModal from "../../MainModal";
import { InputForm } from "form-builder";
import { connect } from "react-redux";
import {
  dismissEditBussPartnersModalAction,
  updateCompanyBussPartnersAction,
  addBusinessPartnersInputInProfileAction,
  removeBusinessPartnersInputInProfileAction,
  childInputChange,
} from "../../../Store/Actions";
import { useTranslation } from "react-i18next";
import { faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IntlTelInput from "react-intl-tel-input";
// import "../../Assets/sass/phone-input.scss";
import Tooltip from "@mui/material/Tooltip";

const formName = "editCompanyBussPartnersModal";
const formNameValidation = "editCompanyBussPartnersModalValidation";
const formServerValidation = "editCompanyBussPartnersModalServerValidation";

const validateAddingBusinessPartners = (
  numberOfPartners,
  numberOfEmployees,
  planLimits
) => {
  if (planLimits > numberOfPartners + numberOfEmployees) {
    return true;
  } else {
    return false;
  }
};

const AddBussPartnersModal = (props) => {
  const numberOfPartners =
    props.editCompanyBussPartnersModal.partners_input.length;
  const numberOfEmployees = props?.data?.selectedCompanyProfile?.numberOfEmployees;
  const planLimits =
    props?.data?.selectedCompanyProfile?.currentSubscription?.employees_limit ??
    Infinity;

  // console.log(`${planLimits} : ${numberOfEmployees} - ${numberOfPartners}`);
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const FormProps = {
    formName,
    formNameValidation,
    formServerValidation,
    formSubmitting: isSubmitting,
  };

  // Start of add/remove bussiness partners multiple inputs
  const handleAddBussinessPartnersInputs = (company_id) => {
    props.addBusinessPartnersInputInProfileAction(company_id);
  };

  const handleRemoveBussinessPartnersInputs = (index) => {
    props.removeBusinessPartnersInputInProfileAction(index);
  };

  const handleChildInputChange = (event, childName, index) => {
    props.childInputChange(
      formName,
      childName,
      event.target.name,
      index,
      event.target.value
    );
  };
  // End of add/remove bussiness partners multiple inputs

  // Start of country code extention function action
  const handleUpdateCountryCode = (seletedCountryData, childName, index) => {
    let country_code = seletedCountryData.iso2;
    props.childInputChange(
      formName,
      childName,
      "phone_country_code",
      index,
      country_code
    );
  };

  const handlePhoneChange = (value, seletedCountryData, childName, index) => {
    let country_code = seletedCountryData.iso2;
    props.childInputChange(
      formName,
      childName,
      "phone_country_code",
      index,
      country_code
    );
    props.childInputChange(formName, childName, "phone", index, value);
  };
  // End of country code extention function action

  const handleSubmitBtn = () => {
    setIsSubmitting(true);
    if (props.modalValidation.length) {
      return;
    }

    props.updateCompanyBussPartnersAction({
      data: props.editCompanyBussPartnersModal,
      ref: "editCompanyBussPartnersModalActions",
    });
  };

  const isModalVissible = props.modalData.isVissible;
  const modalMessage = props.modalData.modalMessage;
  const isLoading = props.modalData.isLoading;

  const toggleModal = () => {
    props.dismissEditBussPartnersModalAction({
      ref: "editCompanyBussPartnersModalActions",
      formName,
    });
  };

  useEffect(() => {
    setIsSubmitting(false);
  }, [isModalVissible]);

  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={toggleModal}
      modalTitle={t("Add Company Business Partners")}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={isLoading}
      className="company_form_wrapper_style"
    >
      <>
        <div className="row employee-form px-3">
          <div className="col-12 my-0">
            <div className="d-flex align-items-baseline">
              <div className="mb-2">
                <h4 className="sub-title-style mb-1">{t("business partners")}</h4>
                <hr />
              </div>

              <div className="ml-3">
                <Tooltip
                  title={
                    !validateAddingBusinessPartners(
                      numberOfPartners,
                      numberOfEmployees,
                      planLimits
                    )
                      ? t("you have reached the maximum number of employees")
                      : ""
                  }
                >
                  <div className="d-inline-block">
                    <button
                      className={`btn add_new_btn_style ${
                        !validateAddingBusinessPartners(
                          numberOfPartners,
                          numberOfEmployees,
                          planLimits
                        )
                          ? "pe-none"
                          : ""
                      }`}
                      type="button"
                      disabled={
                        !validateAddingBusinessPartners(
                          numberOfPartners,
                          numberOfEmployees,
                          planLimits
                        )
                      }
                      onClick={() =>
                        handleAddBussinessPartnersInputs(
                          props?.editCompanyBussPartnersModal?.id
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                </Tooltip>
              </div>
            </div>

            {props?.editCompanyBussPartnersModal?.partners_input?.map(
              (bussinessPartner, i) => (
                <>
                  <div className="row mt-2 mb-2">
                    <div className="col mb-2">
                      <InputForm
                        {...FormProps}
                        name="name"
                        validateBy="textRequired"
                        value={bussinessPartner.name}
                        onChange={(e) =>
                          handleChildInputChange(e, "partners_input", i)
                        }
                        validationName={`input.${i}.name`}
                        label="name"
                        labelStyle="mb-2"
                        containerStyle="mt-0"
                        inputContainerStyle=" "
                        icon="person"
                      />
                    </div>

                    <div className="col mb-2">
                      <div
                        className={
                          isSubmitting &&
                          props?.serverVaildation &&
                          `input.${i}.phone` in props?.serverVaildation
                            ? "invalid-container-style"
                            : " "
                        }
                      >
                        <label className="mb-2 validity-label-style">
                          {t("Phone Number")}
                        </label>
                        <div dir="ltr">
                          <IntlTelInput
                            inputClassName="input-style-default"
                            style={{ display: "flex" }}
                            fieldName={"phone"}
                            telInputProps={{
                              dir: "ltr",
                            }}
                            preferredCountries={["eg", "ae", "sa"]}
                            onPhoneNumberChange={(
                              isValid,
                              value,
                              seletedCountryData,
                              fullNumber,
                              extension,
                              event
                            ) => {
                              handlePhoneChange(
                                value,
                                seletedCountryData,
                                "partners_input",
                                i
                              );
                            }}
                            onSelectFlag={(
                              currentNumber,
                              seletedCountryData,
                              fullNumber,
                              isValid
                            ) => {
                              handleUpdateCountryCode(
                                seletedCountryData,
                                "partners_input",
                                i
                              );
                            }}
                            defaultCountry={bussinessPartner?.phone_country_code?.toLowerCase()}
                            separateDialCode
                            format={false}
                            formatOnInit={false}
                            value={bussinessPartner?.phone}
                          />
                        </div>
                        <div className="validity-msg-style">
                          {props?.serverVaildation &&
                          `input.${i}.phone` in props?.serverVaildation
                            ? props?.serverVaildation[`input.${i}.phone`]
                            : null}
                        </div>
                      </div>
                    </div>

                    <div className="col mb-2">
                      <InputForm
                        {...FormProps}
                        name="email"
                        value={bussinessPartner.email}
                        onChange={(e) =>
                          handleChildInputChange(e, "partners_input", i)
                        }
                        validationName={`input.${i}.email`}
                        label="email"
                        labelStyle="mb-2"
                        containerStyle="mt-0"
                        inputContainerStyle=" "
                        icon="mail"
                      />
                    </div>

                    {bussinessPartner.id != null ? (
                      ""
                    ) : (
                      <div className="mt-3 pt-3 pr-3">
                        <button
                          className="btn add_new_btn_style cancel_object_btn_style"
                          type="button"
                          onClick={() => handleRemoveBussinessPartnersInputs(i)}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                      </div>
                    )}
                  </div>
                </>
              )
            )}
          </div>

          {/* (Start) Error Message */}
          {modalMessage && isSubmitting && (
            <div className="warnig-msg-style">{modalMessage}</div>
          )}
          {/* (End) Error Message */}
        </div>
      </>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  modalData: state.super.editCompanyBussPartnersModalActions,
  modalValidation: state.super[formNameValidation],
  editCompanyBussPartnersModal: state.super[formName],
  serverVaildation: state.super[formServerValidation],
  data: state.super,
});

export default connect(mapStateToProps, {
  dismissEditBussPartnersModalAction,
  updateCompanyBussPartnersAction,
  addBusinessPartnersInputInProfileAction,
  removeBusinessPartnersInputInProfileAction,
  childInputChange,
})(AddBussPartnersModal);
