import React, { useEffect } from "react";
import { InputForm, DateTimePickerForm } from "form-builder";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  setDataTableSortingAction,
  fetchAllHolidaysAction,
  showHolidaysModalAction,
  deleteHolidayAction,
  deleteSuggestedHolidayAction,
  autoSuggestHolidaysAction,
  acceptSuggestedHolidayAction,
  onFormResetAction,
} from "../../Store/Actions";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import AntSwitch from "../../Components/AntSwitch";
import HolidaysModal from "./HolidaysModal";
import swal from "sweetalert";
import Loader from "../../Components/Loader";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import moment from "moment";
import { useTranslation } from "react-i18next";
import HelperFns from "../../Helpers/HelperFns";
import {
  EditIconButton,
  RemoveIconButton,
  AcceptIconButton,
} from "../../Components/IconButtonWithTooltip";

const dataTableRef = "holidaysList";

const HolidaysList = (props) => {
  const { t } = useTranslation();

  const columns = [
    {
      name: t("name"),
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 1,
      cell: (row) => (
        <div dir={HelperFns.isStringRTL(row.name) ? "rtl" : "ltr"}>
          {row.name}
        </div>
      ),
    },
    {
      name: t("from"),
      wrap: true,
      selector: "start_date",
      sortable: false,
      grow: 1,
      cell: (row) => (
        <>
          {row?.start_date != null
            ? moment(row?.start_date).format("DD - MM - yyyy")
            : ""}
        </>
      ),
    },
    {
      name: t("to"),
      wrap: true,
      selector: "end_date",
      sortable: false,
      grow: 1,
      cell: (row) => (
        <>
          {row?.end_date != null
            ? moment(row?.end_date).format("DD - MM - yyyy")
            : ""}
        </>
      ),
    },
    {
      name: t("year"),
      wrap: true,
      selector: "year",
      sortable: false,
      grow: 1,
      cell: (row) => (
        <>
          {row?.start_date != null
            ? moment(row?.start_date).format("yyyy")
            : ""}

          {row?.end_date != null &&
          moment(row?.start_date).format("yyyy") !==
            moment(row?.end_date).format("yyyy")
            ? ` - ${moment(row?.end_date).format("yyyy")}`
            : ""}
        </>
      ),
    },
    {
      name: t("offices"),
      wrap: true,
      sortable: false,
      grow: 1.5,
      cell: (row) => (
        <>
          {row?.apply_on_all_offices ||
          row?.suggested_offices?.length === props?.user?.offices?.length ? (
            <>{t("all offices")}</>
          ) : (
            <>
              {row?.company != null ? (
                <>
                  {row?.holidayProfiles
                    ?.filter(({ office }) => office)
                    ?.map(
                      (holidayProfile, i) =>
                        `${i !== 0 && holidayProfile?.office ? " , " : ""}
              ${
                holidayProfile?.office != null
                  ? holidayProfile?.office?.name
                  : ""
              }`
                    )}{" "}
                </>
              ) : (
                <>
                  {row?.suggested_offices?.map(
                    (suggestedHoliday, i) =>
                      `${i !== 0 && suggestedHoliday?.name ? " , " : ""}${
                        suggestedHoliday?.name
                      }`
                  )}
                </>
              )}
            </>
          )}
        </>
      ),
    },
    {
      name: t("attendance profiles"),
      wrap: true,
      sortable: false,
      grow: 1.5,
      cell: (row) => (
        <>
          {row?.apply_on_all_attendance_profiles ? (
            <>{t("All Attendance Profiles")}</>
          ) : (
            <>
              {row?.attendanceProfiles?.map(
                (attProfile, i) =>
                  `${i !== 0 && attProfile?.name ? " , " : ""}${
                    attProfile?.name
                  }`
              )}
            </>
          )}
        </>
      ),
    },
    {
      name: t("status"),
      wrap: true,
      sortable: false,
      grow: 0.7,
      cell: (row) => <>{row?.company != null ? t("active") : t("Suggested")}</>,
    },
    {
      name: "",
      wrap: true,
      selector: "action",
      grow: 1.7,
      cell: (row) => (
        <div className="cards_table_actions">
          {row?.company != null ? (
            ""
          ) : (
            <AcceptIconButton onClick={() => handleAcceptSuggested(row)} />
          )}

          <HasPrivileges
            reqireMain={[Privilages.ADD_EDIT_HOLIDAYS]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={<EditIconButton />}
          >
            <EditIconButton onClick={() => handleEdit(row)} />
          </HasPrivileges>

          <HasPrivileges
            reqireMain={[Privilages.DELETE_HOLIDAYS]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={<RemoveIconButton />}
          >
            <RemoveIconButton onClick={() => handleDelete(row)} />
          </HasPrivileges>
        </div>
      ),
      ignoreRowClick: true,
    },
  ];

  const handleEdit = (row) => {
    let data = {
      id: row?.company != null ? row?.id : null,
      name: row?.name,
      start_date: row?.start_date,
      end_date: row?.end_date,
      all_offices: row?.apply_on_all_offices,
      all_att_profiles: row?.apply_on_all_attendance_profiles,
      office_ids:
        row?.company != null
          ? row?.holidayProfiles?.map(({ office }) => office?.id)
          : row?.suggested_offices?.map(
              (suggestedHoliday) => suggestedHoliday?.id
            ),
      att_profile_ids: row?.attendanceProfiles?.map(
        (attProfiles) => attProfiles?.id
      ),
      suggested_holiday_id: row?.company != null ? null : row?.id,
      type: "edit",
    };
    props.showHolidaysModalAction({
      data,
      ref: "holidaysModalActions",
      formName: "holidaysModal",
    });
  };

  const handleAdd = () => {
    let data = {
      id: null,
      name: "",
      start_date: "",
      end_date: "",
      all_offices: 1,
      all_att_profiles: 1,
      office_ids: [],
      att_profile_ids: [],
      suggested_holiday_id: null,
      type: "add",
    };
    props.showHolidaysModalAction({
      data,
      ref: "holidaysModalActions",
      formName: "holidaysModal",
    });
  };

  const handleDelete = (row) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        if (row?.company != null) {
          props.deleteHolidayAction(row?.id);
        } else {
          props.deleteSuggestedHolidayAction(row?.id);
        }
      }
    });
  };

  // to handle auto suggest holiday switch
  const handleAutoSuggestHolidays = () => {
    props.autoSuggestHolidaysAction(props?.user?.company?.id);
  };

  // handle accept suggested holiday
  const handleAcceptSuggested = (row) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willAccept) => {
      if (willAccept) {
        props.acceptSuggestedHolidayAction(row?.id);
      }
    });
  };

  useEffect(() => {
    props.fetchAllHolidaysAction({
      ref: dataTableRef,
      [dataTableRef]: props.data[dataTableRef],
    });
    return () => {
      props.onFormResetAction(dataTableRef);
    };
  }, []);

  const handleFilter = () => {
    props.fetchAllHolidaysAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: 1,
        },
      },
    });
  };

  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    props.data[dataTableRef].filter,
    props.data[dataTableRef].year,
  ]);

  const handlePaginate = (
    page = props.data[dataTableRef].pagination.currentPage
  ) => {
    props.fetchAllHolidaysAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: page,
        },
      },
    });
  };

  const handleSorting = (field, dir) => {
    props.fetchAllHolidaysAction({
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: 1,
        },
        sorting: {
          dir,
          key: field.selector,
        },
      },
      ref: dataTableRef,
    });
    props.setDataTableSortingAction(dataTableRef, field.selector, dir);
  };

  return (
    <>
      <div className="mt-3 settings_holiday_wrapper_style">
        {props?.data?.autoSuggestHolidaysLoading ||
        props?.data?.acceptSuggestedHolidayLoading ||
        props?.data?.deleteSuggestedHolidayLoading ? (
          <div className="loader_wrapper_style">
            <Loader />
          </div>
        ) : null}

        <div className="cusotm-holidays-filters-container mb-3">
          <div className="d-flex align-items-center mx-1">
            <label>
              {t("Auto-suggest Holidays According To Office Countries")}
            </label>
            <AntSwitch
              className="mx-2"
              name="auto_suggest_holidays"
              onChange={handleAutoSuggestHolidays}
              checked={props?.data[dataTableRef]?.auto_suggest_holidays}
            />
          </div>

          <div className="col flex-row px-0 holidays-search-input mr-2">
            <InputForm
              name="filter"
              formName={dataTableRef}
              labelStyle="focus-label"
              placeholder={t("search")}
              containerStyle=" "
              inputStyle="search_input"
              inputContainerStyle="search_container"
              icon="search"
            />
          </div>

          <div className="d-flex align-items-center justify-content-between holidays-year-add-new-container">
            <DateTimePickerForm
              name="year"
              formName={dataTableRef}
              label={t("year")}
              hasIcon
              reducer="super"
              labelStyle="mr-3"
              containerStyle="container-style-default flex-nowrap"
              rootStyle="att-datepicker-style"
              placeholder={t("select year")}
              allowClear
              picker="year"
              requestFormat="yyyy"
              pickerFormat="yyyy"
              format="yyyy"
            />

            <HasPrivileges
              reqireMain={[Privilages.ADD_EDIT_HOLIDAYS]}
              allowBP
              avalibleOnExpire={false}
              altExpireChildren={
                <div className="my-2 text-right ml-2">
                  <button
                    disabled
                    className="btn add_new_btn_style"
                    type="button holidays-add-new-btn"
                  >
                    <FontAwesomeIcon className="mr-2" icon={faPlus} />
                    {t("add new")}
                  </button>
                </div>
              }
            >
              <div className="text-right ml-2 holidays-add-new-btn">
                <button
                  className="btn add_new_btn_style"
                  type="button"
                  onClick={handleAdd}
                >
                  <FontAwesomeIcon className="mr-2" icon={faPlus} />
                  {t("add new")}
                </button>
              </div>
            </HasPrivileges>
          </div>
        </div>

        <DataTable
          className="cards_table"
          columns={columns}
          data={props.data[dataTableRef].data}
          noHeader
          persistTableHead
          sortServer
          onSort={handleSorting}
          defaultSortAsc={props.data[dataTableRef].sorting.dir == "asc"}
          defaultSortField={props.data[dataTableRef].sorting.key}
          paginationComponent={() => (
            <Pagination
              tableRef={dataTableRef}
              styleWraper=""
              onPaginate={handlePaginate}
            />
          )}
          pagination={true}
          paginationServer={true}
          progressPending={props.data[dataTableRef].isLoading}
          progressComponent={<Loader />}
        />
      </div>
      <HolidaysModal />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
    user: state.auth.userProfile,
  };
};

export default connect(mapStateToProps, {
  setDataTableSortingAction,
  fetchAllHolidaysAction,
  showHolidaysModalAction,
  deleteHolidayAction,
  deleteSuggestedHolidayAction,
  autoSuggestHolidaysAction,
  acceptSuggestedHolidayAction,
  onFormResetAction,
})(HolidaysList);
