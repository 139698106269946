import React, { useEffect } from "react";
import { connect } from "react-redux";
import Constants from "../../Helpers/Constants";
import moment from "moment";
import { useLazyQuery } from "@apollo/client";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import {
  showAddNewBonusModalAction,
  showEditBonusModalAction,
  fetchUsersBonusAction,
  onInputResetAction,
  deleteBonusAction,
  showErrorToast,
} from "../../Store/Actions";
import { BSelect, DateTimePickerForm, RadioboxForm } from "form-builder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faFileExport } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import AddNewBonusModal from "./AddNewBonusModal";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import CanViewEmployeeProfile from "../../Helpers/HOC/CanViewEmployeeProfile";
import Privilages from "../../Constants/Privilages";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import PaymentFactors from "../../Constants/PaymentFactors";
import {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";
import { AddButton, ColorButton } from "../../Components/Buttons";
import ImportBonusModal from "./ImportBonusModal";
import { Spinner } from "reactstrap";
import { exportBonusQuery } from "../../Graphql/query";
import HelperFns from "../../Helpers/HelperFns";
import { useDispatch } from "react-redux";

const dataTableRef = "BonusList";

const Bonus = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isImportModalOpen, setIsOpenModalOpen] = React.useState(false);

  const fetchData = () => {
    props.fetchUsersBonusAction({
      ref: dataTableRef,
      [dataTableRef]: props.data[dataTableRef],
    });
  };

  useEffect(() => {
    fetchData();
    return () => {
      props.onInputResetAction(dataTableRef, "status");
      props.onInputResetAction(dataTableRef, "from");
      props.onInputResetAction(dataTableRef, "to");
      props.onInputResetAction(dataTableRef, "user_id");
    };
  }, []);

  const handleFilter = () => {
    props.fetchUsersBonusAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: 1,
        },
      },
    });
  };

  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    props.data[dataTableRef].status,
    props.data[dataTableRef].from,
    props.data[dataTableRef].to,
    props.data[dataTableRef].user_id,
  ]);

  const handlePaginate = (
    page = props.data[dataTableRef].pagination.currentPage
  ) => {
    props.fetchUsersBonusAction({
      ref: dataTableRef,
      [dataTableRef]: {
        ...props.data[dataTableRef],
        pagination: {
          ...props.data[dataTableRef].pagination,
          currentPage: page,
        },
      },
    });
  };

  // for add new bonus modal
  const handleAddBonus = () => {
    props.showAddNewBonusModalAction({
      ref: "AddNewBonusModalActions",
      formName: "AddNewBonusModal",
    });
  };

  // for edit bonus modal
  const handleEditBonusModal = (data) => {
    let editRecord = {
      name: data?.name,
      user_id: data?.user?.id,
      payment_factor_id: data?.payment_factor_id,
      value: data?.value,
      payroll_at: data?.payroll_at,
      description: data?.description,
      id: data?.id,
    };
    props.showEditBonusModalAction(editRecord);
  };

  // to delete bonus record
  const handleDeleteBonus = (row) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteBonusAction(row?.id);
      }
    });
  };

  // to export bonus
  const handleExportBonusList = () => {
    const bonusListFilters = props.data[dataTableRef];

    exportBonusesList({
      variables: {
        input: {
          filter_data: {
            filter_flag: "bonuses_filter",
            user_id: bonusListFilters?.user_id,
            status: bonusListFilters?.status,
            from: bonusListFilters?.from,
            to: bonusListFilters?.to,
          },
        },
      },
    });
  };

  const [exportBonusesList, { loading }] = useLazyQuery(exportBonusQuery, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      if (
        response?.exportBonuses &&
        response?.exportBonuses?.status === "success"
      ) {
        HelperFns.downloadFile(response?.exportBonuses?.file);
      } else {
        dispatch(showErrorToast(response?.exportBonuses?.message));
      }
    },
    onError: (error) => {
      dispatch(showErrorToast(error?.message));
    },
  });

  // to approximate the numbers
  function roundToTwo(num) {
    return (+num)?.toFixed(2);
  }

  const columns = [
    {
      name: t("employee name"),
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 2.2,
      cell: (row) => (
        <CanViewEmployeeProfile
          allowBP
          directManger={row?.user?.manager?.id}
          copiedManagers={row?.user?.copied_managers?.map((cp) => cp?.id)}
          altChildren={<div className="col pl-0">{row?.user?.name}</div>}
        >
          <div className="col pl-0">
            <Link
              className="employee-name"
              to={`/employees/employee-profile/${row?.user?.id}`}
            >
              {row?.user?.name}
            </Link>
          </div>
        </CanViewEmployeeProfile>
      ),
    },
    {
      name: t("amount"),
      wrap: true,
      selector: "amount",
      sortable: false,
      grow: 1.2,
      cell: (row) => (
        <div className="d-flex">
          {row?.amount > 0 ? (
            <div>
              <span className="pr-1">{t(row?.currency?.name)}</span>
              {roundToTwo(row?.amount)}
            </div>
          ) : (
            <div>
              {roundToTwo(row?.value)}{" "}
              <span className="pl-1">
                {row?.payment_factor_id === PaymentFactors.FIXED_AMOUNT ? (
                  row?.currency ? (
                    t(row?.currency?.name)
                  ) : (
                    <FontAwesomeIcon icon={faCoins} />
                  )
                ) : (
                  t("days")
                )}
              </span>
            </div>
          )}
        </div>
      ),
    },
    {
      name: t("bonus name"),
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 1.2,
    },
    {
      name: t("created by"),
      wrap: true,
      selector: "name",
      sortable: false,
      grow: 1.2,
      cell: (row) => <>{row?.createdBy?.name}</>,
    },
    {
      name: t("payable at"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      cell: (row) =>
        !!row?.payroll_at ? moment(row?.payroll_at).format("DD-MM-YYYY") : "",
    },
    {
      name: t("actions"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      cell: (row) =>
        row?.payment_status?.toLowerCase() == "unpaid" && (
          <HasPrivileges
            reqireMain={[Privilages.ADD_EDIT_DELETE_BONUSES_DEDUCTIONS]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={
              <div className="cards_table_actions">
                <EditIconButton />
                <RemoveIconButton />
              </div>
            }
          >
            <div className="cards_table_actions">
              <EditIconButton onClick={() => handleEditBonusModal(row)} />
              <RemoveIconButton onClick={() => handleDeleteBonus(row)} />
            </div>
          </HasPrivileges>
        ),
    },
  ];

  const isExportBtnDisabled =
    loading ||
    !!!props?.data?.[dataTableRef]?.from ||
    !!!props?.data?.[dataTableRef]?.to;

  return (
    <div className="extra_bonus_wrapper_style">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <div className="d-flex gap-10 justify-content-between align-items-center">
          <RadioboxForm
            formName={dataTableRef}
            options={Constants.BonusStatusFilter?.map(({ label, value }) => ({
              label: t(label),
              value,
            }))}
            name="status"
            type="radio"
            containerStyle="my-2 my-lg-0"
            labelStyle="label-style"
            optionsContainerStyle="optionsContainerStyle"
            optionItemStyle="optionItemStyle"
            optionInputStyle=" "
            optionLabelStyle="optionLabelStyle"
          />
          <DateTimePickerForm
            name="from"
            placeholder="from"
            formName={dataTableRef}
            requestFormat="YYYY-MM-DD"
          />
          <DateTimePickerForm
            name="to"
            placeholder="to"
            formName={dataTableRef}
            requestFormat="YYYY-MM-DD"
          />
          <BSelect
            reducer="super"
            name="user_id"
            formName={dataTableRef}
            options={props?.data?.extraEmployeesFilter}
            keepDefaultStyle
            optionLabel="name"
            optionValue="id"
            rootStyle="min-width-200"
            isClearable
            placeholder={t("employee")}
            icon="person"
          />
        </div>
        <div className="d-flex gap-10">
          <HasPrivileges
            reqireMain={[Privilages.ADD_EDIT_DELETE_BONUSES_DEDUCTIONS]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={<AddButton />}
          >
            <AddButton onClick={handleAddBonus} />
          </HasPrivileges>
          <ColorButton
            onClick={() => setIsOpenModalOpen(true)}
            label="import bonus"
          />
          <button
            className={`btn py-0 export-btn text-nowrap
                       ${isExportBtnDisabled ? "disabled" : ""}`}
            type="button"
            onClick={handleExportBonusList}
            disabled={isExportBtnDisabled}
          >
            {loading ? (
              <Spinner
                style={{ width: "1rem", height: "1rem", color: "#fff" }}
              />
            ) : (
              <>
                <FontAwesomeIcon className="mr-2" icon={faFileExport} />
                {t("export")}
              </>
            )}
          </button>
        </div>
      </div>

      <DataTable
        noDataComponent={t("no_records")}
        className="cards_table"
        columns={columns}
        data={props.data[dataTableRef].data}
        noHeader
        persistTableHead
        sortServer
        defaultSortAsc={props.data[dataTableRef].sorting.dir == "asc"}
        defaultSortField={props.data[dataTableRef].sorting.key}
        paginationComponent={() => (
          <Pagination
            tableRef={dataTableRef}
            styleWraper=""
            onPaginate={handlePaginate}
            reducer="super"
          />
        )}
        pagination={true}
        paginationServer={true}
        progressPending={props.data[dataTableRef].isLoading}
        progressComponent={<Loader />}
      />

      {/* Start of  Add new bonus modal */}
      {props.isBonusModalVisible ? <AddNewBonusModal /> : null}
      {/* End of  Add new bonus modal */}
      {isImportModalOpen ? (
        <ImportBonusModal
          refetchList={fetchData}
          onClose={() => setIsOpenModalOpen(false)}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
    isBonusModalVisible: state.super.AddNewBonusModalActions.isVissible,
  };
};

export default connect(mapStateToProps, {
  showAddNewBonusModalAction,
  showEditBonusModalAction,
  fetchUsersBonusAction,
  onInputResetAction,
  deleteBonusAction,
})(Bonus);
