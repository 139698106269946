import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import {
  fetchPettyCashListAction,
  onInputResetAction,
  showModifyPettyCashBalanceModalAction,
  fetchPettyCashCurrenciesAction,
} from "../../Store/Actions";
import { BSelect, DateTimePickerForm } from "form-builder";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import Pagination from "../../Components/Pagination";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import CanViewEmployeeProfile from "../../Helpers/HOC/CanViewEmployeeProfile";
import Privilages from "../../Constants/Privilages";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ModifyPettyCashModal from "../../Components/ModifyPettyCashModal";
import { EditIconButton } from "../../Components/IconButtonWithTooltip";

const dataTableRef = "pettyCashList";
const modalActionsRef = "modifyPettyCashBalanceModalActions";

const PettyCash = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Reducer State
  const pettyCashList = useSelector((state) => state.finances[dataTableRef]);
  const modalActions = useSelector((state) => state.finances[modalActionsRef]);
  const pettyCashCurrenciesOptionsData = useSelector(
    (state) => state.finances.pettyCashCurrenciesOptionsData
  );
  const availablePettyCashEmployeesFilterOptions = useSelector(
    (state) => state.finances.avaliablePettyCashEmployeesFilterOptions
  );

  /* ↓ State Effects ↓ */

  useEffect(() => {
    dispatch(
      fetchPettyCashListAction({
        ref: dataTableRef,
        pettyCashList,
      })
    );
    dispatch(
      fetchPettyCashCurrenciesAction({
        emp_id: "",
        type: "",
      })
    );
    return () => {
      dispatch(onInputResetAction(dataTableRef, "emp_id"));
      dispatch(onInputResetAction(dataTableRef, "currency_id"));
      dispatch(onInputResetAction(dataTableRef, "from"));
      dispatch(onInputResetAction(dataTableRef, "to"));
    };
  }, []);

  const handleFilter = () => {
    dispatch(
      fetchPettyCashListAction({
        ref: dataTableRef,
        [dataTableRef]: {
          ...pettyCashList,
          pagination: {
            ...pettyCashList.pagination,
            currentPage: 1,
          },
        },
      })
    );
  };

  const useDidFilterUpdate = useDidUpdateEffect(handleFilter, [
    pettyCashList.emp_id,
    pettyCashList.currency_id,
    pettyCashList.from,
    pettyCashList.to,
  ]);

  /* ↓ Helpers ↓ */

  const handlePaginate = (page = pettyCashList.pagination.currentPage) => {
    dispatch(
      fetchPettyCashListAction({
        ref: dataTableRef,
        [dataTableRef]: {
          ...pettyCashList,
          pagination: {
            ...pettyCashList.pagination,
            currentPage: page,
          },
        },
      })
    );
  };

  // handleing modifiy petty cash balance modal
  const handleModifyPettyCashBalance = () => {
    let data = {
      id: null,
    };
    dispatch(
      showModifyPettyCashBalanceModalAction({
        data,
        ref: modalActionsRef,
        formName: "modifyPettyCashBalanceModal",
      })
    );
  };

  // handleing edit petty cash balance modal
  const handleEditPettyCashBalanceModal = (row) => {
    let data = {
      id: row?.id,
      emp_id: row?.emp_id,
      type: row?.type,
      date: row?.date,
      amount: row?.amount.toString(),
      currency_id: row?.currency?.id,
      attachments: row?.files,
    };
    dispatch(
      showModifyPettyCashBalanceModalAction({
        data,
        ref: modalActionsRef,
        formName: "modifyPettyCashBalanceModal",
      })
    );
  };

  // to approximate the numbers
  function roundToTwo(num) {
    return (+num)?.toFixed(2);
  }

  const columns = [
    {
      name: t("employee"),
      wrap: true,
      sortable: false,
      grow: 1.5,
      cell: (row) => (
        <CanViewEmployeeProfile
          allowBP
          directManger={row?.user?.manager?.id}
          copiedManagers={row?.user?.copied_managers?.map((cp) => cp?.id)}
          altChildren={<div className="col pl-0">{row?.employee_name}</div>}
        >
          <div className="col pl-0">
            <Link
              className="employee-name"
              to={`/employees/employee-profile/${row?.emp_id}`}
            >
              {row?.employee_name}
            </Link>
          </div>
        </CanViewEmployeeProfile>
      ),
    },
    {
      name: t("date"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      cell: (row) => (
        <>
          {row?.date != null ? moment(row?.date).format("DD - MM - YYYY") : ""}
        </>
      ),
    },
    {
      name: t("amount"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      cell: (row) =>
        row.type === "add" ? (
          <div className="d-flex text-success">
            <div className="pr-1">{roundToTwo(row?.amount)}</div>
            <span>{row?.currency?.symbol}</span>
          </div>
        ) : (
          <div className="d-flex text-danger">
            (<div className="pr-1">{roundToTwo(row?.amount)}</div>
            <span>{row?.currency?.symbol}</span>)
          </div>
        ),
    },
    {
      name: t("added by"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      cell: (row) => <>{row?.added_by?.name}</>,
    },
    {
      name: t("current balance"),
      wrap: true,
      sortable: false,
      grow: 1.2,
      cell: (row) => (
        <div className="d-flex amount_currency_wrapper_style">
          <div className="pr-1">{roundToTwo(row?.balance)}</div>
          <span>{row?.currency?.symbol}</span>
        </div>
      ),
    },
    {
      name: t("actions"),
      wrap: true,
      center: true,
      sortable: false,
      grow: 1.2,
      cell: (row) => (
        <HasPrivileges
          reqireMain={[Privilages.MODIFY_PETTY_CASH_BALANCE]}
          allowBP
          avalibleOnExpire={false}
          altExpireChildren={
            <div className="cards_table_actions">
              <EditIconButton />
            </div>
          }
        >
          <div className="cards_table_actions">
            <EditIconButton
              onClick={() => handleEditPettyCashBalanceModal(row)}
            />
          </div>
        </HasPrivileges>
      ),
    },
  ];

  return (
    <div className="petty_cash_wrapper_style">
      <div className="row mb-4 align-items-baseline">
        <div className="col-12 col-xl-10">
          <div className="row align-items-baseline">
            <div className="col-12 col-xl-4 pb-1 pb-xl-0">
              <BSelect
                reducer="finances"
                name="emp_id"
                formName={dataTableRef}
                options={availablePettyCashEmployeesFilterOptions}
                keepDefaultStyle
                optionLabel="name"
                optionValue="emp_id"
                containerStyle="my-0 mb-2 mb-lg-0"
                isClearable
                placeholder={t("select_employee")}
                icon="person"
              />
            </div>

            {pettyCashCurrenciesOptionsData.length === 1 ? (
              ""
            ) : (
              <div className="col-12 col-xl-2 pb-1 pb-xl-0 px-xl-0">
                <BSelect
                  reducer="finances"
                  name="currency_id"
                  formName={dataTableRef}
                  options={pettyCashCurrenciesOptionsData}
                  keepDefaultStyle
                  optionLabel="name"
                  optionValue="id"
                  placeholder={t("select currency")}
                  isClearable
                />
              </div>
            )}

            <div className="col-12 col-xl-6 pb-4 pb-xl-0 d-flex flex-wrap">
              <DateTimePickerForm
                reducer="finances"
                name="from"
                formName={dataTableRef}
                label="from"
                hasIcon
                labelStyle="label-style mb-0"
                containerStyle="container-style-default mr-sm-4 flex-nowrap"
                rootStyle="att-datepicker-style"
                requestFormat="yyyy-MM-DD"
                allowClear
              />
              <DateTimePickerForm
                reducer="finances"
                name="to"
                formName={dataTableRef}
                label="to"
                hasIcon
                labelStyle="label-style mb-0"
                containerStyle="container-style-default flex-nowrap"
                rootStyle="att-datepicker-style"
                requestFormat="yyyy-MM-DD"
                allowClear
              />
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end col-12 col-xl-2">
          <HasPrivileges
            reqireMain={[Privilages.MODIFY_PETTY_CASH_BALANCE]}
            allowBP
            avalibleOnExpire={false}
            altExpireChildren={
              <button className="btn add_new_btn_style" type="button" disabled>
                {t("Modify Balance")}
              </button>
            }
          >
            <button
              className="btn add_new_btn_style"
              type="button"
              onClick={handleModifyPettyCashBalance}
              disabled={pettyCashList.isLoading}
            >
              {t("Modify Balance")}
            </button>
          </HasPrivileges>
        </div>
      </div>

      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="cards_table"
        columns={columns}
        data={pettyCashList.data}
        noHeader
        persistTableHead
        paginationComponent={() => (
          <Pagination
            tableRef={dataTableRef}
            styleWraper=""
            onPaginate={handlePaginate}
            reducer="finances"
          />
        )}
        pagination={true}
        paginationServer={true}
        progressPending={pettyCashList.isLoading}
        progressComponent={<Loader />}
      />

      {/* Start of modify/edit petty cash balance modal */}
      {modalActions.isVissible && <ModifyPettyCashModal />}
      {/* End of modify/edit petty cash balance modal */}
    </div>
  );
};

export default PettyCash;
