import React, { useState } from "react";
import {
  DateTimePickerForm,
  InputForm,
  CheckboxForm,
  BSelect,
  RadioboxForm,
} from "form-builder";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { connect, useSelector, useDispatch } from "react-redux";
import { onInputFieldChange, onRecruitemtProccessFormFieldsChangeAction } from "../../Store/Actions";

import Types from "../../Store/Actions/types";

const insert = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index),
];

const PreviewProcessStage = (props) => {
  const dispatch = useDispatch();

  const handleDrop = (ev) => {
    if (ev.currentTarget.parentElement.id && dragId) {
      const dragBox = props.data.find((box) => box.id === dragId);
      const dropBoxIndex = props.data?.findIndex(
        (box) => box?.id === ev.currentTarget?.parentElement?.id
      );

      let u = props.data.filter((box) => box.id !== dragId);
      let fields = insert(u, dropBoxIndex, dragBox)?.map((box, index) => ({
        ...box,
        order: index + 1,
      }));
      props.onUpdatePreview(fields)
      // dispatch(onRecruitemtProccessFormFieldsChangeAction(props.stageId, fields))


      dispatch({
        type: Types.UPDATE_PROCESS_STAGE_FIELDS_ORDER,
        payload: {
          fields,
          stageIndex: props.stageIndex,
        },
      });
      setDragId(null);
    }
    if (ev.currentTarget.id && dragId) {
      const dragBox = props.data.find((box) => box.id === dragId);
      const dropBoxIndex = props.data.findIndex(
        (box) => box.id === ev.currentTarget.id
      );

      let u = props.data.filter((box) => box.id !== dragId);
      let fields = insert(u, dropBoxIndex, dragBox)?.map((box, index) => ({
        ...box,
        order: index + 1,
      }));

      props.onUpdatePreview(fields)

      // dispatch(onRecruitemtProccessFormFieldsChangeAction(props.stageId, fields))


      // dispatch({
      //   type: Types.UPDATE_PROCESS_STAGE_FIELDS_ORDER,
      //   payload: {
      //     fields,
      //     stageIndex: props.stageIndex,
      //   },
      // });
      setDragId(null);
    }

    // console.log(dragBox, dragBox);
  };

  const [dragId, setDragId] = useState(null);

  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
  };

  const renderElement = (element) => {
    switch (element.type) {
      case "text":
        return (
          <InputForm
            {...element}
            name={element.name}
            label={element.name}
            reducer="recruitment"
            value="  "
            formName="previewStageForm"
            labelStyle="mb-2"
            containerStyle="mt-0 mb-3"
            inputContainerStyle=" "
          // disabled
          />
        );
      case "checkbox":
        return (

          <CheckboxForm
            value="  "
            {...element}
            key={element.id}
            label={element.name}
            reducer="recruitment"
            formName="previewStageForm"
            containerStyle="my-2"
            labelStyle="label-style"
            type="checkbox"
            optionsContainerStyle="optionsContainerStyle gap-10"
            optionItemStyle="optionItemStyle my-2 fit-width"
            optionInputStyle="align-items-center d-flex fit-width flex-row optionInputStyle"
            optionLabelStyle="optionLabelStyle lineheight-initial text-wrap fit-width"
            // disabled
            options={element.options}
          />
        );
      case "date":
        return (
          <DateTimePickerForm
            value="  "
            {...element}
            key={element.id}
            label={element.name}
            reducer="recruitment"
            formName="previewStageForm"
          // disabled
          />
        );
      case "dropList":
        return (
          <BSelect
            menuPortalTarget={document.querySelector("body")}
            value="  "
            {...element}
            key={element.id}
            label={element.name}
            reducer="recruitment"
            formName="previewStageForm"
            optionLabel="label"
            optionValue="value"
            // disabled
            options={element.options}
            isMulti={element.selection_type === "multiple"}
          />
        );
      case "radio":
        return (
          <RadioboxForm
            value="  "
            {...element}
            key={element.id}
            label={element.name}
            reducer="recruitment"
            formName="previewStageForm"
            containerStyle="my-2"
            labelStyle="label-style"
            type="radio"
            optionsContainerStyle="optionsContainerStyle gap-10"
            optionItemStyle="optionItemStyle my-2 fit-width"
            optionInputStyle="align-items-center d-flex fit-width flex-row optionInputStyle"
            optionLabelStyle="optionLabelStyle lineheight-initial text-wrap fit-width"
            options={element.options}
          />
        );
      case "attachment":
        return (
          <div className="h-auto">
            <label className="label-style">{element.name}</label>
            <Dropzone
              value="  "
              name="user_image"
              multiple={false}
              maxFiles={1}
              inputContent={"attachments"}
            />
          </div>
        );

      default:
        return <div>{element.type}</div>;
    }
  };

  const [resize, setResize] = useState({
    id: null,
    width: 0,
  });

  const [initialPos, setInitialPos] = React.useState(null);
  const [initialSize, setInitialSize] = React.useState(null);

  const resizeStart = (e) => {
    e.stopPropagation();
    if (e.currentTarget.parentElement.id) {
      let resizable = document.getElementById(e.currentTarget.parentElement.id);
      setInitialPos(e.clientX);
      setInitialSize(resizable.offsetWidth);
    }
  };

  const resizeEnd = (e) => {
    e.stopPropagation();
    if (e.currentTarget.parentElement.id) {
      // let resizable = document.getElementById(e.currentTarget.parentElement.id);
      let style = `wp-${Math.floor(
        ((initialSize + (e.clientX - initialPos)) /
          document.getElementById("container").offsetWidth) *
        100
      )}`;

      if (
        Math.floor(
          ((initialSize + (e.clientX - initialPos)) /
            document.getElementById("container").offsetWidth) *
          100
        ) > 0
      ) {

        let updatedFields = props.data.map((el) => el.id === e.currentTarget.parentElement.id ? ({ ...el, grid: style }) : ({ ...el }))

        props.onUpdatePreview(updatedFields)
        // dispatch(onRecruitemtProccessFormFieldsChangeAction(props.stageId, updatedFields))

        // dispatch(
        //   onInputFieldChange(
        //     "recruitmentProcessestSages",
        //     props.stageIndex,
        //     e.currentTarget.parentElement.id,
        //     "grid",
        //     style
        //   )
        // );
      }
    }
  };

  return (
    <div id="container" className="row w-100 flex-wrap form-preview mx-0">
      {props?.data
        ?.sort((a, b) => a.order - b.order)
        ?.map((el) => (
          <div
            draggable
            id={el.id}
            style={{
              // resize: "horizontal",
              border:
                el.id === dragId ? "2px solid #23aaeb" : "1px dashed #ccc",
              borderRadius: "5px",
              margin: "10px 10px",
              overflow: "hidden",
              margin: "0 11px 13px 0",
              padding: "11px 17px 11px 16px",
              borderRadius: "4px",
              boxShadow: "0 0 7px 0 rgba(0, 0, 0, 0.16)",
              backgroundColor: "#fff",

              // cursor: "move",
              backgroundColor: "#fff",
              height: "auto",
              minWidth: "200px",
              position: "relative",
            }}
            className={`px-3 py-3 mx-3 ${el.grid}`}
            onDragOver={(ev) => {
              ev.preventDefault();
            }}
            onDragStart={handleDrag}
            onDrop={handleDrop}
          >
            {/* <div
              className="left-resize-border"
              // draggable="true"
              onDragOver={(ev) => {
                ev.preventDefault();
              }}
              // onDragStart={handleDrag}
              // onDrop={handleDrop}
            /> */}
            <div
              className="right-resize-border"
              draggable="true"
              onDragStart={resizeStart}
              onDrag={resizeEnd}
              onDragOver={(ev) => {
                ev.preventDefault();
              }}
            />
            {renderElement(el)}

            {!!el?.description && (
              <div className="notes-style"> {el?.description} </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default PreviewProcessStage;
