import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Constants from "../../Constants";
import MainModal from "../MainModal";
import {
  BSelect,
  InputForm,
  DateTimePickerForm,
  CheckboxBooleanForm,
} from "form-builder";
import Dropzone from "react-dropzone-uploader";
import {
  onInputResetAction,
  attendanceActionModalFailed,
  toggleAttendanceActionModal,
  normalRequestActionAttempt,
  unpaidRequestActionAttempt,
  emergencyRequestActionAttempt,
  permissionRequestActionAttempt,
  halfdayRequestActionAttempt,
  homeExchangeRequestActionAttempt,
  homeFlexRequestActionAttempt,
  upsertClaimsRequestAction,
  onInputChangeAction,
  upsertSickRequestAction,
  onInputResetWithValueAction,
  upsertCustomRequestAction,
  showErrorToast,
  showSuccessToast,
  updateValueAction,
} from "../../Store/Actions";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import HelperFns, { getReqData, showToast } from "../../Helpers/HelperFns";
import * as REQUESTS from "../../Constants/Requests";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import {
  applicableWorktimingsQuery,
  employeeBalanceQuery,
  employeeClaimsQuery,
  employeeProfileQuery,
  employeeRequestsQuery,
  getEmployeeProfile,
  myBalanceQuery,
  myClaimsQuery,
  myProfileQuery,
  myRequestsQuery,
  relevantAssignmentsQuery,
  workTimingsQuery,
} from "../../Graphql/query";
import {
  dayOffRequestMutation,
  ignoreExceptionMutation,
  requestChangeShiftMutation,
  requestLoanMutation,
  unpaidHalfDayMutation,
} from "../../Graphql/mutation";
import swal from "sweetalert";
import { RadioboxForm } from "../../Builder/Form";
import gql from "graphql-tag";

const formName = "attendanceActionModal";
const formNameValidation = "attendanceActionModalValidation";
const userFormServerValidation = "requestFormServerValidation";

const GET_EMPLOYEES = gql`
  query getEmployees {
    users_by_role(
      first: 9999
      input: { page_flag: "employees_list", status: ["Active"] }
    ) {
      data {
        id
        name
      }
    }
  }
`;

const GET_AVAILABLE_REQUESTS = gql`
  query getAvailableRequests($employeeID: ID = null, $date: String = null) {
    availableRequests: getAllowedRequests(
      employeeID: $employeeID
      date: $date
    ) {
      id
      name
    }
    employee(id: $employeeID) {
      relevantAttendanceTypeConfiguration(date: $date) {
        workRemotelySettings {
          can_ex_days
          home_days
          flexible_home
        }
      }
    }
  }
`;

const FormProps = {
  formName,
  formNameValidation,
};

const handleDateTimePickerForm = (
  isSubmiting,
  name,
  placeholder,
  type,
  requestFormat = "YYYY-MM-DD",
  label
) => (
  <DateTimePickerForm
    {...FormProps}
    name={name}
    placeholder={placeholder}
    validateBy="textRequired"
    formSubmitting={isSubmiting}
    containerStyle="my-1"
    inputContainerStyle=" "
    {...{ [type]: true }}
    reducer="user"
    validationName={`input.${name}`}
    formServerValidation={userFormServerValidation}
    rootStyle="w-100"
    requestFormat={requestFormat}
    {...(label && { label })}
  />
);

const AttendanceActionModal = ({ isEmployee, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [generalExceptionMessage, setGeneralExceptionMessage] = useState("");
  const [clientEmployee, setClientEmployee] = useState(null);

  const {
    action_type,
    from,
    to,
    assignment_id,
    half_day_will_attend,
    requestFrom,
    requestTo,
    work_timing_id,
  } = useSelector((state) => state.user.attendanceActionModal);

  const selectedProfile = useSelector(
    (state) => state?.super?.selectedEmployeeProfile
  );

  const userProfile = useSelector((state) => state.user.userProfile);

  const employeeProfile = isEmployee ? userProfile : selectedProfile;

  const employeeId = props?.hasEmpInput
    ? clientEmployee?.id
    : employeeProfile?.id;

  const [applicableWorkTimings, setApplicableWorkTimings] = useState([]);
  const [requestable, setRequestable] = useState(null);

  const myRequestsList = useSelector((state) => state.user.myRequestsList);
  const myBalanceList = useSelector((state) => state.user.myBalanceList);
  const myClaimsList = useSelector((state) => state.user.myClaimsList);

  const employeeRequestsList = useSelector(
    (state) => state.super.employeeRequestsList
  );
  const employeeBalanceList = useSelector(
    (state) => state.super.employeeBalanceList
  );
  const employeeClaimsList = useSelector(
    (state) => state.super.employeeProfileClaimsList
  );

  useEffect(() => {
    setGeneralExceptionMessage("");

    if (!requestTo) {
      dispatch(onInputResetWithValueAction(formName, "requestTo", requestFrom));
    }

    if (action_type === REQUESTS.IGNORE_ASSIGNMENT && requestFrom) {
      setAssignmentsOptions([]);
      fetchRelevantAssignments();
    }
  }, [requestFrom]);

  useEffect(() => {
    setGeneralExceptionMessage("");

    if (!to) {
      dispatch(onInputResetWithValueAction(formName, "to", from));
    }
  }, [from]);

  const [assignmentsOptions, setAssignmentsOptions] = useState([]);
  const [worKTimingsOptions, setWorKTimingsOptions] = useState([]);
  const [
    ignoreAssignmentExceptionMessage,
    setIgnoreAssignmentExceptionMessage,
  ] = useState("");

  const [isSubmiting, setIsSubmiting] = useState(false);

  const [requestFiles, setRequestFiles] = useState([]);

  const serializedAttachemnts = requestFiles?.map(({ file, extension }) => ({
    file,
    extension,
  }));

  const isModalVissible = props.modalActions.isVissible;
  const modalMessage = props.modalActions.modalMessage;
  const isLoading = props.modalActions.isLoading;

  const employeeRefetchQueries = [
    {
      query: myProfileQuery,
    },
    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_REQUESTS],
      allowBP: true,
    })
      ? [
          {
            query: myRequestsQuery,
            variables: {
              rows: 10,
              page: 1,
              field: myRequestsList.sorting.key,
              order: myRequestsList.sorting.dir.toUpperCase(),
              status: myRequestsList.statusFilter,
              from: myRequestsList?.from,
              to: myRequestsList?.to,
            },
          },
        ]
      : []),
    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_LEAVE_BALANCE_HISTORY],
      allowBP: true,
    })
      ? [
          {
            query: myBalanceQuery,
            variables: {
              rows: 10,
              page: 1,
              field: myBalanceList.sorting.key,
              order: myBalanceList.sorting.dir.toUpperCase(),
              type: myBalanceList.type,
              year: myBalanceList.yearFilter,
            },
          },
        ]
      : []),
    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.APPLY_FOR_CLAIM_REQUESTS],
      allowBP: true,
    })
      ? [
          {
            query: myClaimsQuery,
            variables: {
              claimRows: 10,
              claimPage: 1,
              claimStatus: myClaimsList.status,
              from: myClaimsList.from,
              to: myClaimsList.to,
            },
          },
        ]
      : []),
  ];

  const hasActiveSubscription = useSelector(
    (state) => state?.auth?.userProfile?.company?.hasActiveSubscription
  );

  const managerRefetchQueries = [
    {
      query: employeeProfileQuery,
      variables: {
        id: employeeId,
        isExpired: !hasActiveSubscription,
      },
    },
    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_EMPLOYEE_REQUESTS],
      allowBP: true,
    })
      ? [
          {
            query: employeeRequestsQuery,
            variables: {
              id: employeeId,
              rows: 10,
              page: 1,
              field: employeeRequestsList.sorting.key,
              order: employeeRequestsList.sorting.dir.toUpperCase(),
              status: employeeRequestsList.statusFilter,
              from: employeeRequestsList?.from,
              to: employeeRequestsList?.to,
              route: "employee_profile",
            },
          },
        ]
      : []),
    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_EMPLOYEE_BALANCE_ADJUSTMENT],
      allowBP: true,
    })
      ? [
          {
            query: employeeBalanceQuery,
            variables: {
              id: employeeId,
              rows: 10,
              page: 1,
              field: employeeBalanceList?.sorting.key,
              order: employeeBalanceList?.sorting.dir.toUpperCase(),
              type: employeeBalanceList?.type,
              year: employeeBalanceList?.yearFilter,
            },
          },
        ]
      : []),

    ...(HelperFns.checkPrivileges({
      privileges: [Privilages.VIEW_EMPLOYEE_CLAIMS],
      allowBP: true,
    })
      ? [
          {
            query: employeeClaimsQuery,
            variables: {
              claimRows: 10,
              claimPage: 1,
              id: employeeId,
              claimStatus: employeeClaimsList?.status,
              from: employeeClaimsList?.from,
              to: employeeClaimsList?.to,
            },
          },
        ]
      : []),
  ];

  const { data: emps, loading: empsLLoading } = useQuery(GET_EMPLOYEES, {
    skip: !props?.hasEmpInput,
  });

  const [
    fetchRelevantAssignments,
    { loading: fetchRelevantAssignmentsLoading },
  ] = useLazyQuery(relevantAssignmentsQuery, {
    variables: {
      input: {
        from: moment(requestFrom).format("YYYY-MM-DD"),
        to: moment(requestFrom).format("YYYY-MM-DD"),
        employee_id: employeeId,
      },
    },
    onCompleted: (data) => {
      setAssignmentsOptions(data?.relevantAssignments?.data ?? []);
    },
  });

  const { loading: fetchWorkTimingsLoading } = useQuery(workTimingsQuery, {
    variables: {
      date: requestFrom,
    },
    skip: ![REQUESTS.CHANGE_SHIFT, REQUESTS.ADDITIONAL_SHIFT].includes(
      action_type
    ),
    onCompleted: (data) => {
      setWorKTimingsOptions(data?.work_timings_menu ?? []);
      if (
        !data?.work_timings_menu?.find(
          (wt) => wt.id == props?.data.work_timing_id
        )
      ) {
        dispatch(updateValueAction(formName, "work_timing_id", null));
      }
    },
  });

  const [
    fetchApplicableWorkTimings,
    { loading: fetchApplicableWorkTimingsLoading },
  ] = useLazyQuery(applicableWorktimingsQuery);

  const handleRefetchingIncaseOfSuccess = () => {
    dispatch(
      onInputResetWithValueAction("attendanceModalActions", "isVissible", false)
    );
    dispatch(showSuccessToast());
  };

  const [
    ignoreException,
    { loading: ignoreExceptionLoading, error: ignoreExceptionError },
  ] = useMutation(ignoreExceptionMutation, {
    errorPolicy: "all",
    variables: {
      input: {
        assignment_id:
          assignmentsOptions?.length === 1
            ? assignmentsOptions[0]?.id
            : assignment_id,
        date: moment(requestFrom).format("YYYY-MM-DD"),
        employee_id: employeeId,
        files: serializedAttachemnts,
      },
    },
    onCompleted: (data) => {
      if (data) {
        if (data?.ignoreException?.__typename === "GeneralException") {
          return setIgnoreAssignmentExceptionMessage(
            data?.ignoreException?.message ?? ""
          );
        }
        if (data?.ignoreException?.__typename === "PaidPayrollException") {
          return setIgnoreAssignmentExceptionMessage(
            data?.ignoreException?.message ?? ""
          );
        }
        handleRefetchingIncaseOfSuccess();
      }
    },
    refetchQueries: isEmployee ? employeeRefetchQueries : managerRefetchQueries,
    onError: (error) => {
      dispatch(showErrorToast(error?.message));
    },
  });

  useEffect(() => {
    if (ignoreExceptionError) {
      dispatch(
        showErrorToast(
          ignoreExceptionError?.graphQLErrors?.[0]?.message ||
            ignoreExceptionError?.message
        )
      );
    }
  }, [ignoreExceptionError]);

  const [submitUnpaidHalfDay, { loading: unpaidHalfDayLoading }] = useMutation(
    unpaidHalfDayMutation,
    {
      onCompleted: (data) => {
        if (
          data?.unpaidHalfDay?.__typename === "GeneralException" ||
          data?.unpaidHalfDay?.__typename === "PaidPayrollException"
        ) {
          return setGeneralExceptionMessage(data?.unpaidHalfDay?.message ?? "");
        }

        if (data?.unpaidHalfDay) {
          handleRefetchingIncaseOfSuccess();
        } else {
          dispatch(showErrorToast());
        }
      },
      onError: (error) => {
        dispatch(showErrorToast(error?.graphQLErrors[0]));
      },
      refetchQueries: isEmployee
        ? employeeRefetchQueries
        : managerRefetchQueries,
    }
  );

  const [submitDayOff, { loading: dayOffLoading }] = useMutation(
    dayOffRequestMutation,
    {
      onCompleted: (data) => {
        if (
          data?.day_off_request?.__typename === "GeneralException" ||
          data?.day_off_request?.__typename === "PaidPayrollException"
        ) {
          return setGeneralExceptionMessage(
            data?.day_off_request?.message ?? ""
          );
        }

        if (data?.day_off_request?.request?.id) {
          handleRefetchingIncaseOfSuccess();
        } else {
          dispatch(showErrorToast());
        }
      },
      onError: (error) => {
        if (error?.message === "swal") {
          swal({
            title: t("are you sure"),
            text: t("You have other requests on this day"),
            icon: "warning",
            className: "swal-warning-style",
            dangerMode: true,
            buttons: true,
            className: "swal-warning-style",
            buttons: [t("Cancel"), t("confirm")],
          }).then((confirm) => {
            if (confirm) {
              submitDayOff({
                variables: {
                  input: {
                    date: requestFrom,
                    files: serializedAttachemnts,
                    swalValue: true,
                  },
                },
              });
            }
          });
          return;
        }
        dispatch(showErrorToast(error?.graphQLErrors[0].extensions?.reason));
      },
      refetchQueries: isEmployee
        ? employeeRefetchQueries
        : managerRefetchQueries,
    }
  );

  const handleDayOff = () => {
    submitDayOff({
      variables: {
        input: {
          employeeId: employeeId,
          date: requestFrom,
          files: serializedAttachemnts,
        },
      },
    });
  };

  const handleIgnoreException = () => {
    if (fetchRelevantAssignmentsLoading) return;
    if (assignmentsOptions?.length === 0) {
      setIgnoreAssignmentExceptionMessage(
        t("you have no assignments at this date")
      );
      return;
    }
    ignoreException();
  };

  useEffect(() => {
    setRequestable(null);
    props.onInputResetAction(formName, "from");
    props.onInputResetAction(formName, "to");
    props.onInputResetAction(formName, "deducted_from_emergency");
    props.onInputResetAction(formName, "half_day_will_attend");
    props.onInputResetAction(formName, "home_day");
    props.onInputResetAction(formName, "amount");
    props.onInputResetAction(formName, "reason");
    props.onInputResetAction(formName, "assignment_id");
    props.onInputResetAction(formName, "work_timing_id");
    props.onInputResetAction(formName, "notes");
    props.onInputResetAction(formName, "name");
    setIgnoreAssignmentExceptionMessage("");
    props.attendanceActionModalFailed("");
    setRequestFiles([]);
    // reset dropzone content
    const btn = document.querySelector(".dzu-submitButton");
    if (btn) {
      btn.click();
    }
    // reset dropzone content
    setIsSubmiting(false);
    setApplicableWorkTimings([]);
    setGeneralExceptionMessage("");

    if (action_type === REQUESTS.IGNORE_ASSIGNMENT && requestFrom) {
      setAssignmentsOptions([]);
      fetchRelevantAssignments();
    }
  }, [action_type]);

  useEffect(() => {
    setGeneralExceptionMessage("");
    setRequestable(null);
    setApplicableWorkTimings([]);
    setIsSubmiting(false);
  }, [requestFrom, requestTo]);

  const fetchApplicableWorkTimingsFunc = (date, requestFor) => {
    setApplicableWorkTimings([]);
    setRequestable(null);
    return new Promise((resolve, reject) => {
      fetchApplicableWorkTimings({
        variables: {
          employee_id: employeeId,
          date: moment(date).format("YYYY-MM-DD"),
          for: requestFor,
        },
        onCompleted: (data) => {
          let options = [
            ...(data?.employee?.applicableWorkTimings?.default ?? []),
            ...(data?.employee?.applicableWorkTimings?.exceptions ?? []),
          ];
          let normalizedOptions = [];

          options?.forEach((applicable) => {
            switch (applicable?.__typename) {
              case "AttendanceProfileWorkTiming":
                normalizedOptions.push({
                  id: applicable?.id,
                  name: applicable?.work_timing?.name,
                  typename: applicable?.__typename,
                });
                break;
              case "ScheduleDetailEmployee":
                const alternativeWorktimings =
                  applicable?.ScheduleDetail?.alternativeTiming?.map(
                    (alterWorktiming) => ({
                      id: alterWorktiming?.work_timing?.id,
                      name: alterWorktiming?.work_timing?.name,
                    })
                  );

                normalizedOptions.push({
                  // id: applicable?.ScheduleDetail?.WorkTiming?.id,
                  id: applicable?.id,
                  name: !!applicable?.employeeHasShift // employee signed in
                    ? [
                        {
                          id: applicable?.id,
                          name: applicable?.ScheduleDetail?.WorkTiming?.name,
                        },
                        ...(alternativeWorktimings ?? []),
                      ]?.find(
                        (workTiming) =>
                          workTiming?.id == applicable?.employeeHasShift
                      )?.name
                    : applicable?.ScheduleDetail?.WorkTiming?.name,
                  work_timing_id: applicable?.ScheduleDetail?.WorkTiming?.id,
                  typename: applicable?.__typename,
                  alternativeWorktimings,
                  employeeHasShift: applicable?.employeeHasShift,
                });
                break;
              case "Exception":
                normalizedOptions.push({
                  id: applicable?.id,
                  name: applicable?.normalWorkTiming?.name,
                  typename: applicable?.__typename,
                });
                break;
              default:
                return;
            }
          });

          resolve(normalizedOptions);
        },
        onError: (error) => {
          reject(error);
        },
      });
    });
  };

  useEffect(() => {
    props.onInputResetAction(formName, "action_type");
    setIsSubmiting(false);
    setRequestFiles([]);

    return () => {
      setWorKTimingsOptions([]);
      props.onInputResetAction(formName, "requestFrom");
      props.onInputResetAction(formName, "requestTo");
    };
  }, [isModalVissible]);

  // initail vlaue for requestFiles set to empty array

  // function to add object to requestFiles array
  const addRequestFileObject = (file) => {
    setRequestFiles((requestFiles) => [...requestFiles, file]);
  };

  // function to remove an object from an requestFiles array
  const removeRequestFileObject = (id) => {
    setRequestFiles((requestFiles) =>
      requestFiles.filter((file) => file?.id !== id)
    );
  };

  // handle request image change
  const handleImageChange = ({ file, meta }, status) => {
    // to convert image to base64 string
    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      let base64 = reader.result?.split(",");
      let fileType = file?.type?.split("/");

      if (status == "done") {
        let target = {
          file: base64[1],
          extension: fileType[1],
          id: meta?.id,
        };
        addRequestFileObject(target);
      }
    };

    if (status == "removed") {
      removeRequestFileObject(meta?.id);
    }
    return;
  };

  // handel text area change
  const handleTextArea = (e) => {
    props.onInputChangeAction(formName, e);
  };

  const showWorkTimingsMenu =
    (action_type == REQUESTS.NORMAL_HALF_DAY ||
      action_type == REQUESTS.UNPAID_HALF_DAY ||
      action_type == REQUESTS.PERMISSIONS) &&
    requestable?.alternativeWorktimings?.length > 0 &&
    !!!requestable?.employeeHasShift;

  const handleModalTypes = () => {
    switch (props?.data?.action_type) {
      case REQUESTS.NORMAL_LEAVES:
        // case REQUESTS.EMERGENCY_LEAVES:
        return (
          <div className="row align-items-start">
            {reqFlags?.isEmgNormal ? (
              <div className="col-sm-6 mt-2">
                <CheckboxBooleanForm
                  {...FormProps}
                  options={["Treat as an emergency"]}
                  name="deducted_from_emergency"
                  type="checkbox"
                  reducer="user"
                />
              </div>
            ) : null}
          </div>
        );
      case REQUESTS.UNPAID_LEAVES:
        return null;

      case REQUESTS.HOME:
        return (
          <div className="row align-items-start">
            {employeeRelevantAttendanceTypeConfiguration?.workRemotelySettings
              ?.can_ex_days == 1 ? (
              <>
                <div className="col-sm-6">
                  <BSelect
                    {...FormProps}
                    name="home_day"
                    placeholder="In exchange for"
                    options={employeeRelevantAttendanceTypeConfiguration?.workRemotelySettings?.home_days
                      ?.split(",")
                      ?.map((day) => ({ value: day }))}
                    optionLabel="value"
                    optionValue="value"
                    getOptionLabel={(option) => t(option.value?.toLowerCase())}
                    validateBy="textRequired"
                    keepDefaultStyle
                    dependOn="action_type"
                    reducer="user"
                    icon="calendar"
                  />
                </div>
              </>
            ) : null}
          </div>
        );

      case REQUESTS.NORMAL_HALF_DAY:
        // case REQUESTS.EMERGENCY_HALF_DAY:
        return (
          <>
            <div className="row align-items-start">
              <div className="col-sm-6">
                <BSelect
                  {...FormProps}
                  name="half_day_will_attend"
                  placeholder={t("Which Half Would You Attend")}
                  options={Constants.HalfDay}
                  optionLabel="label"
                  optionValue="value"
                  validateBy="textRequired"
                  formSubmitting={isSubmiting}
                  keepDefaultStyle
                  reducer="user"
                  icon="calendar"
                />
              </div>
              {reqFlags?.isEmgHalf ? (
                <div className="col-sm-6 mt-2">
                  <CheckboxBooleanForm
                    {...FormProps}
                    options={["Treat as an emergency"]}
                    name="deducted_from_emergency"
                    type="checkbox"
                    reducer="user"
                  />
                </div>
              ) : null}
            </div>
          </>
        );
      case REQUESTS.UNPAID_HALF_DAY:
        return (
          <>
            <div className="row align-items-start">
              <div className="col-sm-6">
                <BSelect
                  {...FormProps}
                  name="half_day_will_attend"
                  placeholder={t("Which Half Would You Attend")}
                  options={Constants.HalfDay}
                  optionLabel="label"
                  optionValue="value"
                  validateBy="textRequired"
                  formSubmitting={isSubmiting}
                  keepDefaultStyle
                  reducer="user"
                  icon="calendar"
                />
              </div>
            </div>
          </>
        );
      case REQUESTS.PERMISSIONS:
        return (
          <div className="row align-items-start">
            <div className="col-sm-6">
              {handleDateTimePickerForm(
                isSubmiting,
                "from",
                "from",
                "dateTime",
                "YYYY-MM-DD HH:mm",
                "from"
              )}
            </div>
            <div className="col-sm-6">
              {handleDateTimePickerForm(
                isSubmiting,
                "to",
                "to",
                "dateTime",
                "YYYY-MM-DD HH:mm",
                "to"
              )}
            </div>
          </div>
        );

      case REQUESTS.EXPENSE_CLAIM:
        return (
          <div>
            <div className="row">
              <div className="col-12">
                <InputForm
                  name="amount"
                  label={"amount"}
                  labelStyle="mb-2"
                  placeholder={t("amount")}
                  reducer="user"
                  {...FormProps}
                  validationName={`input.amount`}
                  formServerValidation={userFormServerValidation}
                  formSubmitting={isSubmiting}
                  containerStyle=""
                  inputContainerStyle="w-100"
                  type="number"
                  validateBy="textRequired"
                />
              </div>
            </div>

            <div className="row my-2">
              <div
                className={`col-12 ${
                  "input.reason" in props?.userServerVaildation
                    ? "invalid-container-style"
                    : ""
                }`}
              >
                <label className="mb-2 validity-label-style">
                  {t("reason")}
                </label>
                <textarea
                  rows="4"
                  name="reason"
                  placeholder={t("state reason")}
                  onChange={handleTextArea}
                  value={props?.attendanceActionModal?.reason}
                />

                <span className="validity-msg-style">
                  {"input.reason" in props?.userServerVaildation
                    ? props?.userServerVaildation["input.reason"]
                    : null}
                </span>
              </div>
            </div>
          </div>
        );
      case REQUESTS.IGNORE_ASSIGNMENT:
        return (
          <>
            {assignmentsOptions?.length > 1 ? (
              <BSelect
                {...FormProps}
                name="assignment_id"
                label={t("assignment")}
                labelStyle="mb-2"
                keepDefaultStyle
                placeholder={t("select option")}
                options={assignmentsOptions}
                validateBy="textRequired"
                formSubmitting={isSubmiting}
                reducer="user"
                isLoading={fetchRelevantAssignmentsLoading}
              />
            ) : null}
          </>
        );
      case REQUESTS.BEREAVEMENT:
      case REQUESTS.MATERNITY_PATERNITY_LEAVE:
      case REQUESTS.HAJJ_LEAVE:
      case REQUESTS.STUDY_LEAVE:
      case REQUESTS.SABBATICAL_LEAVE:
      case REQUESTS.MARRIAGE_LEAVE:
      case REQUESTS.In_Lieu_Of_Work_Day_Leave:
      case REQUESTS.MILITARY_CALL_LEAVE:
        return (
          <div>
            <div className="row align-items-start mb-2"></div>

            <div>
              <CheckboxBooleanForm
                {...FormProps}
                formSubmitting={isSubmiting}
                options={[
                  "Include Holidays and Weekends When Calculating Leave Durations",
                ]}
                name="include_weekends_and_holidays_in_custom_requests"
                validationName="input.include_weekends_and_holidays_in_custom_requests"
                type="checkbox"
                reducer="user"
              />

              <RadioboxForm
                reducer="user"
                {...FormProps}
                formSubmitting={isSubmiting}
                containerStyle="custom-tardiness-container justify-content-start"
                // labelStyle="d-flex mr-3"
                optionsContainerStyle="penalty-days-style fit-width flex-md-row"
                optionItemStyle="d-flex align-items-center mr-2"
                optionLabelStyle="mb-0 mr-3"
                optionInputStyle=" "
                // label="deduct from"
                options={[
                  { label: "Paid", value: "paid" },
                  { label: "Unpaid", value: "unpaid" },
                ]}
                validateBy="textRequired"
                name="payment_flag"
                validationName="input.payment_flag"
              />
            </div>
          </div>
        );
      case REQUESTS.CHANGE_SHIFT:
      case REQUESTS.ADDITIONAL_SHIFT:
        return (
          <BSelect
            {...FormProps}
            name="work_timing_id"
            label={t("Work timing")}
            labelStyle="mb-2 mt-3"
            keepDefaultStyle
            placeholder={t("select option")}
            options={worKTimingsOptions}
            validateBy="textRequired"
            formSubmitting={isSubmiting}
            reducer="user"
            isLoading={fetchWorkTimingsLoading}
            isDisabled={fetchWorkTimingsLoading}
          />
        );
      case REQUESTS.REQUEST_LOAN:
        return (
          <div className="w-100 ">
            <div className="row w-100 ">
              <div className="col-6">
                <InputForm
                  name="name"
                  label={"name"}
                  labelStyle="mb-2"
                  placeholder={t("name")}
                  reducer="user"
                  {...FormProps}
                  validationName={`input.name`}
                  formServerValidation={userFormServerValidation}
                  formSubmitting={isSubmiting}
                  containerStyle=""
                  inputContainerStyle="w-100"
                  type="text"
                  validateBy="textRequired"
                />
              </div>

              <div className="col-6">
                <InputForm
                  name="amount"
                  label={"amount"}
                  labelStyle="mb-2"
                  placeholder={t("amount")}
                  reducer="user"
                  {...FormProps}
                  validationName={`input.amount`}
                  formServerValidation={userFormServerValidation}
                  formSubmitting={isSubmiting}
                  containerStyle=""
                  inputContainerStyle="w-100"
                  type="number"
                  validateBy="textRequired"
                />
              </div>
            </div>

            <div className={`container-style-default w-100 row`}>
              <div className=" col-12">
                <InputForm
                  name="notes"
                  label={"notes"}
                  labelStyle="mb-2"
                  placeholder={t("notes")}
                  reducer="user"
                  {...FormProps}
                  validationName={`input.notes`}
                  formServerValidation={userFormServerValidation}
                  formSubmitting={isSubmiting}
                  containerStyle=""
                  inputContainerStyle="w-100"
                  type="text"
                  isMultiLines
                />
              </div>
            </div>
          </div>
        );

      default:
        return;
    }
  };

  const applicableFor =
    props?.data?.action_type === REQUESTS.NORMAL_LEAVES ||
    props?.data?.action_type === REQUESTS.UNPAID_LEAVES ||
    props?.data?.action_type === REQUESTS.SICK_LEAVES ||
    props?.data?.action_type === REQUESTS.BEREAVEMENT ||
    props?.data?.action_type === REQUESTS.MATERNITY_PATERNITY_LEAVE ||
    props?.data?.action_type === REQUESTS.HAJJ_LEAVE ||
    props?.data?.action_type === REQUESTS.STUDY_LEAVE ||
    props?.data?.action_type === REQUESTS.SABBATICAL_LEAVE ||
    props?.data?.action_type === REQUESTS.In_Lieu_Of_Work_Day_Leave ||
    props?.data?.action_type === REQUESTS.MILITARY_CALL_LEAVE ||
    props?.data?.action_type === REQUESTS.MARRIAGE_LEAVE
      ? "Leave"
      : props?.data?.action_type === REQUESTS.PERMISSIONS
      ? "Permission"
      : props?.data?.action_type === REQUESTS.HOME
      ? "Home"
      : "HalfDay";

  const handleFetchApplicablesThenSubmit = async (requestFunction) => {
    if (
      // not a half day case
      (action_type != REQUESTS.NORMAL_HALF_DAY &&
        action_type != REQUESTS.UNPAID_HALF_DAY &&
        action_type != REQUESTS.PERMISSIONS &&
        requestable !== null) ||
      // half day case
      ((action_type == REQUESTS.NORMAL_HALF_DAY ||
        action_type == REQUESTS.UNPAID_HALF_DAY ||
        action_type == REQUESTS.PERMISSIONS) &&
        requestable !== null &&
        (requestable?.alternativeWorktimings?.length == 0 ||
          (requestable?.alternativeWorktimings?.length > 0 &&
            (requestable?.employeeHasShift ||
              (!!!requestable?.employeeHasShift && work_timing_id !== null)))))
    ) {
      setIsSubmiting(true);
      requestFunction(requestable);
      return;
    }

    if (
      Constants.possibleDateRangeRequests.includes(action_type) &&
      moment(requestFrom).format("YYYY-MM-DD") !==
        moment(requestTo).format("YYYY-MM-DD")
    ) {
      setIsSubmiting(true);
      requestFunction(null);
      return;
    }

    try {
      const applicables = await fetchApplicableWorkTimingsFunc(
        requestFrom,
        applicableFor
      );

      if (applicables?.length === 0) {
        setIsSubmiting(true);
        setGeneralExceptionMessage(
          "There are no shifts that you can submit this request on"
        );
        return;
      }

      if (applicables?.length > 1) {
        setApplicableWorkTimings(applicables);
        return;
      }

      if (applicables?.length === 1) {
        // if it's a half day request and there're alternative worktimings don't submit and show them
        if (
          (action_type == REQUESTS.NORMAL_HALF_DAY ||
            action_type == REQUESTS.UNPAID_HALF_DAY ||
            action_type == REQUESTS.PERMISSIONS) &&
          applicables?.[0]?.alternativeWorktimings?.length > 0
        ) {
          setRequestable(applicables?.[0]);
          return;
        }

        setIsSubmiting(true);
        requestFunction(applicables[0]);
        return;
      }
    } catch (error) {
      setRequestable(null);
      setApplicableWorkTimings([]);
      setGeneralExceptionMessage(error?.message ?? t("something went wrong"));
    }
  };

  const handleNormalLeaveRequest = (requestable) => {
    props.normalRequestActionAttempt(
      requestFrom,
      requestTo,
      !!props?.data?.deducted_from_emergency,
      isEmployee ? null : employeeId,
      requestFrom && requestTo && requestFrom === requestTo
        ? {
            requestableId: requestable?.id,
            requestableType: requestable?.typename,
          }
        : null,
      serializedAttachemnts ?? [],
      isEmployee ? employeeRefetchQueries : managerRefetchQueries
    );
  };

  const handleUnpaidLeaveRequest = (requestable) => {
    props.unpaidRequestActionAttempt(
      requestFrom,
      requestTo,
      isEmployee ? null : employeeId,
      requestFrom && requestTo && requestFrom === requestTo
        ? {
            requestableId: requestable?.id,
            requestableType: requestable?.typename,
          }
        : null,
      serializedAttachemnts,
      isEmployee ? employeeRefetchQueries : managerRefetchQueries
    );
  };

  const handlePermissionRequest = (requestable) => {
    props.permissionRequestActionAttempt(
      from,
      to,
      isEmployee ? null : employeeId,
      moment(requestFrom).format("YYYY-MM-DD"),
      requestable,
      serializedAttachemnts,
      isEmployee ? employeeRefetchQueries : managerRefetchQueries,
      requestable?.alternativeWorktimings?.length == 0
        ? requestable?.work_timing_id
        : requestable?.employeeHasShift
        ? requestable?.employeeHasShift
        : work_timing_id
    );
  };

  const handleNormalHalfDay = (requestable) => {
    props.halfdayRequestActionAttempt(
      requestFrom,
      props?.data?.half_day_will_attend,
      !!props?.data?.deducted_from_emergency,
      isEmployee ? null : employeeId,
      requestable,
      serializedAttachemnts,
      isEmployee ? employeeRefetchQueries : managerRefetchQueries,
      requestable?.alternativeWorktimings?.length == 0
        ? requestable?.work_timing_id
        : requestable?.employeeHasShift
        ? requestable?.employeeHasShift
        : work_timing_id
    );
  };

  const handleHomeRequest = (requestable) => {
    if (
      employeeRelevantAttendanceTypeConfiguration?.workRemotelySettings
        ?.can_ex_days
    ) {
      return props.homeExchangeRequestActionAttempt(
        requestFrom,
        props?.data?.home_day,
        isEmployee ? null : employeeId,
        requestable,
        serializedAttachemnts,
        isEmployee ? employeeRefetchQueries : managerRefetchQueries
      );
    } else {
      return props.homeFlexRequestActionAttempt(
        requestFrom,
        isEmployee ? null : employeeId,
        requestable,
        serializedAttachemnts,
        isEmployee ? employeeRefetchQueries : managerRefetchQueries
      );
    }
  };

  const handleSickLeaveRequest = (requestable) => {
    // to send file and extenstion only without id key in the array

    props.upsertSickRequestAction({
      ref: "attendanceModalActions",
      formName,
      allData: {
        ...props?.data,
        employee_id: isEmployee ? null : employeeId,
        time_from: requestFrom,
        time_to: requestTo,
        attachments: serializedAttachemnts,
        requestable:
          requestFrom && requestTo && requestFrom === requestTo
            ? {
                requestableId: requestable?.id,
                requestableType: requestable?.typename,
              }
            : null,
      },
      refetchQueries: isEmployee
        ? employeeRefetchQueries
        : managerRefetchQueries,
    });
  };

  const handleUnpaidHalfDay = (requestable) => {
    submitUnpaidHalfDay({
      variables: {
        input: {
          employeeId: employeeId,
          requestDay: moment(requestFrom).format("YYYY-MM-DD"),
          addInfo: half_day_will_attend?.toLowerCase(),
          requestable: {
            requestableId: requestable?.id,
            requestableType: requestable?.typename,
            workTimingId:
              requestable?.alternativeWorktimings?.length == 0
                ? requestable?.work_timing_id
                : requestable?.employeeHasShift
                ? requestable?.employeeHasShift
                : work_timing_id,
          },
          files: serializedAttachemnts,
        },
      },
    });
  };

  const handleCustomLeaveRequest = (requestable) => {
    dispatch(
      upsertCustomRequestAction({
        ref: "attendanceModalActions",
        formName,
        allData: {
          employee_id: employeeId,
          ...props.data,
          files: serializedAttachemnts,
          from: requestFrom,
          to: requestTo,
          request_type_id: action_type,
          requestable:
            requestFrom && requestTo && requestFrom === requestTo
              ? {
                  requestableId: requestable?.id,
                  requestableType: requestable?.typename,
                }
              : null,
        },
        refetchQueries: isEmployee
          ? employeeRefetchQueries
          : managerRefetchQueries,
      })
    );
  };

  // submitting mutation
  const [
    requestChangeShift,
    { loading: changeShiftLoading, error: changeShiftError },
  ] = useMutation(requestChangeShiftMutation, {
    onCompleted: (res) => {
      if (
        res?.change_shift_request?.__typename === "GeneralException" ||
        res?.change_shift_request?.__typename === "PaidPayrollException"
      ) {
        return setGeneralExceptionMessage(
          res?.change_shift_request?.message ?? ""
        );
      }
      if (res?.change_shift_request?.request?.id) {
        handleRefetchingIncaseOfSuccess();
        dispatch(showSuccessToast("Request sent"));
      } else {
        dispatch(showErrorToast());
      }
    },
    refetchQueries: isEmployee ? employeeRefetchQueries : managerRefetchQueries,
    onError: (error) => {
      if (error?.message === "swal") {
        swal({
          title: t("are you sure"),
          text: t("You have other requests on this day"),
          icon: "warning",
          className: "swal-warning-style",
          dangerMode: true,
          buttons: true,
          className: "swal-warning-style",
          buttons: [t("Cancel"), t("confirm")],
        }).then((confirm) => {
          if (confirm) {
            requestChangeShift({
              variables: {
                input: {
                  employeeId: employeeId,
                  date: requestFrom,
                  workTimeId: props?.data.work_timing_id,
                  files: serializedAttachemnts,
                  swalValue: true,
                },
              },
            });
          }
        });
      } else dispatch(showErrorToast(error?.message));
    },
  });

  const handleChangeShift = () => {
    requestChangeShift({
      variables: {
        input: {
          is_change_shift: true,
          employeeId: employeeId,
          date: requestFrom,
          workTimeId: props?.data.work_timing_id,
          files: serializedAttachemnts,
        },
      },
    });
  };

  const handleAdditionalShift = () => {
    requestChangeShift({
      variables: {
        input: {
          is_change_shift: false,
          employeeId: employeeId,
          date: requestFrom,
          workTimeId: props?.data.work_timing_id,
          files: serializedAttachemnts,
        },
      },
    });
  };

  const [submitLoanRequest, { loading: loanRequestLoading }] = useMutation(
    requestLoanMutation,
    {
      onCompleted: (data) => {
        if (data?.upsert_loan_request?.__typename === "GeneralException") {
          return setGeneralExceptionMessage(
            data?.upsert_loan_request?.message ?? ""
          );
        }

        if (data?.upsert_loan_request?.id) {
          handleRefetchingIncaseOfSuccess();
          dispatch(showSuccessToast("Request sent"));
        } else {
          dispatch(showErrorToast());
        }
      },
      onError: (error) => {
        dispatch(
          showErrorToast(
            error?.graphQLErrors[0]?.extensions?.reason ||
              error?.graphQLErrors[0]?.message ||
              error?.message
          )
        );
      },
      refetchQueries: isEmployee
        ? employeeRefetchQueries
        : managerRefetchQueries,
    }
  );

  const handleLoanRequest = () => {
    submitLoanRequest({
      variables: {
        input: {
          emp_id: employeeId,
          amount: parseFloat(props?.data.amount),
          name: props?.data.name,
          notes: props?.data.notes,
        },
      },
    });
  };

  const handleSubmitBtn = () => {
    if (
      props?.attendanceValidation?.length &&
      !(
        props?.attendanceValidation[0] === "work_timing_id" &&
        ![REQUESTS.CHANGE_SHIFT, REQUESTS.ADDITIONAL_SHIFT].includes(
          action_type
        )
      )
    ) {
      setIsSubmiting(true);
      return;
    }

    setGeneralExceptionMessage("");

    switch (props?.data?.action_type) {
      case REQUESTS.NORMAL_LEAVES:
        handleFetchApplicablesThenSubmit(handleNormalLeaveRequest);
        return;

      case REQUESTS.UNPAID_LEAVES:
        handleFetchApplicablesThenSubmit(handleUnpaidLeaveRequest);
        return;

      case REQUESTS.EMERGENCY_LEAVES:
        return props.emergencyRequestActionAttempt(requestFrom, requestTo);

      case REQUESTS.PERMISSIONS:
        handleFetchApplicablesThenSubmit(handlePermissionRequest);
        return;

      case REQUESTS.NORMAL_HALF_DAY:
        handleFetchApplicablesThenSubmit(handleNormalHalfDay);
        return;

      case REQUESTS.UNPAID_HALF_DAY:
        handleFetchApplicablesThenSubmit(handleUnpaidHalfDay);
        return;

      case REQUESTS.HOME:
        handleFetchApplicablesThenSubmit(handleHomeRequest);
        return;

      case REQUESTS.EXPENSE_CLAIM:
        // to send file and extenstion only without id key in the array

        return props.upsertClaimsRequestAction({
          ref: "attendanceModalActions",
          formName,
          allData: {
            ...props?.data,
            attachments: serializedAttachemnts,
            employee_id: isEmployee ? null : employeeId,
          },
          refetchQueries: isEmployee
            ? employeeRefetchQueries
            : managerRefetchQueries,
        });

      case REQUESTS.SICK_LEAVES:
        handleFetchApplicablesThenSubmit(handleSickLeaveRequest);
        return;

      case REQUESTS.IGNORE_ASSIGNMENT:
        return handleIgnoreException();

      case REQUESTS.BEREAVEMENT:
      case REQUESTS.MATERNITY_PATERNITY_LEAVE:
      case REQUESTS.HAJJ_LEAVE:
      case REQUESTS.STUDY_LEAVE:
      case REQUESTS.SABBATICAL_LEAVE:
      case REQUESTS.MARRIAGE_LEAVE:
      case REQUESTS.In_Lieu_Of_Work_Day_Leave:
      case REQUESTS.MILITARY_CALL_LEAVE:
        handleFetchApplicablesThenSubmit(handleCustomLeaveRequest);
        return;

      case REQUESTS.CHANGE_SHIFT:
        handleChangeShift();
        return;

      case REQUESTS.ADDITIONAL_SHIFT:
        handleAdditionalShift();
        return;

      case REQUESTS.DAY_OFF:
        handleDayOff();
        return;

      case REQUESTS.REQUEST_LOAN:
        handleLoanRequest();
        return;

      default:
        return;
    }
  };

  const [getAvailableRequests, { loading, data }] = useLazyQuery(
    GET_AVAILABLE_REQUESTS
  );

  const employeeRelevantAttendanceTypeConfiguration =
    data?.employee?.relevantAttendanceTypeConfiguration;

  const handleGetAvailableRequests = (date) => {
    (!props?.hasEmpInput || (props?.hasEmpInput && clientEmployee?.id)) &&
      getAvailableRequests({
        variables: {
          employeeID: employeeId,
          date: date.format("YYYY-MM-DD"),
        },
        onCompleted: ({ availableRequests = [] }) => {
          const { availableRequestsOptions, reqFlags } = getReqData(
            availableRequests || []
          );

          setSelectTypesOptions(availableRequestsOptions);
          setReqFlags(reqFlags);
        },
        onError: (err) => {
          setSelectTypesOptions([]);
          setReqFlags({});
          showToast(
            "error",
            err?.graphQLErrors[0]?.extensions?.reason ||
              err?.graphQLErrors[0]?.message ||
              err?.message
          );
        },
      });
  };
  const [reqFlags, setReqFlags] = useState({});
  const [selectTypesOptions, setSelectTypesOptions] = useState([]);

  const shouldRenderApplicableWorkTimings =
    (action_type === REQUESTS.PERMISSIONS ||
      action_type === REQUESTS.HOME ||
      action_type === REQUESTS.NORMAL_HALF_DAY ||
      action_type === REQUESTS.UNPAID_HALF_DAY ||
      action_type === REQUESTS.UNPAID_LEAVES ||
      action_type === REQUESTS.NORMAL_LEAVES ||
      action_type === REQUESTS.SICK_LEAVES ||
      action_type === REQUESTS.BEREAVEMENT ||
      action_type === REQUESTS.MATERNITY_PATERNITY_LEAVE ||
      action_type === REQUESTS.HAJJ_LEAVE ||
      action_type === REQUESTS.STUDY_LEAVE ||
      action_type === REQUESTS.SABBATICAL_LEAVE ||
      action_type === REQUESTS.In_Lieu_Of_Work_Day_Leave ||
      action_type === REQUESTS.MILITARY_CALL_LEAVE ||
      action_type === REQUESTS.MARRIAGE_LEAVE) &&
    applicableWorkTimings?.length > 1;

  const requestFromLabel = () => {
    switch (action_type) {
      case REQUESTS.NORMAL_LEAVES:
      case REQUESTS.SICK_LEAVES:
      case REQUESTS.UNPAID_LEAVES:
      case REQUESTS.BEREAVEMENT:
      case REQUESTS.MATERNITY_PATERNITY_LEAVE:
      case REQUESTS.HAJJ_LEAVE:
      case REQUESTS.STUDY_LEAVE:
      case REQUESTS.SABBATICAL_LEAVE:
      case REQUESTS.MARRIAGE_LEAVE:
      case REQUESTS.In_Lieu_Of_Work_Day_Leave:
      case REQUESTS.MILITARY_CALL_LEAVE:
        return "from";
      case REQUESTS.EXPENSE_CLAIM:
        return "incurred at";
      default:
        return "date";
    }
  };

  const removeAllSelectedFiles = (_, allFiles) => {
    allFiles?.forEach((f) => f?.remove());
  };

  return (
    <MainModal
      isOpen={isModalVissible}
      toggle={props.toggleAttendanceActionModal}
      modalTitle={t("new request")}
      btnOnClick={handleSubmitBtn}
      btnSubmitLoading={
        +isLoading ||
        ignoreExceptionLoading ||
        unpaidHalfDayLoading ||
        fetchApplicableWorkTimingsLoading ||
        changeShiftLoading ||
        dayOffLoading ||
        loanRequestLoading
      }
      btnLabel={t("save")}
      disableSubmitButton={fetchApplicableWorkTimingsLoading}
    >
      {props?.hasEmpInput ? (
        <BSelect
          onChange={(val) => setClientEmployee(val)}
          icon="user"
          labelStyle="mb-2"
          containerStyle="mb-2"
          label={t("employee")}
          value={clientEmployee}
          isLoading={empsLLoading}
          options={emps?.users_by_role?.data || []}
        />
      ) : null}

      <div className="d-flex align-items-center">
        {props?.data?.action_type !== REQUESTS.REQUEST_LOAN && (
          <DateTimePickerForm
            {...FormProps}
            name="requestFrom"
            label={requestFromLabel()}
            placeholder="select date"
            // validateBy="textRequired"
            formSubmitting={isSubmiting}
            containerStyle="my-1"
            inputContainerStyle=" "
            reducer="user"
            rootStyle="w-100 mb-3 mr-3"
            requestFormat="YYYY-MM-DD"
            validationName={
              action_type == REQUESTS.EXPENSE_CLAIM
                ? "input.incurred_at"
                : "input.from"
            }
            formServerValidation={userFormServerValidation}
            onIntercept={handleGetAvailableRequests}
            disabled={props?.hasEmpInput && !clientEmployee?.id}
          />
        )}

        {Constants.possibleDateRangeRequests.includes(action_type) ? (
          <DateTimePickerForm
            {...FormProps}
            name="requestTo"
            label="to"
            placeholder="select date"
            validateBy="textRequired"
            formSubmitting={isSubmiting}
            containerStyle="my-1"
            inputContainerStyle=" "
            reducer="user"
            rootStyle="w-100 mb-3 ml-3"
            requestFormat="YYYY-MM-DD"
            // validationName="input.requestFrom"
            // formServerValidation={userFormServerValidation}
          />
        ) : null}
      </div>
      {/* (Start) Type Select */}

      <div className="mb-2">
        <BSelect
          {...FormProps}
          name="action_type"
          label={t("request type")}
          labelStyle="mb-2"
          keepDefaultStyle
          placeholder={t("select option")}
          options={loading ? [] : selectTypesOptions}
          // optionValue="value"
          validateBy="textRequired"
          formSubmitting={isSubmiting}
          reducer="user"
          // getOptionLabel={(option) => t(option.label?.toLowerCase())}
          icon="type"
          isLoading={loading}
          isDisabled={loading}
        />
      </div>

      {/* (End) Type Select */}

      {/* (Start) Modal Content */}
      {handleModalTypes()}

      {!!props?.data?.action_type &&
      props?.data?.action_type !== REQUESTS.REQUEST_LOAN ? (
        <div className="row">
          <div
            className={`col-12 mt-1 documents_dropzone_style ${
              "input.attachments" in props?.userServerVaildation
                ? "invalid-container-style"
                : ""
            }`}
          >
            <label className="mb-2 validity-label-style">{t("files")}</label>
            <Dropzone
              onChangeStatus={handleImageChange}
              canRemove
              accept="image/*"
              name="attachments"
              multiple={true}
              inputContent={t("face_placeholder")}
              onSubmit={removeAllSelectedFiles}
            />
            <span className="validity-msg-style">
              {"input.attachments" in props?.userServerVaildation
                ? props?.userServerVaildation["input.attachments"]
                : null}
            </span>
          </div>
        </div>
      ) : null}
      {/* (End) Modal Content */}

      {/* applicable work timings for requesting */}
      {shouldRenderApplicableWorkTimings ? (
        <div className="col-12 px-0">
          <BSelect
            {...FormProps}
            name="requestable"
            label={t("requestable")}
            labelStyle="mb-2"
            keepDefaultStyle
            placeholder={t("select option")}
            options={applicableWorkTimings}
            validateBy="textRequired"
            formSubmitting={isSubmiting}
            reducer="user"
            isLoading={fetchApplicableWorkTimingsLoading}
            onChange={(value) => setRequestable(value)}
            value={requestable}
          />
        </div>
      ) : null}

      {showWorkTimingsMenu ? (
        <BSelect
          {...FormProps}
          label="work timing"
          name="work_timing_id"
          placeholder={t("Which Half Would You Attend")}
          options={[
            { id: requestable?.work_timing_id, name: requestable?.name },
            ...(requestable?.alternativeWorktimings ?? []),
          ]}
          validateBy="textRequired"
          formSubmitting={isSubmiting}
          keepDefaultStyle
          reducer="user"
          icon="calendar"
        />
      ) : null}

      {/* (Start) Form server validation message */}
      <span className="warnig-msg-style">
        {props.userServerVaildation[`input.description`] ?? " "}
      </span>
      {/* (End) Form server validation message */}

      {/* (Start) Error Message */}
      {modalMessage && isSubmiting && (
        <div className="warnig-msg-style">{modalMessage}</div>
      )}
      {/* (End) Error Message */}
      {ignoreAssignmentExceptionMessage?.length ? (
        <div className="invalid-container-style">
          <p className="mb-0 mt-2 validity-msg-style">
            {ignoreAssignmentExceptionMessage}
          </p>
        </div>
      ) : null}
      <div className="invalid-container-style">
        <div className="validity-msg-style">{generalExceptionMessage}</div>
      </div>
    </MainModal>
  );
};

const mapStateToProps = (state) => ({
  data: state.user.attendanceActionModal,
  attendanceValidation: state.user.attendanceActionModalValidation,
  modalActions: state.user.attendanceModalActions,
  userProfile: state.user.userProfile,
  userServerVaildation: state.user[userFormServerValidation],
});

export default connect(mapStateToProps, {
  onInputResetAction,
  normalRequestActionAttempt,
  unpaidRequestActionAttempt,
  emergencyRequestActionAttempt,
  attendanceActionModalFailed,
  permissionRequestActionAttempt,
  halfdayRequestActionAttempt,
  homeExchangeRequestActionAttempt,
  homeFlexRequestActionAttempt,
  toggleAttendanceActionModal,
  upsertClaimsRequestAction,
  onInputChangeAction,
  upsertSickRequestAction,
})(AttendanceActionModal);
