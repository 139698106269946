import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { InputForm } from "../../Builder/Form";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAccessLevelsAttempt,
  onFormResetAction,
  fetchPlanPrivilegesAction,
  dismissAddNewAccessLevelModalAction,
  editRolePrivilegesAttempt,
  upsertRoleToCompanyAttempt,
  deleteCompanyRoleAttmpet,
  fetchRolePresetPrivilegesAction,
} from "../../Store/Actions";
import Loader from "../../Components/Loader";
import DataTable from "react-data-table-component";
import { Spinner } from "reactstrap";
import MainModal from "../../Components/MainModal";
import swal from "sweetalert";
import Select from "react-select";
import RolePrivilegesTreeView from "../PlanRoleModal/RolePrivilegesTreeView";
import {
  EditIconButton,
  RemoveIconButton,
} from "../../Components/IconButtonWithTooltip";

const AccessLevels = () => {
  const formName = "upsertRoleToCompanyForm";
  const dataTableRef = "accessLevels";
  const formNameValidation = "accessLevelsFormValidation";
  const formServerValidation = "accessLevelsServerValidation";
  const [modifiyingState, setModifiyingState] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [presetRole, setpresetRole] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const planId = useSelector(
    (state) => state.auth.userProfile?.company?.activeSubscription?.plan?.id
  );

  const {
    accessLevels,
    fetchingAccessLevelsLoading,
    editRoleToCompanyLoading,
    upsertRoleToCompanyForm,
    upsertRoleToCompanyLoading,
    deleteCompanyRoleLoading,
    accessLevelsFormValidation,
    loadingRolePresetPrivileges,
    managePrivilagesModal,
    plan_privileges,
    loadingPlanPrivileges,
  } = useSelector((state) => state.super);

  useEffect(() => {
    dispatch(fetchAccessLevelsAttempt());
    dispatch(onFormResetAction(dataTableRef));
    return () => {
      dispatch(onFormResetAction(dataTableRef));
      dispatch(onFormResetAction("managePrivilagesModal"));
      setModifiyingState(null);
    };
  }, []);

  useEffect(() => {
    if (presetRole?.value) {
      dispatch(fetchRolePresetPrivilegesAction(presetRole?.value));
    }
  }, [presetRole]);

  useEffect(() => {
    if (!accessLevels.isModalVissible) {
      setIsSubmitting(false);
      setpresetRole(null);
      dispatch(onFormResetAction(formServerValidation));
      dispatch(onFormResetAction(formName));
      dispatch(onFormResetAction("managePrivilagesModal"));
    }
  }, [accessLevels.isModalVissible]);

  if (fetchingAccessLevelsLoading) {
    return <Loader />;
  }

  const renderAccessLevelsByFilter = () => {
    const { filter, data } = accessLevels;
    if (filter) {
      return data.filter((accessLevel) =>
        accessLevel.label.toLowerCase().includes(filter.toLowerCase())
      );
    } else {
      return data;
    }
  };

  const handleEdit = (role) => {
    if (loadingPlanPrivileges) {
      return;
    }
    let { value, label } = role;
    dispatch(editRolePrivilegesAttempt(planId, value, label));
    setModifiyingState(label);
  };

  const columns = [
    {
      name: t("name"),
      wrap: true,
      selector: "label",
      sortable: false,
      grow: 1,
    },
    {
      name: t("actions"),
      wrap: true,
      selector: "action",
      grow: 0.03,
      cell: (row) => {
        let roleType = row?.roleable?.type;
        if (roleType.toLowerCase() === "company") {
          return (
            <HasPrivileges
              reqireMain={[Privilages.ADD_EDIT_DELETE_NEW_ACCESS_LEVELS]}
              allowBP
            >
              <div className="cards_table_actions">
                <EditIconButton onClick={() => handleEdit(row)} />
                <RemoveIconButton onClick={() => handleDelete(row)} />
              </div>
            </HasPrivileges>
          );
        }
      },
      ignoreRowClick: true,
    },
  ];

  const handleAddNewBtn = () => {
    dispatch(fetchPlanPrivilegesAction(planId));
    setModifiyingState("create");
  };

  const modalTitle = () => {
    if (modifiyingState === "create") {
      return t("add new access level");
    } else {
      return `${t("edit access level")} - ${modifiyingState}`;
    }
  };

  const handleDelete = (row) => {
    if (loadingPlanPrivileges) {
      return;
    }
    let roleId = row.value;
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteCompanyRoleAttmpet(roleId));
      }
    });
  };

  const handleSubmit = () => {
    let { id, name } = upsertRoleToCompanyForm;
    let { main, all, managed } = managePrivilagesModal;
    setIsSubmitting(true);
    if (accessLevelsFormValidation.length > 0) {
      return;
    }
    if (!main.length && !all.length && !managed.length) {
      return;
    }
    let data = {
      id,
      name,
      privileges: main,
      PrivilegesWithScopeAll: all,
      PrivilegesWithScopeManaged: managed,
    };
    dispatch(upsertRoleToCompanyAttempt(data));
  };

  const handleCancel = () => {
    dispatch(dismissAddNewAccessLevelModalAction());
    setIsSubmitting(false);
  };

  return (
    <div>
      {editRoleToCompanyLoading || deleteCompanyRoleLoading ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}
      {/* filter and add new button start */}
      <div className="d-flex justify-content-between align-items-center my-3">
        <div className="col px-0">
          <InputForm
            name="filter"
            formName={dataTableRef}
            placeholder={t("search")}
            containerStyle=" "
            inputContainerStyle="search_container"
            inputStyle="search_input"
            icon="search"
          />
        </div>
        <HasPrivileges
          reqireMain={[Privilages.ADD_EDIT_DELETE_NEW_ACCESS_LEVELS]}
          allowBP
        >
          <div className="pl-3 text-right">
            <button
              className="btn add_new_btn_style"
              type="button"
              onClick={handleAddNewBtn}
              disabled={loadingPlanPrivileges}
            >
              {loadingPlanPrivileges ? (
                <>
                  <Spinner
                    style={{ width: "1rem", height: "1rem", color: "#fff" }}
                  />
                  <span className="mx-2 text-capitalize">{t("loading")}</span>
                </>
              ) : (
                <>
                  <FontAwesomeIcon className="mr-2" icon={faPlus} />
                  <span>{t("add new")}</span>
                </>
              )}
            </button>
          </div>
        </HasPrivileges>
      </div>
      {/* filter and add new button end */}
      <DataTable
        noDataComponent={<div className="p-4"> {t("no_records")} </div>}
        className="cards_table"
        columns={columns}
        data={renderAccessLevelsByFilter()}
        noHeader
        persistTableHead
      />

      <MainModal
        isOpen={accessLevels.isModalVissible}
        btnLabelCancel="Cancel"
        btnOnCancelClick={handleCancel}
        modalTitle={modalTitle()}
        btnOnClick={handleSubmit}
        btnSubmitLoading={upsertRoleToCompanyLoading}
        className="manage_privilages_modal_wrapper_style"
      >
        <InputForm
          name="name"
          formNameValidation={formNameValidation}
          formServerValidation={formServerValidation}
          formName={formName}
          formSubmitting={isSubmitting}
          validateBy="textRequired"
          validationName="input.name"
          placeholder={t("name")}
          inputStyle="form-control access-level-input"
          label={t("name")}
          labelStyle="text-nowrap mr-3 access-level-labels"
          containerStyle="d-flex align-items-center justify-content-start"
          rootStyle="px-custom-padding-1"
          inputContainerStyle="form-group mb-0 d-flex access-levels-inputs-container"
        />
        <div className="d-flex flex-row align-items-center px-3 py-1">
          <label className="access-level-labels mr-3">{t("copy from")}</label>
          <Select
            className={
              "select-def-input-containe b-select-style col-md-6 col-sm-12 access-levels-inputs-container px-0"
            }
            classNamePrefix={"b-select-style"}
            value={presetRole}
            onChange={(val) => setpresetRole(val)}
            isLoading={loadingRolePresetPrivileges}
            isClearable={true}
            isRtl={document.dir?.toLowerCase() == "rtl"}
            isSearchable
            id="role_preset"
            placeholder={t("select option")}
            options={accessLevels?.data}
          />
        </div>
        {loadingRolePresetPrivileges ? (
          <div className="loader_wrapper_style">
            <Loader />
          </div>
        ) : null}
        <RolePrivilegesTreeView
          companyLevel
          parents={JSON.parse(plan_privileges)}
        />
      </MainModal>
    </div>
  );
};

export default AccessLevels;
