import React from "react";
import { useTranslation } from "react-i18next";

import MainModal from "../MainModal";
import { Skeleton } from "@mui/material";
import { FaceIconRow, LocationIconRow, TimeIconRow } from "./shared";

const modalInitState = { isOpen: false, data: null };

const CheckLogs = ({ name, checkIns, onViewLocation }) => {
  const { t } = useTranslation();
  const [modalState, setModalState] = React.useState(modalInitState);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <strong className="dark-color px-2" style={{ fontSize: 14 }}>
          {t("Check-ins")} ({checkIns?.length})
        </strong>
        {checkIns?.length >= 3 ? (
          <button
            style={{ textDecoration: "underline" }}
            className="btn-link bg-none border-0 text-info"
            onClick={() =>
              setModalState({ data: { name, checkIns }, isOpen: true })
            }
          >
            Show All
          </button>
        ) : null}
      </div>

      {checkIns.map((check, idx) =>
        idx < 2 ? (
          <div
            key={idx}
            style={{ backgroundColor: "#eef9ff" }}
            className="px-2 flex-fill d-flex align-items-center gap-10 mb-1"
          >
            <Logs name={name} check={check} onViewLocation={onViewLocation} />
          </div>
        ) : null
      )}

      {modalState.isOpen ? (
        <Modal
          data={modalState.data}
          onClose={() => setModalState(modalInitState)}
        />
      ) : null}
    </>
  );
};

const Modal = ({ data, onClose }) => {
  const { t } = useTranslation();

  return (
    <MainModal
      isOpen
      size="lg"
      toggle={onClose}
      hasModalFooter={false}
      modalTitle={t("Check-ins")}
    >
      {data?.checkIns?.map((check, i) => (
        <div key={i} className="d-flex align-items-center gap-20 mb-2">
          <div className="flex-fill d-flex align-items-center justify-content-between gap-20">
            <Logs name={data.name} check={check} />
          </div>
        </div>
      ))}
    </MainModal>
  );
};

const Logs = ({ name, check, onViewLocation }) => (
  <>
    {/* In */}
    <Log
      isCheckIn
      name={name}
      lng={check?.check_in_lng}
      lat={check?.check_in_lat}
      time={check?.check_in_time}
      face={check?.checkInFace?.path}
      locationName={check?.checkInLocation?.name}
      faceSuccessRatio={check?.liveness_ratio_in}
      isMockedLocation={check?.mocked_location_in}
      onViewLocation={onViewLocation}
    />
    {/* Out */}
    <Log
      name={name}
      lng={check?.check_out_lng}
      lat={check?.check_out_lat}
      time={check?.check_out_time}
      face={check?.checkOutFace?.path}
      locationName={check?.checkOutLocation?.name}
      faceSuccessRatio={check?.liveness_ratio_out}
      isMockedLocation={check?.mocked_location_out}
      onViewLocation={onViewLocation}
    />
  </>
);

const Log = ({
  lng,
  lat,
  time,
  face,
  name,
  isCheckIn,
  locationName,
  faceSuccessRatio,
  isMockedLocation,
  onViewLocation,
}) => {
  const handleViewLocation = () => {
    onViewLocation({
      lng,
      lat,
      face,
      name,
      date: time,
      location_name: locationName,
      signinOutLocationsModal: false,
      checkType: isCheckIn ? "check_in" : "check_out",
    });
  };

  return (
    <div className="flex-fill d-flex gap-10 text-12">
      {time ? (
        <>
          <div className="d-flex gap-10 align-items-center">
            <TimeIconRow time={time} isIn={isCheckIn} />
            <FaceIconRow
              isFaceOnly
              face={face}
              faceSuccessRatio={faceSuccessRatio}
            />
          </div>
          <LocationIconRow
            locationName={locationName}
            isMockedLocation={isMockedLocation}
            handleViewLocation={handleViewLocation}
          />
        </>
      ) : (
        <Skeleton animation={false} height={20} className="w-100" />
      )}
    </div>
  );
};

export default CheckLogs;
