import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import NotFound from "../../Assets/img/notFound.png";
import NotFoundStatus from "../../Assets/img/404.png";

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <section className="statusPage">
      <h2 className="sr-only">{t("page not found")}</h2>

      <div className="wrapper">
        <div className="txt">
          <div className="statusImg">
            <img
              aria-hidden="true"
              className="img-fluid"
              src={NotFoundStatus}
              alt=""
            />
          </div>
          <p className="px-4">
            {t("the page is missing or you assembled the link incorrectly.")}
          </p>
          <Link to="/" className="btn btn-primary bg-main mx-4">
            {t("back to home")}
          </Link>
        </div>
        <div className="img">
          <img aria-hidden="true" className="img-fluid" src={NotFound} alt="" />
        </div>
      </div>
    </section>
  );
};

export default NotFoundPage;
