import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { resetLeavesBreaksForm } from "../../Store/Actions";

import { LeavesBreaksForm } from "../../Containers/LeavesBreaksForm";

// Ui
import MainModal from "../MainModal";

const LeavesBreaksModal = ({ setIsModalOpen, fetchLeavesBreaks }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const isEditing =
    useSelector((state) => state.super.leavesBreaksForm.id) == null;

  useEffect(() => {
    return () => dispatch(resetLeavesBreaksForm());
  }, []);

  return (
    <MainModal
      isOpen={true}
      toggle={() => setIsModalOpen(false)}
      className="leaves_breaks_modal"
      modalTitle={
        isEditing
          ? t("new leave and break profile")
          : t("edit leave and break profile")
      }
    >
      <LeavesBreaksForm
        setIsModalOpen={setIsModalOpen}
        fetchLeavesBreaks={fetchLeavesBreaks}
      />
    </MainModal>
  );
};

export default LeavesBreaksModal;
