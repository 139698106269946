import React from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import {
  importLeadsMutation,
  downloadCRMTemplateMutation,
} from "../../Graphql/mutation";
import HelperFns, { showToast } from "../../Helpers/HelperFns";

import MainModal from "../MainModal";
import { ColorButton } from "../Buttons";
import Dropzone from "react-dropzone-uploader";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const ImportLeadsModal = ({ onClose, refetchList }) => {
  const { t } = useTranslation();

  // Local State
  const [file, setFile] = React.useState(null);

  // Server State
  const [downloadTemplate, { loading: downloadTemplateLoading }] = useMutation(
    downloadCRMTemplateMutation
  );
  const [importLeads, { loading: importLoading }] =
    useMutation(importLeadsMutation);

  /* ↓ Helpers ↓ */

  const handleCLoseModal = () => {
    onClose();
  };

  const handleUploadChange = ({ file }, status) => {
    if (status === "done") setFile(file);
  };

  const handleDownload = () => {
    downloadTemplate({
      onCompleted: ({ download_crm_template }) => {
        HelperFns.downloadFile(download_crm_template);
      },
    });
  };

  const handleImport = () => {
    importLeads({
      variables: { file },
      onCompleted: () => {
        refetchList();
        onClose();
        showToast("success");
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors?.[0]?.extensions?.reason ||
            err?.graphQLErrors?.[0]?.message ||
            err?.message
        );
      },
    });
  };

  return (
    <MainModal
      isOpen
      toggle={handleCLoseModal}
      btnOnClick={handleImport}
      modalTitle="Import Leads"
      className="documents_dropzone_style"
      disableSubmitButton={!file}
      btnSubmitLoading={importLoading}
    >
      <strong className="d-block">{t("template")}</strong>
      <p className="font-13 ">
        {t(
          "for a successful import, please make sure you are using this template when uploading your data"
        )}
      </p>

      <ColorButton
        loadingButton
        color="#23aaeb"
        className="mb-4"
        label="download template"
        onClick={handleDownload}
        loading={downloadTemplateLoading}
        icon={<FileDownloadIcon fontSize="small" />}
      />

      <Dropzone
        canRemove
        maxFiles="1"
        name="attachments"
        accept=".csv,.xlsx,.xls"
        onChangeStatus={handleUploadChange}
        inputContent={t("drag files here or browse")}
      />
    </MainModal>
  );
};

export default ImportLeadsModal;
