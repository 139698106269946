import React, { useEffect } from "react";
import MainModal from "../../Components/MainModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import HelperFns, { showToast } from "../../Helpers/HelperFns";
import { Alert, AlertTitle } from '@mui/material';
import Select from "react-select";
import {
  RadioboxForm,
  InputForm,
} from "form-builder";
import { useMutation, useQuery } from "@apollo/client";
import { AddButton } from "../../Components/Buttons";
import { EditIconButton } from "../../Components/IconButtonWithTooltip";
import MultipleCheckInOutForm from "../../Components/SharedModals/MultipleCheckInOutForm";
import { changeCheckInsModalQuery, fetchcheck_in_form_idQuery } from "../../Graphql/query";
import { addCheckInSettingsForEmployeesMutation } from "../../Graphql/mutation";

const modalInitState = {
  type: null,
  formID: null,
  isOpen: false,
  isEdit: false,
};

const ChangeCheckInsModal = (props) => {
  const { t } = useTranslation();

  const initState = {
    allow_multiple_check_ins:false,
    face_recognition_check_in:false,
    check_in_settings_as_work_timing_sign_in_settings:true,
    CustomizeCheckIn:false,
    workplace_setting:"DEFAULT",
    check_out_form_id: null,
    check_in_form_id: null,
    additional_work_places:null,
    additional_work_places_signout: null,
    workplace_setting_signout:"DEFAULT",
    workplace_setting_identical:1,
    check_in_distance_setting:null,
    employees_ids:props.selectedEmployees
  }

  const [input, setInput] = useState(initState);
  const [modalState, setModalState] = React.useState(modalInitState);

  // fetching form data
  const {
    data: formsData,
    loading: formsDataLoading,
    refetch: refetchFormsData,
  } = useQuery(changeCheckInsModalQuery, {
    skip: false,
    notifyOnNetworkStatusChange: true,
  });

   // submitting mutation
   const [addCheckInSettingsForEmployees, { loading: submitLoading, error:submitError }] =
   useMutation(addCheckInSettingsForEmployeesMutation, {
     onError: (err) => {
       console.log({err});
       if(err?.graphQLErrors[0]?.extensions?.validation){
         let validation = err?.graphQLErrors[0]?.extensions?.validation
         Object.keys(validation).forEach(key => {
           setValidator(prev=>(
           {...prev,
           [key]:{
             isValid:false, 
             msg:validation[key].toString()
           }}))
         })
       }
       else showToast("error", err?.graphQLErrors[0]?.extensions?.reason || err.message)
     },
     onCompleted: (res) => {
       if(res?.addCheckInSettingsForEmployees?.status==="success"){
         props.resetSelection()
         props.refetch()
         props.close()
       }
       showToast(
         res?.addCheckInSettingsForEmployees?.status,
         res?.addCheckInSettingsForEmployees.message
       );
 
     },
   });


// validation
const validatorInitState = {
  "input.check_out_form_id":{valid:true, msg:null},
  "input.check_in_form_id":{valid:true, msg:null},
  "input.additional_work_places":{valid:true, msg:null},
  "input.additional_work_places_signout":{valid:true, msg:null},
  "input.check_in_distance_setting":{valid:true, msg:null},
}
const [validator, setValidator] = useState(validatorInitState)

const validate = () => {
  if(! input.allow_multiple_check_ins) return true
  let tempValidator = {...validatorInitState}
  if(input.CustomizeCheckIn){
      tempValidator["input.check_in_form_id"] = {
        valid: (Boolean(input.check_in_form_id)),
        msg: "This Field is Required."
      }
      tempValidator["input.check_out_form_id"] = {
        valid: (Boolean(input.check_out_form_id)),
        msg: "This Field is Required."
      }
  }

  if(!input.check_in_settings_as_work_timing_sign_in_settings){
    if(input.workplace_setting==="DEFAULT"){
      tempValidator["input.additional_work_places"] = {
        valid: (Boolean(input.additional_work_places[0])),
        msg: "This Field is Required."
      }
    }
    if((!input.workplace_setting_identical)&&input.workplace_setting_signout==="DEFAULT"){
      tempValidator["input.additional_work_places_signout"] = {
        valid: (Boolean(input.additional_work_places_signout[0])),
        msg: "This Field is Required."
      }
    }
  }

  if((!Boolean(input.check_in_distance_setting)&&input.check_in_distance_setting!==0)) 
    tempValidator["input.check_in_distance_setting"] = {
      valid: false,
      msg: "This Field is Required."
    }
  else if(input.check_in_distance_setting < 0) 
    tempValidator["input.check_in_distance_setting"] = {
      valid: false,
      msg: "cannot be negative"
    }

  setValidator(tempValidator)
  return (tempValidator["input.check_in_form_id"].valid&&tempValidator["input.check_out_form_id"].valid&&
          tempValidator["input.additional_work_places"].valid&&tempValidator["input.additional_work_places_signout"].valid && 
          tempValidator["input.check_in_distance_setting"].valid)
}



  // resetting form data
  useEffect(()=>{
    setInput(initState)
    setValidator(validatorInitState)
  },[props.isOpen])

  useEffect(()=>{
    let reset = {...input};
    if (input.workplace_setting_identical) {
      reset.additional_work_places_signout = reset.additional_work_places
      reset.workplace_setting_signout = reset.workplace_setting
    }
    setInput(reset)
  },[input.workplace_setting_identical, input.additional_work_places, input.workplace_setting])

  const handleSave = () => {
    if(!validate()) return
    HelperFns.checkPassword(
      "defaut_warning_messsage",
      "",
      "Confirm",
      "",
      () => submit()
    );
  };

  const submit = () => {
    if(!validate()) return
    let{ allow_multiple_check_ins,
        check_in_form_id,
        check_out_form_id,
        check_in_distance_setting,
        face_recognition_check_in,
        additional_work_places,  
        additional_work_places_signout, 
        workplace_setting, 
        workplace_setting_signout, 
        workplace_setting_identical, 
        employees_ids
     } = input
    if(input.check_in_settings_as_work_timing_sign_in_settings){
      addCheckInSettingsForEmployees({
        variables:{
          input: {
            allow_multiple_check_ins,
            check_in_form_id,
            check_out_form_id,
            check_in_distance_setting: !allow_multiple_check_ins? 0 : parseFloat(check_in_distance_setting),
            checkin_setting:null,
            employees_ids
          }
        }
      })
    }
    else {
      addCheckInSettingsForEmployees({
        variables:{
          input: {
            allow_multiple_check_ins,
            check_in_form_id,
            check_out_form_id,
            check_in_distance_setting:parseFloat(check_in_distance_setting),
            checkin_setting: {
              face_recognition_check_in,
              face_recognition_check_out: face_recognition_check_in,
              additional_work_places,  
              additional_work_places_signout, 
              workplace_setting, 
              workplace_setting_signout, 
              workplace_setting_identical
            },
            employees_ids
          }
        }
      })
    }
  };

  const handleCancle = () => {
    props.close()
  };


  const handleInputChange = (e) => {
    setInput(prev=>({...prev, [e.target.name]:(e.target.checked)}))
  };


  const handleIntCheckBoxChange = (e) => {
    setInput(prev=>({...prev, [e.target.name]:(e.target.checked? 1 : 0)}))
  };

  const handleRadioChange = (e,val) => {
    setInput((prevState) => {
      return { ...prevState, [e.target.name]: val };
    });
    if (val!=="DEFAULT") {
      e.target.name==="workplace_setting"?
        setInput(prev => (
          {...prev, additional_work_places:null }
        ))
        :
        setInput(prev => (
          {...prev, additional_work_places_signout:null }
        ))

    }
  };


  const selectVal = (val, options, multi) => { 
        if(multi) {
            return (options?.filter((opt) => val?.includes(opt?.id)) || [])
        }
        return (options?.find((opt) => opt?.id === val) || null)
    }

  const handleSelect = (val,e) => {
    if (e.name ==="check_out_form_id"||e.name==="check_in_form_id") {
        setInput((prev) => ({
          ...prev,
          [e.name]: val?.id,
        }));
        return
    }
    console.log(input[e.name], val);
    setInput(prev=>({...prev,[e.name]: val?  val.map(v=>v.id) : []} ))
}

const handleCustomizeCheckIn = (e) => {
  setInput(prev=>({...prev, [e.target.name]:(e.target.checked)}))
  if(!e.target.checked)
    setInput(prev => ({
      ...prev,
      check_in_form_id:initState.check_in_form_id,
      check_out_form_id:initState.check_out_form_id,
    }))
}

const handleAllowMultipleChange = (e) => {
  setInput(prev=>({...prev, [e.target.name]:(e.target.checked)}))
  if(!e.target.checked) setInput(initState)
}


  return (
    <MainModal
      isOpen={props.isOpen}
      size="lg"
      modalTitle={t("Bulk Actions")}
      btnSubmitLoading={submitLoading}
      btnOnClick={handleSave}
      toggle={handleCancle}
      className="p-5 "
    >
      <div className="p-2">
        <Alert severity="info">
                <AlertTitle>
                  {t("Change Check-Ins Settings")}
                </AlertTitle>
                <strong>{t("Employees will be effected", { count: props.selectedEmployees.length })}</strong>
          </Alert>
      </div>

        <div className={"p-2 pb-3 "}>
                <label className={"checkbox"}>
                    <input
                        type="checkbox"
                        name={"allow_multiple_check_ins"}
                        value={input.allow_multiple_check_ins}
                        onChange={(e) => handleAllowMultipleChange(e)}
                        checked={input.allow_multiple_check_ins}
                        {...props}
                        />
                    <span></span>

                    <div className={`booleanInputLabelStyle ${input.allow_multiple_check_ins?"lightActiveColor":""}`}>
                        {t("allow multiple check-ins during shifts")}
                    </div>
                </label>

                {input.allow_multiple_check_ins && 

                    <div>
                        
                        <label className={"checkbox"}>
                            <input
                                type="checkbox"
                                name={"CustomizeCheckIn"}
                                value={input.CustomizeCheckIn}
                                onChange={(e)=>handleCustomizeCheckIn(e)}
                                checked={input.CustomizeCheckIn}
                                />
                            <span></span>

                            <div className={`booleanInputLabelStyle ${input.CustomizeCheckIn?"lightActiveColor":""}`}>
                                {t("Customize Check-in Form")}
                            </div>
                        </label>
                        {input.CustomizeCheckIn && 
                        <>
                        <div className="d-flex flex-wrap gap-20 mb-2">
                            {["in", "out"]. map( type => <div key={type} className="d-flex flex-fill gap-20 mt-2">{/*check_out_form_id*/}
                                <div className="flex-fill">
                                    <div className="d-flex flex gap-10 mb-2">
                                        <span>{t(`Check-${type} Forms`)}</span>
                                        <AddButton
                                        isIconOnly
                                        onClick={() =>
                                          setModalState((prev) => ({
                                            ...prev,
                                            isOpen: true,
                                            type,
                                          }))
                                        }
                                        />
                                    </div>
                            <div className="d-flex gap-10">
                                <div className={"w-100 "+(validator[`input.check_${type}_form_id`]?.valid? "" : "invalid-container-style")}>
                                    <Select
                                        className={"select-def-input-containe b-select-style flex-grow-1"}
                                        classNamePrefix={"b-select-style"}
                                        value={selectVal(input[`check_${type}_form_id`],(formsData?.checkInOutForms || []))}
                                        onChange={handleSelect}
                                        isClearable={true}
                                        isRtl={document?.body?.dir?.toLowerCase() == "rtl"}
                                        isSearchable
                                        placeholder={t("select option")}
                                        options={
                                          formsDataLoading ? [] : formsData?.checkInOutForms
                                        }
                                        getOptionLabel={(opt) => opt?.name}
                                        getOptionValue={(opt) => opt?.id}
                                        isLoading={formsDataLoading}
                                        name={`check_${type}_form_id`}
                                    />
                                    <div className="validity-msg-style text-left">
                                    { t(validator[`input.check_${type}_form_id`]?.msg) }
                                    </div>
                                </div>
                                {input[`check${type}_form_id`] ? (
                                <EditIconButton
                                    buttonProps={{ disabled: false }}
                                    onClick={() =>
                                      setModalState({
                                        type,
                                        isEdit: true,
                                        isOpen: true,
                                        formID: input[`check${type}Forms`],
                                      })
                                    }
                                />
                                ) : null}
                            </div>
                          </div>
                        </div>)}
                        </div>
                        <MultipleCheckInOutForm
                            state={modalState}
                            formName={props?.formName}
                            refetchFormsData={refetchFormsData}
                            formsData={formsData?.checkInOutForms}
                            onClose={() => setModalState(modalInitState)}
                          />
                            </>}
                        <div className="d-flex flex-column gap-3">
                            <div>
                                <label className={"checkbox"}>
                                <input
                                    type="checkbox"
                                    name={"check_in_settings_as_work_timing_sign_in_settings"}
                                    value={input.check_in_settings_as_work_timing_sign_in_settings}
                                    onChange={(e) => handleInputChange(e)}
                                    checked={input.check_in_settings_as_work_timing_sign_in_settings}
                                    {...props}
                                    />
                                <span></span>

                                <div className={`booleanInputLabelStyle ${input.check_in_settings_as_work_timing_sign_in_settings?"lightActiveColor":""}`}>
                                    {t("Set Check-in and out settings according to the work timing sign-in/out settings")}
                                </div>
                            </label>
                            </div>
                            {!input.check_in_settings_as_work_timing_sign_in_settings && <>
                            <>
                                <strong className="d-block mt-4 mb-2 blue-color">
                                    {t("facial recognition settings")}
                                </strong>
                                <label className={"checkbox"}>
                                    <input
                                    type="checkbox"
                                    name={"face_recognition_check_in"}
                                    value={input.face_recognition_check_in}
                                    onChange={(e) => handleInputChange(e)}
                                    checked={input.face_recognition_check_in}
                                    {...props}
                                    />
                                    <span></span>

                                    <div className={`booleanInputLabelStyle ${input.face_recognition_check_in?"lightActiveColor":""}`}>
                                        {t("Require facial recognition when checking in/out")}
                                    </div>
                                </label>
                            </>

                            <strong className="d-block mt-4 mb-2 blue-color">
                                {t("Check-in/out Work Places")}
                            </strong>
                            <RadioboxForm
                            labelStyle="label-style font-weight-bold"
                            label="Employee can check in From"
                            name={"workplace_setting"}
                            value={input.workplace_setting}
                            interceptChange={handleRadioChange}
                            validateContainerStyle={""}
                            optionInputStyle="text-black-100"
                            containerStyle="mb-0"
                            optionLabelStyle="optionLabelStyle"
                            optionsContainerStyle="d-flex flex-column  gap-10"
                            options={[
                                {
                                label: t("Anywhere"),
                                value:"ANY_PLACE",
                                },
                                {
                                label: t("Any work place in the company"),
                                value: "ALL_COMPANY_WORKPLACES",
                                },
                                {
                                label: t("Specific work places"),
                                value: "DEFAULT"
                                },
                            ]}
                        />

                        {  input?.workplace_setting==="DEFAULT" &&
                            <div className={"py-3 "+ (validator["input.additional_work_places"].valid? "" : "invalid-container-style")}>
                                <label className={"select-def-label validity-label-style"}>
                                {t("Additional Workplaces") }
                                </label>
                                <Select
                                    className={"select-def-input-containe b-select-style flex-grow-1"}
                                    classNamePrefix={"b-select-style"}
                                    value={selectVal(input.additional_work_places,(formsData?.locations_menu?.data || []), true)}
                                    onChange={handleSelect}
                                    name={"additional_work_places"}
                                    isClearable={true}
                                    isRtl={document?.body?.dir?.toLowerCase() == "rtl"}
                                    isSearchable
                                    placeholder={t("select option")}
                                    options={
                                      formsDataLoading ? [] : formsData?.locations_menu.data
                                    }
                                    getOptionLabel={(opt) => opt?.name}
                                    getOptionValue={(opt) => opt?.id}
                                    isLoading={formsDataLoading}
                                    isMulti={true}
                                />
                                <div className="validity-msg-style text-left">
                                  { t(validator["input.additional_work_places"].msg) }
                                </div>
                            </div>
                        }  

                        <div className="py-3">
                            <label className={"checkbox"}>
                                <input
                                type="checkbox"
                                name={"workplace_setting_identical"}
                                value={input.workplace_setting_identical}
                                onChange={(e) => handleIntCheckBoxChange(e)}
                                checked={input.workplace_setting_identical}
                                {...props}
                                />
                                <span></span>

                                <div className={`booleanInputLabelStyle ${input.workplace_setting_identical?"lightActiveColor":""}`}>
                                    {t("Apply the same settings when checking out")}
                                </div>
                            </label>
                        </div>

                        {  !input.workplace_setting_identical && 
                            <>
                            <RadioboxForm
                            label="Employee can Check Out From"
                            labelStyle="label-style font-weight-bold"
                            value={input.workplace_setting_signout}
                            name={"workplace_setting_signout"}
                            interceptChange={handleRadioChange}
                            validateContainerStyle={""}
                            optionInputStyle="text-black-100"
                            containerStyle="mb-0"
                            optionLabelStyle="optionLabelStyle"
                            optionsContainerStyle="d-flex flex-column  gap-10"
                            options={[
                                {
                                label: t("Anywhere"),
                                value:"ANY_PLACE"
                                },
                                {
                                label: t("Any work place in the company"),
                                value: "ALL_COMPANY_WORKPLACES"
                                },
                                {
                                label: t("Specific work places"),
                                value: "DEFAULT"
                                },
                            ]}/>

                            {  input?.workplace_setting_signout==="DEFAULT" &&
                                <div className={"py-3 "+ (validator["input.additional_work_places_signout"].valid? "" : "invalid-container-style")}>
                                    <label className={"select-def-label validity-label-style"}>
                                    {t("Additional Workplaces") }
                                    </label>
                                    <Select
                                        className={"select-def-input-containe b-select-style flex-grow-1"}
                                        classNamePrefix={"b-select-style"}
                                        value={selectVal(input.additional_work_places_signout,(formsData?.locations_menu?.data || []), true)}
                                        onChange={handleSelect}
                                        name={"additional_work_places_signout"}
                                        isClearable={true}
                                        isRtl={document?.body?.dir?.toLowerCase() == "rtl"}
                                        isSearchable
                                        isMulti={true}
                                        placeholder={t("select option")}
                                        options={
                                          formsDataLoading ? [] : formsData?.locations_menu.data
                                        }
                                        getOptionLabel={(opt) => opt?.name}
                                        getOptionValue={(opt) => opt?.id}
                                        isLoading={formsDataLoading}
                                    />
                                    <div className="validity-msg-style text-left">
                                      { t(validator["input.additional_work_places_signout"].msg) }
                                    </div>
                                </div>
                            }</>
                        }
                        </>}
                            <div className={"py-3 "+ (validator["input.check_in_distance_setting"].valid? "" : "invalid-container-style")}>
                              <InputForm
                                validateBy="textRequired"
                                value={input.check_in_distance_setting}
                                name="metres"
                                onChange={(v)=> {
                                  setInput(prev => ({...prev, check_in_distance_setting:v.target.value}))
                                }}
                                placeholder={t("metres")}
                                label="Show work places surrounding the employee within"
                                labelStyle="mr-2"
                                containerStyle="mt-0 mb-3 d-flex align-items-center"
                                icon="globe"
                                hasSuffix
                                suffixTitle="metres"
                                rootStyle="mt-4"
                              />
                              <div className="validity-msg-style text-left">
                                { t(validator["input.check_in_distance_setting"].msg) }
                              </div>
                            </div>
                        </div>
                    </div>
                }
        </div>
    </MainModal>
  );
};

export default ChangeCheckInsModal;