import React from "react";
import MainApp from "./App/MainApp";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import { PersistGate } from "redux-persist/integration/react";

import "flag-icon-css/css/flag-icon.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./App/Assets/sass/main.scss";
import 'leaflet/dist/leaflet.css';
import "react-block-ui/style.css";
import "moment/min/locales.min";

import Store from "./App/Store";
import { persistor } from "./App/Store";
import { client } from "./App/Services/Client";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Toast from "./App/Components/Feedback/Toast"


const App = () => {

  function TestError() {
    const a = null;
    return a.hello();
  }

  return (
    <Provider store={Store}>
      <PersistGate persistor={persistor}>
        <ApolloProvider client={client}>
          <button className="d-none" onClick={TestError}>Error</button>
          <MainApp />
          <Toast />
        </ApolloProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
library.add(fas, far);
