import React from "react";
import Skeleton from "@mui/material/Skeleton";

const TablePlaceholder = ({ columns = 5, rows = 6 }) => {
  return (
    <div className="p-2">
      <Skeleton variant="text" className="mb-4" width={180} height={32.5} />

      <div className="flex-row d-flex ">
        <div className="flex-grow-1">
          <Skeleton variant="text" width={120} height={40} />
        </div>
        <Skeleton
          variant="text"
          className="d-flex mx-2"
          width={145}
          height={40}
        />
        <Skeleton
          variant="text"
          className="d-flex mx-2"
          width={145}
          height={40}
        />
      </div>

      <div
        className="row mt-3"
        style={{
          flexDirection: "flex-row ",
        }}
      >
        <div className="w-100 px-3">
          <Skeleton
            variant="rectangular"
            className="px-4"
            width="100%"
            height={2}
          />
        </div>
        {[...Array(+columns).keys()]?.map((_, value) => (
          <div className="col justify-content-center align-items-center">
            <Skeleton variant="text" width={"100%"} height={60} />
          </div>
        ))}
        <div className="w-100 px-3">
          <Skeleton
            variant="rectangular"
            className="px-4"
            width="100%"
            height={2}
          />
        </div>
      </div>
      {[...Array(+rows).keys()]?.map((_, value) => (
        <>
          <div
            className="row mt-2"
            style={{
              flexDirection: "flex-row",
            }}
          >
            {[...Array(+columns).keys()]?.map((_, value) => (
              <div className="col justify-content-center align-items-center">
                <Skeleton variant="text" height={70} />
              </div>
            ))}
          </div>
          <Skeleton variant="rectangular" width="100%" height={2} />
        </>
      ))}
    </div>
  );
};

export default TablePlaceholder;
