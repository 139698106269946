import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { faCircle, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  showSystemRolePlanModal,
  fetchPlanRolesAction,
  deleteRoleAction,
} from "../../Store/Actions";

import PlanRoleModal from "../../Containers/PlanRoleModal";
import Loader from "../../Components/Loader";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privileges from "../../Constants/Privilages";
const SystemPlanRoles = (props) => {
  const { planId } = useParams();
  useEffect(() => {
    props.fetchPlanRolesAction(planId);

    return () => {};
  }, []);

  const { t } = useTranslation();
  const handleAddRole = () => {
    props.showSystemRolePlanModal();
  };

  const handleDeleteRole = (roleId) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteRoleAction(roleId, planId, true);
      }
    });
  };
  return (
    <div className="content pt-3">
      <div className="px-2 pb-2">
        <div className="col-12 px-0">
          <h4 className="page-title mb-3">{t("new plan")}</h4>
        </div>
        {props.loadingNewPlanRoles || props.planActionLoading ? (
          <div className="loader_wrapper_style">
            <Loader />
          </div>
        ) : null}

        <div className="d-flex align-items-center flex-row sign-in-config bg-white my-0 py-0">
          <div>
            <h5 className="sub-title-style mt-2">{t("Roles")}</h5>
          </div>
          <HasPrivileges
            reqireMain={[
              Privileges.ADD_EDIT_DELETE_PLANS,
              Privileges.SUPER_PRIVILEGE,
            ]}
          >
            <button
              className="add_new_btn_style d-flex btn ml-4"
              onClick={handleAddRole}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </HasPrivileges>
        </div>
        <HasPrivileges
          reqireMain={[
            Privileges.ADD_EDIT_DELETE_PLANS,
            Privileges.SUPER_PRIVILEGE,
          ]}
        >
          <div className="mt-3">
            {props?.planRoles?.roles?.map((role) => (
              <div className="d-flex flex-row col-2 px-0">
                <span className="">
                  <FontAwesomeIcon icon={faCircle} size="xs" color="#23aaeb" />
                </span>
                <p className="mx-3">{role.name}</p>
                <HasPrivileges
                  reqireMain={[
                    Privileges.ADD_EDIT_DELETE_PLANS,
                    Privileges.SUPER_PRIVILEGE,
                  ]}
                >
                  <span
                    className="ml-auto"
                    role="button"
                    onClick={() => handleDeleteRole(role?.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} size="xs" color="#ff5151" />
                  </span>
                </HasPrivileges>
              </div>
            ))}
          </div>
        </HasPrivileges>
      </div>

      <div className="px-2">
        <Link
          to={"/admin-panel?tab=plans"}
          className="add_new_btn_style bg-success btn float-right m-2 text-capitalize"
        >
          {t("finish")}
        </Link>
      </div>

      <PlanRoleModal isNewPlan={true} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.super,
    planRoles: state.admin.newPlanRoles,
    loadingNewPlanRoles: state.admin.loadingNewPlanRoles,
    planActionLoading: state.admin?.planActionLoading,
  };
};

export default connect(mapStateToProps, {
  showSystemRolePlanModal,
  fetchPlanRolesAction,
  deleteRoleAction,
})(SystemPlanRoles);
