import React from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import {
  changeLeadTagMutation,
  removeLeadTagMutation,
  storeLeadTagsMutation,
} from "../../Graphql/mutation";
import { showToast } from "../../Helpers/HelperFns";

import {
  EditIconButton,
  TimesIconButton,
  AcceptIconButton,
  RemoveIconButton,
} from "../IconButtonWithTooltip";
import Loader from "../Loader";
import MainModal from "../MainModal";
import { InputForm } from "form-builder";

const tagInitState = { name: "", color: "#000000" };
const LeadsTagsModal = ({
  data,
  onClose,
  refetchList,
  closeLeadModal,
  setTagsOptions,
  addToTagsOptions,
}) => {
  const { t } = useTranslation();

  // Local State
  const [errMsg, setErrMsg] = React.useState("");
  const [isChanged, setIsChanged] = React.useState(false);
  const [tags, setTags] = React.useState(
    data?.isEdit
      ? data?.tags?.map((t) => ({ ...t, editMode: false, refName: t?.name }))
      : [tagInitState]
  );

  // Server State
  const [saveTag, { loading: saveLoading }] = useMutation(
    storeLeadTagsMutation
  );
  const [changeTag, { loading: changeLoading }] = useMutation(
    changeLeadTagMutation
  );
  const [removeTag, { loading: removeLoading }] = useMutation(
    removeLeadTagMutation
  );

  /* ↓ Helpers ↓ */

  const handleChange = (e, idx) => {
    setTags((prev) =>
      prev.map((p, i) =>
        idx === i ? { ...p, [e.target.name]: e.target.value } : p
      )
    );
  };

  const handleOpenEditMode = (idx) => {
    setTags((prev) =>
      prev.map((p, i) => (idx === i ? { ...p, editMode: true } : p))
    );
  };

  const handleCloseEditMode = (idx) => {
    setTags((prev) =>
      prev.map((p, i) =>
        idx === i ? { ...p, editMode: false, name: p?.refName } : p
      )
    );
  };

  // const handleAddAdditionalTag = () => {
  //   setTags((prev) => [...prev, tagInitState]);
  // };

  const handleClose = () => {
    onClose();
    if (isChanged) {
      refetchList();
      closeLeadModal();
    }
  };

  const handleUpsert = (tag) => {
    const upsert = data?.isEdit ? changeTag : saveTag;

    if (tags.every((t) => t?.name)) {
      upsert({
        variables: data?.isEdit ? tag : tags?.[0],
        onCompleted: (res) => {
          if (data?.isEdit) {
            setTags((prev) =>
              prev.map((p) =>
                p?.id === tag?.id ? { ...p, editMode: false } : p
              )
            );
            setIsChanged(true);
          } else {
            addToTagsOptions(res?.store_tag);
            onClose();
          }

          showToast("success", data?.isEdit ? "tag updated" : "tag created");
        },
        onError: (err) => {
          showToast(
            "error",
            err?.graphQLErrors[0]?.extensions?.reason ||
              err?.graphQLErrors[0]?.message ||
              err?.message
          );
        },
      });
    } else {
      setErrMsg("tag name is required");
    }
  };

  const handleDeleteTags = (id) => {
    removeTag({
      variables: { id },
      onCompleted: () => {
        const newTags = tags.filter((p) => p?.id !== id);
        setTags(newTags);
        setIsChanged(true);
        showToast("success", "tag deleted");
      },
      onError: (err) => {
        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason ||
            err?.graphQLErrors[0]?.message ||
            err?.message
        );
      },
    });
  };

  return (
    <MainModal
      isOpen
      toggle={handleClose}
      btnSubmitLoading={saveLoading}
      hasModalFooter={!data?.isEdit}
      btnOnClick={data?.isEdit ? undefined : handleUpsert}
      modalTitle={data?.isEdit ? "edit tags" : "new tags"}
    >
      {changeLoading || removeLoading ? <Loader fixed /> : null}

      {!data?.isEdit ? (
        <AddTags tags={tags} handleChange={handleChange} />
      ) : (
        tags?.map(({ editMode, refName, ...tag }, i) => (
          <div className="d-flex gap-10 align-items-center">
            {!editMode ? (
              <>
                <span className="flex-1">{tag?.name}</span>
                <div className="d-flex gap-5">
                  <EditIconButton onClick={() => handleOpenEditMode(i)} />
                  <RemoveIconButton onClick={() => handleDeleteTags(tag?.id)} />
                </div>
              </>
            ) : (
              <>
                <AddTags i={i} tags={tags} handleChange={handleChange} />
                <div className="d-flex gap-10 ml-auto">
                  <AcceptIconButton
                    label="save"
                    onClick={() => handleUpsert(tag)}
                  />
                  <TimesIconButton
                    label="cancel"
                    onClick={() => handleCloseEditMode(i)}
                  />
                </div>
              </>
            )}
          </div>
        ))
      )}

      {errMsg ? (
        <p role="alert" className="mb-0 error-color">
          {t(errMsg)}
        </p>
      ) : null}

      {/* <div className="w-100 d-flex align-items-center gap-20 my-3">
        <strong className="blue-color text-16">{t("Additional Tags")}</strong>
        <AddButton isIconOnly onClick={handleAddAdditionalTag} />
      </div>

      {tags.map((tag, i) =>
        i ? (
          <div className="d-flex gap-10">
            <InputForm
              name="name"
              value={tag.name}
              containerStyle="p-0"
              aria-label={t("new tag")}
              placeholder={t("new tag")}
              onChange={(e) => handleChange(e, i)}
            />
            <input
              name="color"
              type="color"
              value={tag.color}
              aria-label={t("new color")}
              onChange={(e) => handleChange(e, i)}
            />
          </div>
        ) : null
      )} */}
    </MainModal>
  );
};

export default LeadsTagsModal;

const AddTags = ({ i = 0, tags, handleChange }) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex gap-20 align-items-end py-2">
      <InputForm
        name="name"
        value={tags?.[i]?.name}
        containerStyle="p-0"
        aria-label={t("new tag")}
        placeholder={t("new tag")}
        onChange={(e) => handleChange(e, i)}
      />
      <input
        name="color"
        type="color"
        value={tags?.[i]?.color}
        aria-label={t("new color")}
        onChange={(e) => handleChange(e, i)}
      />
    </div>
  );
};
